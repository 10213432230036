import React from 'react';
import Plot from 'react-plotly.js';
import QbFortyTimeValueToColorHelper from '../Helpers/QbFortyTimeValueToColorHelper';
import RbFortyTimeValueToColorHelper from '../Helpers/RbFortyTimeValueToColorHelper';
import WrFortyTimeValueToColorHelper from '../Helpers/WrFortyTimeValueToColorHelper';
import TeFortyTimeValueToColorHelper from '../Helpers/TeFortyTimeValueToColorHelper';

export default class HorizontalBarChart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			width: this.getChartWidth()
		};
		this.updateDimensions = this.updateDimensions.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}

	getChartWidth() {
		return window.innerWidth < 640 ? 325 : 475;
	}

	updateDimensions() {
		this.setState({ width: this.getChartWidth() });
	}

	render() {
		const getColorHelperByPosition = (position) => {
			switch (position.toLowerCase()) {
				case 'quarterback':
					return QbFortyTimeValueToColorHelper;
				case 'running back':
					return RbFortyTimeValueToColorHelper;
				case 'wide receiver':
					return WrFortyTimeValueToColorHelper;
				case 'tight end':
					return TeFortyTimeValueToColorHelper;
				default:
					return (value) => '#eeeeee';
			}
		};

		const colorHelper = getColorHelperByPosition(this.props.position);

		return (
			<Plot
				data={[
					{
						y: [
							this.props.bar1Label,
							this.props.bar2Label
						],
						x: [
							this.props.bar1Value,
							this.props.bar2Value
						],
						type: 'bar',
						orientation: 'h',
						marker: {
							color: [
								colorHelper(this.props.bar1Value),
								'#eeeeee'
							]
						},
						text: [
							this.props.bar1Value,
							this.props.bar2Value
						],
						textposition: 'outside',
						textfont: {
							color: '#000'
						}
					}
				]}
				layout={{
					title: {
						text: this.props.title ? this.props.title : '',
						font: {
							size: 18
						}
					},
					font: {
						color: '#000'
					},
					height: 225,
					width: this.state.width,
					tickmode: 'auto',
					xaxis: {
						title: 'Time (sec.)',
						range: [
							this.props.lowerRange,
							this.props.upperRange
						],
						showline: true,
						showgrid: false
					},
					yaxis: {
						automargin: true,
						ticklen: 10,
						tickcolor: 'rgba(0,0,0,0)',
						showline: true
					},
					margin: { l: 50, r: 50, t: 30, b: 50 }
				}}
				config={{
					displayModeBar: false
				}}
			/>
		);
	}
}
