import React from 'react';

const ContractYearTag = () => {
	return (
		<span
			title="Contract Year"
			className="ml-2 bg-green-700 text-white text-xs font-semibold px-2 py-1 rounded"
		>
			CY
		</span>
	);
};

export default ContractYearTag;
