import React from 'react';
import PageTitle from '../FormElements/PageTitle';
import GlobalErrorsBox from '../ErrorComponents/GlobalErrorBox/GlobalErrorsBox';
import FormSection from '../FormElements/FormSection';
import FormSubmit from '../FormElements/FormSubmit';
import Label from '../FormElements/Label';
import TextInput from '../FormElements/TextInput';
import TextArea from '../FormElements/TextArea';
import EmailInput from '../FormElements/EmailInput';

export default class IssueForm extends React.Component {
	constructor() {
		super();

		this.state = {
			name: '',
			emailAddress: '',
			issueDescription: '',
			errors: []
		};

		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	render() {
		return (
			<div className="w-96 py-4">
				<PageTitle title={this.props.hideTitle ? '' : 'Create issue'} />
				<GlobalErrorsBox globalErrors={this.state.errors} />
				<FormSection>
					<Label htmlFor="name">Name *</Label>
					<TextInput
						id="name"
						name="name"
						value={this.state.name}
						onChange={this.onChange}
					/>
					<Label htmlFor="emailAddress">Email *</Label>
					<EmailInput
						id="emailAddress"
						name="emailAddress"
						value={this.state.emailAddress}
						onChange={this.onChange}
					/>
					<Label htmlFor="issueDescription">Description of the issue *</Label>
					<TextArea
						id="issueDescription"
						name="issueDescription"
						value={this.state.issueDescription}
						onChange={this.onChange}
					/>
				</FormSection>
				<div className="grid">
					<div className="lg:justify-self-end">
						<FormSubmit onSubmit={this.onSubmit} label="Submit" />
					</div>
				</div>
			</div>
		);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onSubmit(e) {
		e.preventDefault();
		e.stopPropagation();

		fetch(this.props.endpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				name: this.state.name,
				email: this.state.emailAddress,
				description: this.state.issueDescription
			})
		})
			.then((response) => response.json())
			.then((data) => {
				// Handle successful response here
				console.log('Create issue call successful.', data);
				this.props.handleCreateIssueModalClose();
			})
			.catch((error) => {
				// Handle error response here
				console.error('Create issue call failed.', error);
				this.setState({ errors: error.errors || [] });
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			});
	}
}
