import React from 'react';
import CircularBarChart from '../../Charts/CircularBarChart';
import {
	getCompetition,
	getQuarterbacks,
	getNonQuarterbacks
} from '../../../utils/teamEnvironmentHelpers';

const TeamEnvironmentScoreCard = ({
	player,
	handleRankClick,
	visibleRankModal,
	handlePlayerClick,
	rankModalRef,
	currentPlayerRef
}) => {
	const {
		opportunity_grade,
		competition_grade,
		position,
		team: {
			offensive_line_grade,
			wide_receivers_grade,
			tight_ends_grade,
			running_backs_grade,
			quarterbacks_grade,
			head_coach_grade
		} = {}
	} =
		player[0] || {};

	const maxPossibleScore = 10;

	return (
		<div className="flex flex-wrap border border-gray-300 rounded-xl shadow-lg bg-white">
			<div className="pt-6 px-5 md:px-18 flex flex-wrap items-center justify-center lg:justify-between w-full xl:flex-row space-y-6 sm:space-y-0">
				<CircularBarChart
					title="Opportunity"
					chartSize="md"
					value={opportunity_grade}
					maxPossible={maxPossibleScore}
					infoIconText="This score is calculated based on the player's projected volume and role within their team's offense."
					hasLabel={false}
					hasRankModalEnabled={false}
				/>
				<CircularBarChart
					title="Competition"
					chartSize="md"
					value={competition_grade}
					maxPossible={maxPossibleScore}
					infoIconText="This score is calculated based on the player's competition for touches within their team's offense. A higher score indicates less competition for touches. A lower score indicates more competition for touches."
					hasLabel={false}
					hasRankModalEnabled={true}
					gradeRanks={getCompetition(player)}
					handleRankClick={() =>
						handleRankClick(`competition_score_${player[0].id}`)}
					isRankVisible={
						visibleRankModal === `competition_score_${player[0].id}`
					}
					rankModalRef={rankModalRef}
					currentPlayerRef={currentPlayerRef}
					handlePlayerClick={handlePlayerClick}
					displayPosition={true}
					currentPlayerId={player && player[0] ? player[0].id : 0}
				/>
				<CircularBarChart
					title="O-Line"
					chartSize="md"
					value={offensive_line_grade}
					maxPossible={maxPossibleScore}
					infoIconText="This score is calculated based on the quality of the player's offensive line, which impacts their ability to produce fantasy points."
					hasLabel={false}
					hasRankModalEnabled={false}
				/>
				{position === 'quarterback' ? (
					<CircularBarChart
						title="WR/TE/RB"
						chartSize="md"
						value={((wide_receivers_grade || 0) * 0.5 +
							(tight_ends_grade || 0) * 0.25 +
							(running_backs_grade || 0) * 0.25).toFixed(2)}
						maxPossible={maxPossibleScore}
						infoIconText="This score is calculated based on the quality of the player's wide receivers, tight ends, and running backs, which impacts their ability to produce fantasy points."
						hasLabel={false}
						hasRankModalEnabled={true}
						gradeRanks={getNonQuarterbacks(player)}
						handleRankClick={() => handleRankClick(`wr_te_rb_${player[0].id}`)}
						isRankVisible={visibleRankModal === `wr_te_rb_${player[0].id}`}
						rankModalRef={rankModalRef}
						currentPlayerRef={currentPlayerRef}
						handlePlayerClick={handlePlayerClick}
						displayPosition={true}
					/>
				) : (
					<CircularBarChart
						title="QB"
						chartSize="md"
						value={quarterbacks_grade}
						maxPossible={maxPossibleScore}
						infoIconText="This score is calculated based on the quality of the player's quarterback, which impacts their ability to produce fantasy points."
						hasLabel={false}
						hasRankModalEnabled={true}
						gradeRanks={getQuarterbacks(player)}
						handleRankClick={() => handleRankClick(`qb_${player[0].id}`)}
						isRankVisible={visibleRankModal === `qb_${player[0].id}`}
						rankModalRef={rankModalRef}
						currentPlayerRef={currentPlayerRef}
						handlePlayerClick={handlePlayerClick}
						displayPosition={true}
					/>
				)}
				<CircularBarChart
					title="Coaching"
					chartSize="md"
					value={head_coach_grade}
					maxPossible={maxPossibleScore}
					infoIconText="This score is calculated based on the quality of the player's head coach and offensive coordinator, which impacts their ability to produce fantasy points."
					hasLabel={false}
					hasRankModalEnabled={false}
				/>
			</div>
		</div>
	);
};

export default TeamEnvironmentScoreCard;
