import React from 'react';
import StatusWidget from '../../StatusWidget/StatusWidget';
import CircularBarChart from '../../Charts/CircularBarChart';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';

const handlePlayerClick = (playerId, playerPosition) => {
	window.location.href = `/players/${playerId}?position=${playerPosition}`;
};

const DepthChartCard = ({
	player,
	gradeRanks,
	handleRankClick,
	visibleRankModal,
	rankModalRef,
	currentPlayerRef,
	loading
}) => {
	if (player.length === 0 || !player.image_url) {
		return null;
	}

	return (
		<div className="md:col-span-1">
			<div className="grid grid-cols-1 border border-gray-300 rounded-xl shadow-lg pt-2 md:pt-14 lg:pt-2 bg-white">
				<div className="px-4 sm:px-6 lg:px-8 flex items-center justify-between mx-2 md:mx-4">
					{/* Player details */}
					<div className="flex items-center flex-col sm:flex-row">
						{/* Player image */}
						<img
							className="h-48 md:60 lg:h-80 rounded mb-4 sm:mb-0 sm:mr-8"
							src={player.image_url}
							alt="Player Portrait"
						/>
						<div className="text-center sm:text-left lg:pt-6">
							{/* Player details */}
							<div>
								<div className="flex flex-col sm:flex-row items-left mb-0">
									{/* Depth Chart Position */}
									<div className="text-lg text-gray-500">
										{player.depth_chart_position === 1 ? (
											'Starter'
										) : player.depth_chart_position === 2 ? (
											'2nd String'
										) : player.depth_chart_position === 3 ? (
											'3rd String'
										) : (
											'4th String or Lower'
										)}
									</div>
								</div>
								<div className="flex flex-col sm:flex-row items-center mb-0">
									{/* Player name */}
									<h1
										className="text-3xl lg:text-4xl font-bold mb-0 cursor-pointer hover:underline"
										onClick={() =>
											handlePlayerClick(player.id, player.position)}
									>
										{player.first_name + ' ' + player.last_name}
									</h1>
									<div className="ml-0 lg:ml-4 mt-2 md:mt-0 flex items-center">
										<StatusWidget
											status={player.status}
											description={player.injury_description}
										/>
									</div>
								</div>
							</div>

							<hr className="mt-4 mb-2 lg:mt-2.5 lg:mb-2.5" />

							{/* Fantasy Fitness Score Card */}
							<div
								className="flex flex-wrap 2xl:flex-nowrap justify-center items-center w-full space-x-0 xl:space-x-4 mt-4 md:mt-0"
								style={{
									marginLeft: window.innerWidth >= 1024 ? '-12px' : '0px' // This is a hack to fix the alignment of the CircularBarCharts. Only sets the margin on page load.
								}}
							>
								<CircularBarChart
									loading={loading}
									title="Overall Score™"
									chartSize="md"
									value={
										player && player.fantasy_fitness_grade ? (
											player.fantasy_fitness_grade
										) : (
											0
										)
									}
									maxPossible={10}
									infoIconText="This is the player's overall Fantasy Fitness Score™. It is calculated based on a combination of the player's production, athleticism, and team environment."
									rank={
										player && player.fantasy_fitness_grade_position_rank ? (
											player.fantasy_fitness_grade_position_rank
										) : (
											999
										)
									}
									abbr_position={getPositionAbbreviation(
										player && player.position ? player.position : ''
									)}
									gradeRanks={
										gradeRanks && gradeRanks.fantasy_fitness_grades ? (
											gradeRanks.fantasy_fitness_grades
										) : (
											[]
										)
									}
									handleRankClick={() =>
										handleRankClick(`fantasy_fitness_score_${player.id}`)}
									isRankVisible={
										visibleRankModal === `fantasy_fitness_score_${player.id}`
									}
									rankModalRef={rankModalRef}
									currentPlayerRef={currentPlayerRef}
									handlePlayerClick={handlePlayerClick}
									currentPlayerId={player && player.id ? player.id : 0}
									hasLabel={true}
									hasRankModalEnabled={true}
								/>
								<CircularBarChart
									loading={loading}
									title="Talent Score™"
									chartSize="sm"
									value={
										player && player.talent_grade ? player.talent_grade : 0
									}
									maxPossible={10}
									infoIconText="This is the player's Fantasy Fitness Talent Score™. It is calculated based on a combination of the player's production and athleticism."
									rank={
										player && player.talent_grade_position_rank ? (
											player.talent_grade_position_rank
										) : (
											999
										)
									}
									abbr_position={getPositionAbbreviation(
										player && player.position ? player.position : ''
									)}
									gradeRanks={
										gradeRanks && gradeRanks.talent_grades ? (
											gradeRanks.talent_grades
										) : (
											[]
										)
									}
									handleRankClick={() =>
										handleRankClick(
											`fantasy_fitness_talent_score_${player.id}`
										)}
									isRankVisible={
										visibleRankModal ===
										`fantasy_fitness_talent_score_${player.id}`
									}
									rankModalRef={rankModalRef}
									currentPlayerRef={currentPlayerRef}
									handlePlayerClick={handlePlayerClick}
									currentPlayerId={player && player.id ? player.id : 0}
									hasLabel={true}
									hasRankModalEnabled={true}
								/>
								<CircularBarChart
									loading={loading}
									title="Team Score™"
									chartSize="sm"
									value={player && player.team_grade ? player.team_grade : 0}
									maxPossible={10}
									infoIconText="This is the player's Fantasy Fitness Team Score™. It is calculated based on a combination of the player's team environment factors."
									rank={
										player && player.team_grade_position_rank ? (
											player.team_grade_position_rank
										) : (
											999
										)
									}
									abbr_position={getPositionAbbreviation(
										player && player.position ? player.position : ''
									)}
									gradeRanks={
										gradeRanks && gradeRanks.team_grades ? (
											gradeRanks.team_grades
										) : (
											[]
										)
									}
									handleRankClick={() =>
										handleRankClick(`fantasy_fitness_team_score_${player.id}`)}
									isRankVisible={
										visibleRankModal ===
										`fantasy_fitness_team_score_${player.id}`
									}
									rankModalRef={rankModalRef}
									currentPlayerRef={currentPlayerRef}
									handlePlayerClick={handlePlayerClick}
									currentPlayerId={player && player.id ? player.id : 0}
									hasLabel={true}
									hasRankModalEnabled={true}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DepthChartCard;
