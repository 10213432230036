import React from 'react';
import { Fragment } from 'react';

export function getPrivacyPolicy() {
	return (
		<Fragment>
			<div className="p-6 max-w-4xl mx-auto">
				<h1 className="text-2xl font-bold mb-4">
					Fantasy Fitness, LLC - Privacy Policy
				</h1>
				<p className="text-gray-700 mb-4">
					<strong>Effective Date: August 4, 2024</strong>
				</p>
				<p className="text-gray-700 mb-4">
					Your privacy is important to us. This Privacy Policy explains how
					Fantasy Fitness, LLC ("Fantasy Fitness", "we", "our", "us") collects,
					uses, and shares information about you when you use our fantasy
					football research and data application, services, and website
					(collectively, the "Service"). By using our Service, you agree to the
					collection and use of information in accordance with this Privacy
					Policy.
				</p>

				<h2 className="text-xl font-semibold mt-6 mb-2">
					1. Information We Collect
				</h2>
				<p className="text-gray-700 mb-4">
					We collect the following types of information:
				</p>
				<ul className="list-disc list-inside pl-5 mb-4">
					<li>
						<strong>Personal Information:</strong> When you create an account,
						subscribe to our Service, or contact us, we may collect personal
						information such as your name, email address, payment information,
						and any other information you choose to provide.
					</li>
					<li>
						<strong>Usage Information:</strong> We collect information about
						your interactions with our Service, such as the pages or features
						you use, the time and duration of your visits, and other information
						about your use of the Service.
					</li>
					<li>
						<strong>Device Information:</strong> We collect information about
						the device you use to access our Service, including the hardware
						model, operating system and version, unique device identifiers, and
						network information.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6 mb-2">
					2. How We Use Your Information
				</h2>
				<p className="text-gray-700 mb-4">
					We use the information we collect for the following purposes:
				</p>
				<ul className="list-disc list-inside pl-5 mb-4">
					<li>
						<strong>To Provide and Improve Our Service:</strong> We use your
						information to operate, maintain, and enhance our Service, including
						providing customer support and processing transactions.
					</li>
					<li>
						<strong>To Communicate with You:</strong> We use your information to
						send you updates, newsletters, marketing materials, and other
						information that may be of interest to you. You can opt-out of
						receiving these communications at any time.
					</li>
					<li>
						<strong>To Ensure Security and Prevent Fraud:</strong> We use your
						information to protect our Service and our users from unauthorized
						access, misuse, and other fraudulent activities.
					</li>
					<li>
						<strong>To Comply with Legal Obligations:</strong> We use your
						information as required by law, regulation, or legal process.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6 mb-2">
					3. Sharing Your Information
				</h2>
				<p className="text-gray-700 mb-4">
					We do not sell your personal information to third parties. We may
					share your information with:
				</p>
				<ul className="list-disc list-inside pl-5 mb-4">
					<li>
						<strong>Service Providers:</strong> We may share your information
						with third-party service providers who perform services on our
						behalf, such as payment processing, data analysis, and customer
						support.
					</li>
					<li>
						<strong>Legal Requirements:</strong> We may disclose your
						information if required to do so by law or in response to a valid
						legal request, such as a subpoena or court order.
					</li>
					<li>
						<strong>Business Transfers:</strong> In the event of a merger,
						acquisition, or sale of all or a portion of our assets, your
						information may be transferred to the acquiring entity.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6 mb-2">4. Your Choices</h2>
				<p className="text-gray-700 mb-4">
					You have the following choices regarding your information:
				</p>
				<ul className="list-disc list-inside pl-5 mb-4">
					<li>
						<strong>Access and Update:</strong> You can access and update your
						personal information by logging into your account and making the
						necessary changes.
					</li>
					<li>
						<strong>Opt-Out:</strong> You can opt-out of receiving marketing
						communications from us by following the unsubscribe instructions
						included in those communications or by contacting us directly.
					</li>
					<li>
						<strong>Delete:</strong> You can request that we delete your
						personal information by contacting us. Please note that we may
						retain certain information as required by law or for legitimate
						business purposes.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6 mb-2">5. Security</h2>
				<p className="text-gray-700 mb-4">
					We take reasonable measures to protect your information from
					unauthorized access, use, or disclosure. However, no method of
					transmission over the internet or electronic storage is completely
					secure, and we cannot guarantee the absolute security of your
					information.
				</p>

				<h2 className="text-xl font-semibold mt-6 mb-2">
					6. Children's Privacy
				</h2>
				<p className="text-gray-700 mb-4">
					Our Service is not intended for children under the age of 13. We do
					not knowingly collect personal information from children under 13. If
					we become aware that we have collected personal information from a
					child under 13, we will take steps to delete such information.
				</p>

				<h2 className="text-xl font-semibold mt-6 mb-2">
					7. Changes to This Privacy Policy
				</h2>
				<p className="text-gray-700 mb-4">
					We may update this Privacy Policy from time to time. We will notify
					you of any changes by posting the new Privacy Policy on our website
					and updating the "Effective Date" at the top of this Privacy Policy.
					Your continued use of the Service after any such changes constitutes
					your acceptance of the new Privacy Policy.
				</p>

				<h2 className="text-xl font-semibold mt-6 mb-2">8. Contact Us</h2>
				<p className="text-gray-700 mb-4">
					If you have any questions about this Privacy Policy, please contact us
					at:
				</p>
				<p className="text-gray-700 mb-4">
					Fantasy Fitness, LLC<br />
					Email: matt@fantasyfitness.io<br />
				</p>
			</div>
		</Fragment>
	);
}
