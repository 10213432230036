import React from 'react';

export default class TimelineBarChart extends React.Component {
	constructor(props) {
		super(props);

		const data = this.props.data || [];

		this.state = {
			data: data,
			loading: data && data.length ? false : true
		};

		this.loadData = this.loadData.bind(this);
	}

	componentDidMount() {
		if (!this.state.data || this.state.data.length === 0) {
			this.loadData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.endpoint !== this.props.endpoint) {
			this.loadData();
		}
	}

	render() {
		// console.log('DailyBarChart', this.state);
		const dataArr = this.state.data.map((d) => d.value);
		let maxVal = Math.max(...dataArr);
		// console.log(maxVal);

		return (
			<div className="DailyBarChart">
				{this.state.loading ? (
					<div className="loadingOverlay">
						<div className="spinner" />
					</div>
				) : null}
				<div className="DailyBarChart-bars-container">
					<div>
						{this.state.data.map((d, idx) => (
							<div key={d.date}>
								<Tooltip text={`${d.date}: ${Number(d.value).toLocaleString()} ${this.props.title}`}>
									<div
										className={'bar ' + (d.value === 0 ? 'bar-empty' : '')}
										style={{
											height: `calc(${d.value / maxVal * 100}% - 15px)`,
											left: `${54 * idx}px`
										}}
									/>
								</Tooltip>
							</div>
						))}
					</div>
					<div className="DailyBarChart-dates-container">
						<div>
							{this.state.data.map((d, idx) => (
								<div key={d.date}>
									<div
										className="date"
										style={{
											left: `${54 * idx}px`
										}}
									>
										{d.date}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}

	loadData() {
		this.setState({ loading: true });
		$.ajax({
			type: 'POST',
			url: this.props.endpoint
		}).done((data) => {
			//console.log('DailyBarChart loadData done', data);
			this.setState({ data: data, loading: false });
		});
	}
}
