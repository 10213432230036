import React from 'react';
import { Fragment } from 'react';

export function getTermsOfService() {
	return (
		<Fragment>
			<div className="container mx-auto px-4 py-8">
				<h1 className="text-3xl font-bold mb-4">
					Fantasy Fitness, LLC - Terms of Service
				</h1>
				<p className="text-sm text-gray-600 mb-8">
					<strong>Effective Date: August 4, 2024</strong>
				</p>
				<p className="mb-6">
					Welcome to Fantasy Fitness, LLC ("Fantasy Fitness", "we", "our",
					"us"). These Terms of Service ("Terms") govern your use of our fantasy
					football research and data application, services, and website
					(collectively, the "Service"). By accessing or using our Service, you
					agree to be bound by these Terms. If you do not agree with these
					Terms, please do not use our Service.
				</p>

				<h2 className="text-2xl font-semibold mb-4">1. Eligibility</h2>
				<p className="mb-6">
					You must be at least 18 years old to use our Service. If you are under
					18, you may use the Service only with the involvement and consent of a
					parent or guardian. By using our Service, you represent and warrant
					that you meet this requirement or have obtained the necessary parental
					consent.
				</p>

				<h2 className="text-2xl font-semibold mb-4">2. Account Registration</h2>
				<p className="mb-4">
					To access certain features of our Service, you may be required to
					create an account. When you create an account, you agree to:
				</p>
				<ul className="list-disc list-inside mb-6">
					<li>Provide accurate, current, and complete information.</li>
					<li>
						Maintain the security and confidentiality of your account
						credentials.
					</li>
					<li>
						Notify us immediately of any unauthorized use of your account.
					</li>
				</ul>

				<h2 className="text-2xl font-semibold mb-4">
					3. Subscription and Billing
				</h2>
				<p className="mb-4">
					Fantasy Fitness offers subscription-based access to premium features
					of the Service. By subscribing, you agree to the following:
				</p>
				<ul className="list-disc list-inside mb-6">
					<li>
						<strong>Subscription Fees:</strong> You agree to pay the
						subscription fees as outlined on our pricing page. Subscription fees
						are billed monthly from the day you sign up.
					</li>
					<li>
						<strong>Payment Method:</strong> You authorize Stripe (our
						integrated payment service) to charge your chosen payment method for
						the subscription fees. You agree to provide accurate and complete
						payment information and to promptly update any changes to your
						payment details.
					</li>
					<li>
						<strong>Refunds:</strong> Subscription fees are non-refundable
						except as required by law or as otherwise stated in these Terms.
					</li>
				</ul>

				<h2 className="text-2xl font-semibold mb-4">4. Use of the Service</h2>
				<p className="mb-4">
					You agree to use our Service for lawful purposes only and in
					compliance with all applicable laws. You agree not to:
				</p>
				<ul className="list-disc list-inside mb-6">
					<li>
						Use the Service in any manner that could interfere with, disrupt, or
						negatively affect the Service or other users.
					</li>
					<li>
						Use the Service for any commercial purpose without our prior written
						consent.
					</li>
					<li>
						Misuse any data, content, or other materials provided through the
						Service.
					</li>
				</ul>

				<h2 className="text-2xl font-semibold mb-4">5. User Content</h2>
				<p className="mb-6">
					You may have the opportunity to submit, post, or otherwise make
					available content through our Service ("User Content"). By submitting
					User Content, you grant us a non-exclusive, worldwide, royalty-free,
					transferable, sublicensable license to use, reproduce, distribute,
					modify, display, and perform your User Content in connection with the
					Service.
				</p>

				<h2 className="text-2xl font-semibold mb-4">
					6. Intellectual Property
				</h2>
				<p className="mb-6">
					All intellectual property rights in the Service, including but not
					limited to, the content, features, and functionality, are owned by
					Fantasy Fitness or our licensors. You agree not to use, copy, modify,
					or distribute any content from the Service without our prior written
					consent.
				</p>

				<h2 className="text-2xl font-semibold mb-4">
					7. Embedded Content from Third Parties
				</h2>
				<p className="mb-6">
					Our Service may include embedded content (such as videos and articles)
					created by third parties. This content is provided for your
					convenience and information. The respective owners of this content
					retain all rights to their content, and it is subject to the terms and
					conditions of those third-party platforms (e.g., YouTube). We do not
					claim ownership of such third-party content and disclaim all liability
					for any third-party content included in our Service.
				</p>

				<h2 className="text-2xl font-semibold mb-4">8. Privacy</h2>
				<p className="mb-6">
					Your privacy is important to us. Our Privacy Policy explains how we
					collect, use, and share your information. By using the Service, you
					agree to our Privacy Policy.
				</p>

				<h2 className="text-2xl font-semibold mb-4">
					9. Disclaimer of Warranties
				</h2>
				<p className="mb-6">
					The Service is provided on an "as-is" and "as-available" basis.
					Fantasy Fitness makes no representations or warranties of any kind,
					express or implied, regarding the Service, including but not limited
					to, the accuracy, reliability, or completeness of any content or data
					provided through the Service.
				</p>

				<h2 className="text-2xl font-semibold mb-4">
					10. Limitation of Liability
				</h2>
				<p className="mb-4">
					To the maximum extent permitted by law, Fantasy Fitness shall not be
					liable for any indirect, incidental, special, consequential, or
					punitive damages, or any loss of profits or revenues, whether incurred
					directly or indirectly, or any loss of data, use, goodwill, or other
					intangible losses, resulting from:
				</p>
				<ul className="list-disc list-inside mb-6">
					<li>Your use or inability to use the Service.</li>
					<li>
						Any unauthorized access to or use of our servers and/or any personal
						information stored therein.
					</li>
					<li>
						Any bugs, viruses, trojan horses, or the like that may be
						transmitted to or through our Service by any third party.
					</li>
				</ul>

				<h2 className="text-2xl font-semibold mb-4">11. Indemnification</h2>
				<p className="mb-6">
					You agree to indemnify and hold harmless Fantasy Fitness, its
					affiliates, and their respective officers, directors, employees, and
					agents from and against any claims, liabilities, damages, losses, and
					expenses, including reasonable attorneys' fees, arising out of or in
					any way connected with your use of the Service or your violation of
					these Terms.
				</p>

				<h2 className="text-2xl font-semibold mb-4">
					12. Changes to the Terms
				</h2>
				<p className="mb-6">
					We may update these Terms from time to time. We will notify you of any
					changes by posting the new Terms on our website and updating the
					"Effective Date" at the top of these Terms. Your continued use of the
					Service after any such changes constitutes your acceptance of the new
					Terms.
				</p>

				<h2 className="text-2xl font-semibold mb-4">13. Termination</h2>
				<p className="mb-6">
					We may terminate or suspend your access to the Service at any time,
					without prior notice or liability, for any reason, including if you
					breach these Terms. Upon termination, your right to use the Service
					will immediately cease.
				</p>

				<h2 className="text-2xl font-semibold mb-4">14. Governing Law</h2>
				<p className="mb-6">
					These Terms shall be governed by and construed in accordance with the
					laws of the State of [Insert State], without regard to its conflict of
					law principles.
				</p>

				<h2 className="text-2xl font-semibold mb-4">15. Contact Information</h2>
				<p className="mb-6">
					If you have any questions about these Terms, please contact us at:
				</p>
				<p className="mb-6">
					Fantasy Fitness, LLC<br />
					Email: matt@fantasyfitness.io<br />
				</p>
				<p className="mb-6">
					By using our Service, you acknowledge that you have read, understood,
					and agree to be bound by these Terms.
				</p>
			</div>
		</Fragment>
	);
}
