import React from 'react';
import PrimaryButton from '../../Buttons/PrimaryButton';
import Carousel from '../../Carousel/BasicCardCarousel';
import Accordion from '../../Accordion/Accordion';
import PricingTable from '../../PricingTable/PricingTable';
import macBookPlayerImage from '../../../../assets/images/device_images/macbook_bijan.svg';
import macBookDraftTrackerImage from '../../../../assets/images/device_images/macbook-draft-tracker.svg';
import macBookRosterProfileImage from '../../../../assets/images/device_images/macbook-roster-profile.svg';
import groupIPhoneImage from '../../../../assets/images/device_images/iPhoneGroup.svg';
import iPhonePlayerProfileSummary from '../../../../assets/images/device_images/iphone-player-profile-summary.jpg';
import iPhonePlayerProfileFFS from '../../../../assets/images/device_images/iphone-player-profile-ffs.jpg';
import iPhoneRosterIndex from '../../../../assets/images/device_images/iphone-roster-index.svg';
import iPhoneRosterProfileImage from '../../../../assets/images/device_images/iphone-roster-profile.svg';

export default function MarketingPage({
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) {
	return (
		<div>
			{/* Intro Section */}
			<section className="bg-cover bg-center bg-white py-12">
				<div className="grid grid-cols-12 h-full text-black">
					<div className="col-span-12 xl:col-span-5 px-5 ml-4 sm:ml-10 lg:ml-20 xl:ml-52 mr-5 xl:mr-20 mt-8 xl:mt-20 px-8">
						<h1
							className="font-bold mb-4 text-6xl lg:text-8xl leading-loose"
							style={{ lineHeight: '1.25' }}
						>
							Dominate your leagues.
						</h1>
						<p className="text-xl mb-8 font-light text-gray-500">
							Unparalleled data-driven player insights and advanced tools to
							help you dominate your fantasy football leagues.
						</p>
						<PrimaryButton
							label="Learn more"
							url="#introducing-fantasy-fitness"
							onShowcase={true}
						/>
					</div>
					<div className="col-span-12 xl:col-span-7 flex justify-center items-center pt-4 pt-6 xl:pt-0 xl:mr-10 mt-16 xl:mt-16">
						<img
							src={macBookPlayerImage}
							alt="MacBook Player"
							className="w-3/4 hidden md:block"
						/>
					</div>
				</div>
			</section>

			{/* Introducing Fantasy Fitness Section */}
			<section
				id="introducing-fantasy-fitness"
				className="bg-cover bg-center bg-gray-50 container text-center py-12 px-6"
			>
				<h2 className="text-5xl mb-12 font-semibold">
					Introducing Fantasy Fitness
				</h2>
				<Carousel
					slideSpeed={5000}
					images={[
						groupIPhoneImage,
						macBookDraftTrackerImage,
						macBookRosterProfileImage,
						groupIPhoneImage
					]}
					mobileImages={[
						iPhonePlayerProfileSummary,
						iPhonePlayerProfileFFS,
						iPhoneRosterIndex,
						iPhoneRosterProfileImage
					]}
				/>
				<div className="text-center pt-10">
					<div className="grid grid-cols-1 md:grid-cols-3 gap-8 space-y-20 md:space-y-0 mt-0 xl:mt-20">
						<div className="px-5">
							<i className="icon fas fa-check-circle fa-4x mb-3 text-gray-700 hover:text-gray-800" />
							<h3 className="text-2xl mb-2">Easy to use</h3>
							<p>
								Our mobile-friendly app makes it quick and easy to manage your
								fantasy football teams.
							</p>
						</div>
						<div className="px-5">
							<i className="icon fas fa-cogs fa-4x mb-3 text-gray-700 hover:text-gray-800" />
							<h3 className="text-2xl mb-2">Top-tier tools</h3>
							<p>
								Advanced features to help you make informed decisions and gain a
								competitive edge.
							</p>
						</div>
						<div className="px-5">
							<i className="icon fas fa-trophy fa-4x mb-3 text-gray-700 hover:text-gray-800" />
							<h3 className="text-2xl mb-2">A winning mindset</h3>
							<p>
								Expert insights and data-driven strategies to help you dominate
								your leagues.
							</p>
						</div>
					</div>
				</div>
			</section>

			{/* Pricing Section */}
			<section className="bg-cover bg-center bg-white py-12 px-6">
				<h2
					className="mb-6 text-center font-bold"
					style={{
						fontSize: '4rem'
					}}
				>
					Our Pricing
				</h2>
				<p className="text-2xl mb-6 text-center">
					Choose the plan that's right for you...
				</p>
				<PricingTable
					className="overflow-y-none"
					stripe_product_essential_id={stripe_product_essential_id}
					stripe_product_advanced_id={stripe_product_advanced_id}
					stripe_product_ultimate_id={stripe_product_ultimate_id}
				/>
			</section>

			{/* Your Questions, Answered Section */}
			<section className="container mt-16 py-12  px-6">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
					<div>
						<h3 className="text-3xl md:text-5xl mb-6">
							Your questions, <span className="block">answered</span>
						</h3>
					</div>
					<div>
						<Accordion
							title="Is Fantasy Fitness right for you?"
							body="Fantasy Fitness is perfect for fantasy football enthusiasts looking for advanced tools and comprehensive insights to enhance their game."
						/>
						<Accordion
							title="What features are included in each plan?"
							body="Each plan offers unique features tailored to different needs. Essential provides the basics, Advanced includes more tools, and Ultimate offers the most comprehensive suite."
						/>
						<Accordion
							title="How do I upgrade or downgrade my plan?"
							body="You can easily upgrade or downgrade your plan through your account settings at any time."
						/>
						<Accordion
							title="How do I get support if I need help?"
							body="Contact matt@fantasyfitness.io for any support inquiries."
						/>
					</div>
				</div>
			</section>
		</div>
	);
}
