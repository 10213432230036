export default function RbFortyTimeValueToColorHelper(value) {
	if (value <= 4.3) {
		return '#1ABE10';
	} else if (value <= 4.35) {
		return '#4BC818';
	} else if (value <= 4.4) {
		return '#7DD220';
	} else if (value <= 4.45) {
		return '#AFDC28';
	} else if (value <= 4.5) {
		return '#E1E630';
	} else if (value <= 4.55) {
		return '#EDCC2E';
	} else if (value <= 4.6) {
		return '#E9A227';
	} else if (value <= 4.65) {
		return '#E57820';
	} else if (value <= 4.7) {
		return '#E04E1A';
	} else if (value > 4.7) {
		return '#DC2413';
	}
}
