import React, { useState, Fragment } from 'react';
import CircularBarChart from '../../../Charts/CircularBarChart';

const ComparePlayerAthleticismScores = ({ selectedPlayers }) => {
	// States for each tooltip
	const [isSizeSpeedHovered, setIsSizeSpeedHovered] = useState(false);
	const [isExplosivenessHovered, setIsExplosivenessHovered] = useState(false);
	const [isAgilityHovered, setIsAgilityHovered] = useState(false);

	const tooltipStyles = {
		position: 'absolute',
		top: '100%',
		left: '125px',
		transform: 'translateX(-50%)',
		whiteSpace: 'wrap',
		width: '250px'
	};

	const tooltipContent = {
		sizeSpeed: "This score is calculated based on the combination of the player's height, weight, and 40 time. It provides context to the raw 40 time by considering the player's size, resulting in a more accurate representation of their speed.",
		explosiveness: "This score is calculated based on a combination of the player's vertical jump and broad jump, which are widely accepted measures of a player's explosiveness.",
		agility: "This score is calculated based on a combination of the player's 3-cone drill and shuttle times, which are widely accepted measures of a player's lateral quickness and change of direction ability."
	};

	const renderTooltip = (isHovered, content) => (
		isHovered && (
			<div
				className="p-2 bg-white border border-gray-300 shadow-lg rounded-lg text-sm z-10"
				style={tooltipStyles}
			>
				{content}
			</div>
		)
	);

	return (
		<Fragment>
			{/* Size/Speed */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Size/Speed
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isSizeSpeedHovered ? 'text-gray-700' : ''}`}
							onMouseEnter={() => setIsSizeSpeedHovered(true)}
							onMouseLeave={() => setIsSizeSpeedHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isSizeSpeedHovered, tooltipContent.sizeSpeed)}
					</div>
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`fitness-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							<CircularBarChart
								title="Size/Speed"
								chartSize="xs"
								value={player && player.speed_grade ? player.speed_grade : 0}
								maxPossible={10}
								infoIconText={tooltipContent.sizeSpeed}
								hasTitle={false}
								hasLabel={false}
								hasRankModalEnabled={false}
							/>
						</div>
					</td>
				))}
			</tr>
			{/* Explosiveness */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Explosiveness
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isExplosivenessHovered ? 'text-gray-700' : ''}`}
							onMouseEnter={() => setIsExplosivenessHovered(true)}
							onMouseLeave={() => setIsExplosivenessHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isExplosivenessHovered, tooltipContent.explosiveness)}
					</div>
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`fitness-talent-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							<CircularBarChart
								title="Explosiveness"
								chartSize="xs"
								value={player && player.burst_grade ? player.burst_grade : 0}
								maxPossible={10}
								infoIconText={tooltipContent.explosiveness}
								hasTitle={false}
								hasLabel={false}
								hasRankModalEnabled={false}
							/>
						</div>
					</td>
				))}
			</tr>
			{/* Agility */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Agility
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isAgilityHovered ? 'text-gray-700' : ''}`}
							onMouseEnter={() => setIsAgilityHovered(true)}
							onMouseLeave={() => setIsAgilityHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isAgilityHovered, tooltipContent.agility)}
					</div>
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`fitness-team-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							<CircularBarChart
								title="Agility"
								chartSize="xs"
								value={player && player.agility_grade ? player.agility_grade : 0}
								maxPossible={10}
								infoIconText={tooltipContent.agility}
								hasTitle={false}
								hasLabel={false}
								hasRankModalEnabled={false}
							/>
						</div>
					</td>
				))}
			</tr>
		</Fragment>
	);
};

export default ComparePlayerAthleticismScores;
