import React from 'react';

const FilterByPlatform = ({ filter, onChange }) => {
	const teams = [
		'Yahoo!',
		'ESPN',
		'NFL',
		'Sleeper',
		'FFPC',
		'Underdog'
	];

	const handlePlatformFilterChange = (event) => {
		const { value } = event.target;
		onChange('platform', value);
	};

	return (
		<div className="flex items-center my-2 lg:my-0 mr-2 md:mr-4">
			<label className="text-sm sm:text-base mr-2">Filter By Platform:</label>
			<select
				name="platform"
				value={filter.platform}
				onChange={handlePlatformFilterChange}
				className="p-1.5 md:p-2 border border-gray-300 rounded-md text-xs sm:text-base"
			>
				<option value="">All</option>
				{teams.map((platform) => (
					<option key={platform} value={platform}>
						{platform}
					</option>
				))}
			</select>
		</div>
	);
};

export default FilterByPlatform;
