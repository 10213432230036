import React from 'react';
import { Link } from 'react-router-dom';

export default function StatusWidget({
	status,
	description,
	hideLabel,
	is50pXThumbnail,
	is75pxThumbnail
}) {
	function determineMakerColor(status) {
		if (status.includes('Healthy') || status.includes('Active')) {
			return 'bg-green-500';
		} else if (
			status.includes('Questionable') ||
			status.includes('Probable') ||
			status.includes('Doubtful')
		) {
			return 'bg-yellow-500';
		} else {
			return 'bg-red-500';
		}
	}

	return (
		<div className="relative flex items-center">
			{status && (
				<div
					title={status && description ? `${status} (${description})` : status}
					className={
						`${is50pXThumbnail
							? 'h-1.5 w-1.5'
							: is75pxThumbnail
								? 'h-2 w-2'
								: 'h-2.5 w-2.5'} rounded-full inline-block ` +
						determineMakerColor(status)
					}
				/>
			)}
			<span className="ml-1.5">
				<span className="text-gray-500">
					{status && description && !hideLabel ? (
						`${status} (${description})`
					) : status && !hideLabel ? (
						status
					) : (
						''
					)}
				</span>
			</span>
		</div>
	);
}
