import React, { useState } from 'react';

export default function Accordion(props) {
	const [
		showAccordion,
		setShowAccordion
	] = useState(false);
	const onClickAccordion = () => setShowAccordion(!showAccordion);

	return (
		<div className="Accordion w-full min-w-96 rounded-md pb-6 text-gray-800 text-sm md:text-lg">
			<div className="grid grid-cols-12 overflow-auto">
				<div className="col-span-12 pb-6 border border-b-1 border-t-0 border-l-0 border-r-0 ">
					<span className="inline-block text-2xl font-light">
						{props.title}
					</span>
					<div className="float-right">
						{showAccordion === false ? (
							<i
								className="fa fa-chevron-down mr-1.5 text-gray-400 text-sm"
								aria-hidden="true"
								onClick={onClickAccordion}
							/>
						) : showAccordion === true ? (
							<i
								className="fa fa-chevron-up mr-1.5 text-gray-400 text-sm"
								aria-hidden="true"
								onClick={onClickAccordion}
							/>
						) : (
							''
						)}
					</div>
					{showAccordion && (
						<div className="bg-gray-100 mt-2 mb-4 rounded-md">
							<div>
								<span className="inline-block pr-6">{props.body}</span>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
