import React from 'react';

const FilterByFormat = ({ filter, onChange }) => {
	const formats = [
		'Best Ball',
		'Dynasty',
		'Redraft'
	];

	const handleFormatFilterChange = (event) => {
		const { value } = event.target;
		onChange('format', value);
	};

	return (
		<div className="flex items-center my-2 lg:my-0 mr-2 md:mr-4">
			<label className="text-sm sm:text-base mr-2">Filter By Format:</label>
			<select
				name="format"
				value={filter.format}
				onChange={handleFormatFilterChange}
				className="p-1.5 md:p-2 border border-gray-300 rounded-md text-xs sm:text-base"
			>
				<option value="">All</option>
				{formats.map((format) => (
					<option key={format} value={format}>
						{format}
					</option>
				))}
			</select>
		</div>
	);
};

export default FilterByFormat;
