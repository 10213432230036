const getBarPropsByPosition = (position) => {
	switch (position.toLowerCase()) {
		case 'quarterback':
			return {
				label: 'Avg QB',
				value: 4.81,
				lowerRange: 4.4,
				upperRange: 5.3
			};
		case 'running back':
			return {
				label: 'Avg RB',
				value: 4.5,
				lowerRange: 4.2,
				upperRange: 4.6
			};
		case 'wide receiver':
			return {
				label: 'Avg WR',
				value: 4.49,
				lowerRange: 4.2,
				upperRange: 4.6
			};
		case 'tight end':
			return {
				label: 'Avg TE',
				value: 4.73,
				lowerRange: 4.4,
				upperRange: 5.1
			};
		default:
			return { label: 'No Player', value: 0 };
	}
};

export default getBarPropsByPosition;
