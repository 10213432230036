import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import PlayerProfileCard from '../../Player/PlayerProfileCard/PlayerProfileCard';
import TeamProfileCard from '../../Team/TeamProfileCard/TeamProfileCard';
import FilterByPosition from '../../Filters/FilterByPosition';
import FilterByTeam from '../../Filters/FilterByTeam';
import FilterBySearchInput from '../../Filters/FilterBySearchInput';
import FilterByIsRookie from '../../Filters/FilterByIsRookie';
import ClearFiltersButton from '../../Filters/ClearFilterButton/ClearFiltersButton';
import SortByCategoryDropdown from '../../Sorts/SortByCategory/SortByCategoryDropdown';
import CollegeStatsButtons from '../../Buttons/CollegeStatsButtons/CollegeStatsButtons';
import PlayersProfileStatsTable from '../../Players/DataTables/PlayersProfileStatsTable/PlayersProfileStatsTable';
import QuarterbacksCollegeBasicStatsTable from '../../Players/DataTables/Quarterbacks/QuarterbacksCollegeBasicStatsTable/QuarterbacksCollegeBasicStatsTable';
import QuarterbacksCollegeAdvancedStatsTable from '../../Players/DataTables/Quarterbacks/QuarterbacksCollegeAdvancedStatsTable/QuarterbacksCollegeAdvancedStatsTable';
import RunningBacksCollegeBasicStatsTable from '../../Players/DataTables/RunningBacks/RunningBacksCollegeBasicStatsTable/RunningBacksCollegeBasicStatsTable';
import RunningBacksCollegeAdvancedStatsTable from '../../Players/DataTables/RunningBacks/RunningBacksCollegeAdvancedStatsTable/RunningBacksCollegeAdvancedStatsTable';
import WideReceiversCollegeBasicStatsTable from '../../Players/DataTables/WideReceivers/WideReceiversCollegeBasicStatsTable/WideReceiversCollegeBasicStatsTable';
import WideReceiversCollegeAdvancedStatsTable from '../../Players/DataTables/WideReceivers/WideReceiversCollegeAdvancedStatsTable/WideReceiversCollegeAdvancedStatsTable';
import TightEndsCollegeBasicStatsTable from '../../Players/DataTables/TightEnds/TightEndsCollegeBasicStatsTable/TightEndsCollegeBasicStatsTable';
import TightEndsCollegeAdvancedStatsTable from '../../Players/DataTables/TightEnds/TightEndsCollegeAdvancedStatsTable/TightEndsCollegeAdvancedStatsTable';
import RosterFormModal from '../../Roster/RosterFormModal/RosterFormModal';
import UseRosterBuilderConfirmation from '../../Roster/UseRosterBuilderConfirmation/UseRosterBuilderConfirmation';
import RosterDraftCard from '../../Roster/RosterDraftCard/RosterDraftCard';
import RosterDraftNotesCard from '../../Roster/RosterDraftNotesCard/RosterDraftNotesCard';
import RosterMetricsCard from '../../Roster/RosterMetricsCard/RosterMetricsCard';
import PricingTable from '../../PricingTable/PricingTable';

export default function PlayersIndexCard({
	get_player_endpoint,
	get_players_endpoint,
	get_team_endpoint,
	get_roster_by_user_endpoint,
	create_roster_by_user_endpoint,
	update_roster_by_user_endpoint,
	create_roster_player_by_roster_endpoint,
	delete_roster_player_by_roster_endpoint,
	get_roster_player_note_endpoint,
	create_roster_player_note_endpoint,
	update_roster_player_note_endpoint,
	update_draft_notes_by_roster_endpoint,
	create_checkout_session_endpoint,
	stripe_public_key,
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) {
	// Auth context
	const { user } = useAuth();
	const { subscription } = useAuth();

	// Ref for modals
	const modalRef = useRef();

	// State variable for players
	const [
		players,
		setPlayers
	] = useState([]);

	// State variables for selected college stats type ('basic' or 'advanced')
	const [
		selectedCollegeStatsType,
		setSelectedCollegeStatsType
	] = useState(null);

	// State variables for filtering players
	const [
		isOnlyQuarterbackSelected,
		setIsOnlyQuarterbackSelected
	] = useState(false);
	const [
		isOnlyRunningBackSelected,
		setIsOnlyRunningBackSelected
	] = useState(false);
	const [
		isOnlyWideReceiverSelected,
		setIsOnlyWideReceiverSelected
	] = useState(false);
	const [
		isOnlyTightEndSelected,
		setIsOnlyTightEndSelected
	] = useState(false);

	// State variables for player and team modals
	const [
		isPlayerModalOpen,
		setIsPlayerModalOpen
	] = useState(false);
	const [
		selectedPlayer,
		setSelectedPlayer
	] = useState(null);
	const [
		isTeamModalOpen,
		setIsTeamModalOpen
	] = useState(false);
	const [
		selectedTeam,
		setSelectedTeam
	] = useState([]);

	// State variables for rosters
	const [
		roster,
		setRoster
	] = useState([]);
	const [
		rosters,
		setRosters
	] = useState([]);
	const [
		isUseRosterBuilderConfirmationModalOpen,
		setIsUseRosterBuilderConfirmationModalOpen
	] = useState(false);
	const [
		isCreateRosterModalOpen,
		setIsCreateRosterModalOpen
	] = useState(false);
	const [
		isDeleteRosterModalOpen,
		setIsDeleteRosterModalOpen
	] = useState(false);
	const [
		rosterToDelete,
		setRosterToDelete
	] = useState(null);
	const [
		editingRoster,
		setEditingRoster
	] = useState(null);
	const [
		rosterTeamName,
		setRosterTeamName
	] = useState('');
	const [
		rosterLeagueName,
		setRosterLeagueName
	] = useState('');
	const [
		rosterPlatform,
		setRosterPlatform
	] = useState('');
	const [
		rosterFormat,
		setRosterFormat
	] = useState('');
	const [
		rosterScoring,
		setRosterScoring
	] = useState('');
	const [
		rosterNotes,
		setRosterNotes
	] = useState('');
	const [
		additionalEmails,
		setAdditionalEmails
	] = useState([
		user ? user.email : ''
	]);
	const [
		isPricingTableModalOpen,
		setIsPricingTableModalOpen
	] = useState(false);

	// Filter and sort state variables
	const [
		filter,
		setFilter
	] = useState({ position: [], team: '', isRookie: '' });
	const [
		sortBy,
		setSortBy
	] = useState('fantasy_fitness_grade');
	const [
		sortOrder,
		setSortOrder
	] = useState('desc');
	const [
		search,
		setSearch
	] = useState('');
	const [
		isDraftModeEnabled,
		setIsDraftModeEnabled
	] = useState(false);
	const [
		selectedPlayers,
		setSelectedPlayers
	] = useState([]);

	// State variable for subscription success modal
	const [
		isUserProfileSuccessModalOpen,
		setIsUserProfileSuccessModalOpen
	] = useState(false);

	// State variable for subscription success modal
	const [
		isSubscriptionSuccessModalOpen,
		setIsSubscriptionSuccessModalOpen
	] = useState(false);
	const [
		planName,
		setPlanName
	] = useState('');

	// User profile success modal if present
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		if (params.get('user_profile_success')) {
			setIsUserProfileSuccessModalOpen(true);
		}
	}, []);

	// UseEffect to check query params and open the subscription success modal if present
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		if (params.get('subscription_success')) {
			setIsSubscriptionSuccessModalOpen(true);
			setPlanName(params.get('plan_name'));
		}
	}, []);

	useEffect(
		() => {
			fetchPlayers();

			if (
				selectedCollegeStatsType === 'basic' ||
				selectedCollegeStatsType === 'advanced'
			) {
				fetchPlayers();
			}
		},
		[
			filter,
			sortBy,
			sortOrder,
			search
		]
	);

	const fetchPlayers = () => {
		let url = `${get_players_endpoint}?position=${filter.position.join(
			','
		)}&team=${filter.team}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}&isRookie=${filter.isRookie}`;
		fetch(url)
			.then((response) => response.json())
			.then((result) => {
				setPlayers(result.players || []);
			})
			.catch((error) => {
				console.error('Error fetching players:', error);
			});
	};

	// Need to fetch players from the backend when the player is selected
	const handleFilterChange = (filterKey, value) => {
		setFilter((prevFilter) => ({ ...prevFilter, [filterKey]: value }));
	};

	const handleSearchInputChange = (event) => {
		setSearch(event.target.value);
	};

	const handleSortChange = (event) => {
		const { value } = event.target;
		setSortBy(value);
		// If sorting on a different column, reset default sort order to 'asc'
		if (value !== sortBy) {
			setSortOrder('asc');
		}
	};

	const handleSortOrderChange = (sortColumn, sortOrder) => {
		if (sortOrder === '' || sortOrder === 'desc') {
			setSortBy(sortColumn);
			setSortOrder('asc');
		} else if (sortOrder === 'asc') {
			setSortBy(sortColumn);
			setSortOrder('desc');
		}
	};

	const handleToggleSortOrder = () => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
	};

	const handleCollegeBasicStatsClick = () => {
		setSelectedCollegeStatsType(
			(prevType) => (prevType === 'basic' ? null : 'basic')
		);
	};

	const handleCollegeAdvancedStatsClick = () => {
		setSelectedCollegeStatsType(
			(prevType) => (prevType === 'advanced' ? null : 'advanced')
		);
	};

	const handleClearFilters = () => {
		setFilter({ position: [], team: '', isRookie: '' });
		setSortBy('fantasy_fitness_grade');
		setSortOrder('desc');
		setSearch('');
		setSelectedCollegeStatsType(null);
		setIsOnlyQuarterbackSelected(false);
		setIsOnlyRunningBackSelected(false);
		setIsOnlyWideReceiverSelected(false);
		setIsOnlyTightEndSelected(false);
	};

	const handleDraftModeToggle = () => {
		if (!subscription || Object.keys(subscription).length === 0) {
			setIsPricingTableModalOpen(true);
			return;
		} else if (subscription && subscription.status !== 'active') {
			setIsPricingTableModalOpen(true);
			return;
		} else if (
			subscription &&
			subscription.status === 'active' &&
			subscription.product_name === 'Essential Fantasy Fitness'
		) {
			setIsPricingTableModalOpen(true);
			return;
		} else if (
			subscription &&
			subscription.status === 'active' &&
			(subscription.product_name === 'Advanced Fantasy Fitness' ||
				subscription.product_name === 'Ultimate Fantasy Fitness')
		) {
			const newDraftModeState = !isDraftModeEnabled;
			setIsDraftModeEnabled(newDraftModeState);

			if (newDraftModeState) {
				setIsUseRosterBuilderConfirmationModalOpen(true);
			}
		}
	};

	const handleUseRosterBuilder = () => {
		setIsUseRosterBuilderConfirmationModalOpen(false);
		setIsCreateRosterModalOpen(true);
	};

	const handleSaveRoster = (navigateToNewRoster = false) => {
		const payload = {
			user_id: user.id,
			team_name: rosterTeamName,
			league_name: rosterLeagueName,
			platform: rosterPlatform,
			format: rosterFormat,
			scoring: rosterScoring,
			emails: additionalEmails,
			notes: rosterNotes
		};

		const endpoint = editingRoster
			? update_roster_by_user_endpoint
					.replace('user_id', user.id)
					.replace('roster_id', editingRoster.id)
			: create_roster_by_user_endpoint;

		const method = editingRoster ? 'PUT' : 'POST';

		fetch(endpoint, {
			method: method,
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(payload)
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					// console.log('Roster created:', data.data);
					setRoster(data.data[data.data.length - 1]);
					setRosters(data.data);
					console.log(
						editingRoster ? 'Roster updated:' : 'Roster created:',
						data.data
					);
					if (navigateToNewRoster) {
						const newRosterId = data.data[data.data.length - 1].id;
						navigate(`/rosters/${newRosterId}`);
					}
					closeModal();
				} else {
					console.error(
						editingRoster
							? 'Failed to update roster:'
							: 'Failed to create roster:',
						data.message
					);
				}
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

	const handleEmailChange = (index, value) => {
		const updatedEmails = [
			...additionalEmails
		];
		updatedEmails[index] = value;
		setAdditionalEmails(updatedEmails);
	};

	const handleAddEmail = () => {
		setAdditionalEmails([
			...additionalEmails,
			''
		]);
	};

	const handleRemoveEmail = (index) => {
		const updatedEmails = [
			...additionalEmails
		];
		updatedEmails.splice(index, 1);
		setAdditionalEmails(updatedEmails);
	};

	const handleAddPlayerToRoster = (position, playerId) => {
		if (create_roster_player_by_roster_endpoint) {
			const endpoint =
				create_roster_player_by_roster_endpoint
					.replace('user_id', user.id)
					.replace('roster_id', roster.id)
					.replace('player_id', playerId) +
				'?position=' +
				position;

			fetch(endpoint, {
				method: 'POST'
			})
				.then((response) => response.json())
				.then((data) => {
					console.log('handleAddPlayerToRoster data: ', data);
					setRoster(data.data);
				});
		}
	};

	const closeModal = () => {
		setIsCreateRosterModalOpen(false);
		setIsDeleteRosterModalOpen(false);
		setRosterToDelete(null);
		setEditingRoster(null);
	};

	const handlePlayerSelect = (selectedPlayer, isDraftedByUser) => {
		if (selectedPlayer && roster && roster.length === 0) {
			setSelectedPlayers([
				...selectedPlayers,
				selectedPlayer
			]);
		} else if (selectedPlayer && roster) {
			if (isDraftedByUser) {
				handleAddPlayerToRoster(selectedPlayer.position, selectedPlayer.id);
			}

			setSelectedPlayers([
				...selectedPlayers,
				selectedPlayer
			]);
		}
	};

	const handlePlayerHideFromList = (playerId, playerPosition) => {
		setSelectedPlayers(
			selectedPlayers.filter(
				(player) =>
					!(player.id === playerId && player.position === playerPosition)
			)
		);
	};

	const handlePlayerModalOpen = (player) => {
		setSelectedPlayer(player);
		setIsPlayerModalOpen(true);
	};

	const handleTeamModalOpen = (team) => {
		setSelectedTeam(team);
		setIsTeamModalOpen(true);
	};

	const closePlayerModal = () => {
		setIsPlayerModalOpen(false);
		setSelectedPlayer(null);
	};

	const closeTeamModal = () => {
		setIsTeamModalOpen(false);
		setSelectedTeam(null);
	};

	const closePricingTableModal = () => {
		setIsPricingTableModalOpen(false);
	};

	const closeUserProfileSuccessModal = () => {
		setIsUserProfileSuccessModalOpen(false);

		const url = new URL(window.location);
		const params = new URLSearchParams(url.search);

		if (
			params.has('user_profile_success') &&
			params.get('user_profile_success') === 'true'
		) {
			params.delete('user_profile_success');

			const newUrl = params.toString()
				? `${url.pathname}?${params.toString()}`
				: url.pathname;
			window.history.replaceState({}, '', newUrl);
		}
	};

	const closeSubscriptionSuccessModal = () => {
		setIsSubscriptionSuccessModalOpen(false);

		const url = new URL(window.location);
		const params = new URLSearchParams(url.search);

		if (params.has('subscription_success') && params.has('plan_name')) {
			// Remove the parameters
			params.delete('subscription_success');
			params.delete('plan_name');

			// Construct the new URL
			const newUrl = '/players';
			window.history.replaceState({}, '', newUrl);
		}
	};

	const closePlayerModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPlayerModalOpen(false);
			setSelectedPlayer(null);
		}
	};

	const closeTeamModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsTeamModalOpen(false);
			setSelectedTeam(null);
		}
	};

	const closePricingTableModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPricingTableModalOpen(false);
		}
	};

	const closeUserProfileSuccessModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsUserProfileSuccessModalOpen(false);

			const url = new URL(window.location);
			const params = new URLSearchParams(url.search);

			if (
				params.has('user_profile_success') &&
				params.get('user_profile_success') === 'true'
			) {
				params.delete('user_profile_success');

				const newUrl = params.toString()
					? `${url.pathname}?${params.toString()}`
					: url.pathname;
				window.history.replaceState({}, '', newUrl);
			}
		}
	};

	const closeSubscriptionSuccessModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsSubscriptionSuccessModalOpen(false);

			const url = new URL(window.location);
			const params = new URLSearchParams(url.search);

			if (params.has('subscription_success') && params.has('plan_name')) {
				// Remove the parameters
				params.delete('subscription_success');
				params.delete('plan_name');

				// Construct the new URL
				const newUrl = '/players';
				window.history.replaceState({}, '', newUrl);
			}
		}
	};

	useEffect(
		() => {
			if (
				filter.position.length === 1 &&
				filter.position[0] === 'quarterback'
			) {
				setIsOnlyQuarterbackSelected(true);
			} else {
				setIsOnlyQuarterbackSelected(false);
				setSelectedCollegeStatsType(null);
			}
		},
		[
			filter.position
		]
	);

	useEffect(
		() => {
			if (
				filter.position.length === 1 &&
				filter.position[0] === 'running back'
			) {
				setIsOnlyRunningBackSelected(true);
			} else {
				setIsOnlyRunningBackSelected(false);
				setSelectedCollegeStatsType(null);
			}
		},
		[
			filter.position
		]
	);

	useEffect(
		() => {
			if (
				filter.position.length === 1 &&
				filter.position[0] === 'wide receiver'
			) {
				setIsOnlyWideReceiverSelected(true);
			} else {
				setIsOnlyWideReceiverSelected(false);
				setSelectedCollegeStatsType(null);
			}
		},
		[
			filter.position
		]
	);

	useEffect(
		() => {
			if (filter.position.length === 1 && filter.position[0] === 'tight end') {
				setIsOnlyTightEndSelected(true);
			} else {
				setIsOnlyTightEndSelected(false);
				setSelectedCollegeStatsType(null);
			}
		},
		[
			filter.position
		]
	);

	useEffect(
		() => {
			if (isPlayerModalOpen) {
				document.addEventListener('mousedown', closePlayerModalOnClickOutside);
			} else {
				document.removeEventListener(
					'mousedown',
					closePlayerModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closePlayerModalOnClickOutside
				);
			};
		},
		[
			isPlayerModalOpen
		]
	);

	useEffect(
		() => {
			if (isTeamModalOpen) {
				document.addEventListener('mousedown', closeTeamModalOnClickOutside);
			} else {
				document.removeEventListener('mousedown', closeTeamModalOnClickOutside);
			}
			return () => {
				document.removeEventListener('mousedown', closeTeamModalOnClickOutside);
			};
		},
		[
			isTeamModalOpen
		]
	);

	useEffect(
		() => {
			if (isPricingTableModalOpen) {
				document.addEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closePricingTableModalOnClickOutside
				);
			};
		},
		[
			isPricingTableModalOpen
		]
	);

	useEffect(
		() => {
			if (isUserProfileSuccessModalOpen) {
				document.addEventListener(
					'mousedown',
					closeUserProfileSuccessModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeUserProfileSuccessModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeUserProfileSuccessModalOnClickOutside
				);
			};
		},
		[
			isUserProfileSuccessModalOpen
		]
	);

	useEffect(
		() => {
			if (isSubscriptionSuccessModalOpen) {
				document.addEventListener(
					'mousedown',
					closeSubscriptionSuccessModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeSubscriptionSuccessModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeSubscriptionSuccessModalOnClickOutside
				);
			};
		},
		[
			isSubscriptionSuccessModalOpen
		]
	);

	const renderPlanFeatures = () => {
		switch (planName) {
			case 'essential':
				return (
					<Fragment>
						<p className="mb-2 underline">Essential Fantasy Fitness</p>
						<ul className="list-disc ml-5 text-gray-700 space-y-2">
							<li>Ad-free experience</li>
							<li>Player compare tool</li>
							<li>
								Create and collaborate with co-managers on up to 5 rosters
							</li>
							<li>Each roster includes roster metrics and player notes</li>
						</ul>
					</Fragment>
				);
			case 'advanced':
				return (
					<Fragment>
						<p className="mb-2 underline">Advanced Fantasy Fitness</p>
						<ul className="list-disc ml-5 text-gray-700 space-y-2">
							<li>Ad-free experience</li>
							<li>Player compare tool</li>
							<li>
								Create and collaborate with co-managers on up to 20 rosters
							</li>
							<li>Each roster includes roster metrics and player notes</li>
							<li>
								Draft Tracker with Roster Builder™ - Our cutting-edge draft room
								companion
							</li>
							<li>
								Week 17 opponents list for every player for better team stacking
							</li>
						</ul>
					</Fragment>
				);
			case 'ultimate':
				return (
					<Fragment>
						<p className="mb-2 underline">Ultimate Fantasy Fitness</p>
						<ul className="list-disc ml-5 text-gray-700 space-y-2">
							<li>Ad-free experience</li>
							<li>Player compare tool</li>
							<li>
								Create and collaborate with co-managers on up to 100 rosters
							</li>
							<li>Each roster includes roster metrics and player notes</li>
							<li>
								Draft Tracker with Roster Builder™ - Our cutting-edge draft room
								companion
							</li>
							<li>
								Week 17 opponents list for every player for better team stacking
							</li>
							<li>Player exposure report</li>
						</ul>
					</Fragment>
				);
			default:
				return null;
		}
	};

	return (
		<div>
			<div className="hidden lg:contents mb-10 relative">
				<button
					className={`${isDraftModeEnabled
						? 'bg-green-500'
						: 'bg-blue-500 hover:bg-blue-700'} text-white px-4 py-2 rounded inline-block`}
					style={{
						position: 'absolute',
						top: '160px',
						right: isDraftModeEnabled ? '18.5%' : '270px'
					}}
					onClick={handleDraftModeToggle}
				>
					{isDraftModeEnabled ? (
						'Draft tracking in progress...'
					) : (
						'Enable Draft Tracker with Roster Builder™'
					)}
				</button>

				{/* Roster Draft Player Card */}
				{isDraftModeEnabled &&
				(Array.isArray(roster)
					? roster.length > 0
					: Object.keys(roster).length > 0) && (
					<span
						className="flex flex-col w-full justify-end w-3/5 my-10 inline-block absolute border border-gray-300 rounded-xl shadow-lg bg-white"
						style={{
							top: '4.5%',
							right: '13.5%',
							width: '73%',
							zIndex: 40
						}}
					>
						<RosterDraftCard
							get_roster_by_user_endpoint={get_roster_by_user_endpoint}
							get_player_endpoint={get_player_endpoint}
							get_players_endpoint={get_players_endpoint}
							get_team_endpoint={get_team_endpoint}
							create_roster_player_by_roster_endpoint={
								create_roster_player_by_roster_endpoint
							}
							delete_roster_player_by_roster_endpoint={
								delete_roster_player_by_roster_endpoint
							}
							get_roster_player_note_endpoint={get_roster_player_note_endpoint}
							create_roster_player_note_endpoint={
								create_roster_player_note_endpoint
							}
							update_roster_player_note_endpoint={
								update_roster_player_note_endpoint
							}
							selectedPlayers={selectedPlayers}
							selectedRoster={roster}
							isDraftModeEnabled={isDraftModeEnabled}
						/>
					</span>
				)}

				{/* Roster Draft Notes Card */}
				{isDraftModeEnabled &&
				(Array.isArray(roster)
					? roster.length > 0
					: Object.keys(roster).length > 0) && (
					<div style={{ position: 'absolute', top: 383, right: 21 }}>
						<RosterDraftNotesCard
							user={user}
							roster={roster}
							update_draft_notes_by_roster_endpoint={
								update_draft_notes_by_roster_endpoint
							}
						/>
					</div>
				)}

				{/* Roster Metrics Card */}
				{isDraftModeEnabled &&
				(Array.isArray(roster)
					? roster.length > 0
					: Object.keys(roster).length > 0) && (
					<div style={{ position: 'absolute', top: 383, left: 21 }}>
						<RosterMetricsCard
							roster={roster}
							isDraftModeEnabled={isDraftModeEnabled}
						/>
					</div>
				)}
			</div>

			<div
				className={`flex flex-col lg:flex-row lg:mb-4 justify-between ${isDraftModeEnabled &&
				roster &&
				roster.team_name &&
				roster.league_name
					? 'mt-20'
					: isDraftModeEnabled &&
						roster &&
						roster.team_name &&
						!roster.league_name
						? 'mt-10'
						: ''}`}
			>
				<FilterByPosition filter={filter} onChange={handleFilterChange} />
				<FilterByTeam filter={filter} onChange={handleFilterChange} />
				{/* Filter components */}
				<div className="hidden lg:contents">
					<FilterBySearchInput
						value={search}
						label="Filter By Name:"
						placeholder="First or Last Name"
						onChange={handleSearchInputChange}
						onEnter={fetchPlayers}
					/>
					<FilterByIsRookie filter={filter} onChange={handleFilterChange} />
				</div>
				{/* Mobile Filter components */}
				<div className="lg:hidden contents">
					<div className="flex items-start space-x-2 sm:space-x-4 w-full lg:w-auto">
						<FilterBySearchInput
							value={search}
							label="Filter by Name:"
							placeholder="First or Last Name"
							onChange={handleSearchInputChange}
							onEnter={fetchPlayers}
						/>
						<FilterByIsRookie filter={filter} onChange={handleFilterChange} />
					</div>
					<SortByCategoryDropdown
						sortBy={sortBy}
						handleSortChange={handleSortChange}
						handleToggleSortOrder={handleToggleSortOrder}
						sortOrder={sortOrder}
					/>
				</div>
				<div className="hidden lg:contents">
					<ClearFiltersButton onClick={handleClearFilters} />
				</div>
			</div>

			<div
				className={`${isDraftModeEnabled
					? 'mx-20 lg:mx-30'
					: ''} p-6 lg:p-8 border border-gray-300 rounded-xl shadow-lg bg-white`}
			>
				{/* College Stats Buttons */}
				{(isOnlyQuarterbackSelected ||
					isOnlyRunningBackSelected ||
					isOnlyWideReceiverSelected ||
					isOnlyTightEndSelected) && (
					<CollegeStatsButtons
						selectedCollegeStatsType={selectedCollegeStatsType}
						handleCollegeBasicStatsClick={handleCollegeBasicStatsClick}
						handleCollegeAdvancedStatsClick={handleCollegeAdvancedStatsClick}
					/>
				)}

				{/* PlayersProfileStatsTable */}
				{selectedCollegeStatsType === null && (
					<PlayersProfileStatsTable
						roster={roster}
						players={players}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
						isDraftModeEnabled={isDraftModeEnabled}
						selectedPlayers={selectedPlayers}
						handlePlayerSelect={handlePlayerSelect}
						handlePlayerHideFromList={handlePlayerHideFromList}
						handlePlayerModalOpen={handlePlayerModalOpen}
						handleTeamModalOpen={handleTeamModalOpen}
					/>
				)}

				{/* QuarterbacksCollegeBasicStatsTable */}
				{selectedCollegeStatsType === 'basic' &&
				isOnlyQuarterbackSelected && (
					<QuarterbacksCollegeBasicStatsTable
						players={players}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* QuarterbacksCollegeAdvancedStatsTable */}
				{selectedCollegeStatsType === 'advanced' &&
				isOnlyQuarterbackSelected && (
					<QuarterbacksCollegeAdvancedStatsTable
						players={players}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* RunningBacksCollegeBasicStatsTable */}
				{selectedCollegeStatsType === 'basic' &&
				isOnlyRunningBackSelected && (
					<RunningBacksCollegeBasicStatsTable
						players={players}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* RunningBacksCollegeAdvancedStatsTable */}
				{selectedCollegeStatsType === 'advanced' &&
				isOnlyRunningBackSelected && (
					<RunningBacksCollegeAdvancedStatsTable
						players={players}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* WideReceiversCollegeBasicStatsTable */}
				{selectedCollegeStatsType === 'basic' &&
				isOnlyWideReceiverSelected && (
					<WideReceiversCollegeBasicStatsTable
						players={players}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* WideReceiversCollegeAdvancedStatsTable */}
				{selectedCollegeStatsType === 'advanced' &&
				isOnlyWideReceiverSelected && (
					<WideReceiversCollegeAdvancedStatsTable
						players={players}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* TightEndsCollegeBasicStatsTable */}
				{selectedCollegeStatsType === 'basic' &&
				isOnlyTightEndSelected && (
					<TightEndsCollegeBasicStatsTable
						players={players}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}

				{/* TightEndsCollegeAdvancedStatsTable */}
				{selectedCollegeStatsType === 'advanced' &&
				isOnlyTightEndSelected && (
					<TightEndsCollegeAdvancedStatsTable
						players={players}
						sortBy={sortBy}
						sortOrder={sortOrder}
						handleSortOrderChange={handleSortOrderChange}
					/>
				)}
			</div>

			{/* Player Modal */}
			{isPlayerModalOpen &&
			selectedPlayer && (
				<div
					className="fixed inset-0 z-50 overflow-auto flex w-full"
					style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
				>
					<div
						className="relative p-8 bg-white m-auto flex-col flex rounded-lg"
						style={{ width: '75%' }}
						ref={modalRef}
					>
						<button
							className="absolute top-0 right-0 p-2 text-gray-700 hover:text-gray-900"
							onClick={closePlayerModal}
						>
							<svg
								className="h-6 w-6 fill-current"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M6.225 4.811l.084.073L12 10.587l5.691-5.691a1 1 0 011.497 1.32l-.083.094L13.413 12l5.692 5.692a1 1 0 01-1.32 1.497l-.094-.083L12 13.413l-5.691 5.691a1 1 0 01-1.497-1.32l.083-.094L10.587 12 4.811 6.225a1 1 0 011.32-1.497z" />
							</svg>
						</button>
						<PlayerProfileCard
							player={selectedPlayer}
							get_player_endpoint={get_player_endpoint}
							get_players_endpoint={get_players_endpoint}
						/>
					</div>
				</div>
			)}

			{/* Team Modal */}
			{isTeamModalOpen &&
			selectedTeam && (
				<div
					className="fixed inset-0 z-50 overflow-auto flex w-full"
					style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
				>
					<div
						className="relative p-8 bg-white m-auto flex-col flex rounded-lg"
						style={{ width: '75%' }}
						ref={modalRef}
					>
						<button
							className="absolute top-0 right-0 p-2 text-gray-700 hover:text-gray-900"
							onClick={closeTeamModal}
						>
							<svg
								className="h-6 w-6 fill-current"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M6.225 4.811l.084.073L12 10.587l5.691-5.691a1 1 0 011.497 1.32l-.083.094L13.413 12l5.692 5.692a1 1 0 01-1.32 1.497l-.094-.083L12 13.413l-5.691 5.691a1 1 0 01-1.497-1.32l.083-.094L10.587 12 4.811 6.225a1 1 0 011.32-1.497z" />
							</svg>
						</button>
						<TeamProfileCard
							team={selectedTeam}
							get_team_endpoint={get_team_endpoint}
						/>
					</div>
				</div>
			)}

			{/* Roster Modals */}
			{isCreateRosterModalOpen && (
				<RosterFormModal
					isOpen={isCreateRosterModalOpen}
					closeModal={closeModal}
					handleSaveRoster={handleSaveRoster}
					rosterTeamName={rosterTeamName}
					setRosterTeamName={setRosterTeamName}
					rosterLeagueName={rosterLeagueName}
					setRosterLeagueName={setRosterLeagueName}
					rosterPlatform={rosterPlatform}
					setRosterPlatform={setRosterPlatform}
					rosterFormat={rosterFormat}
					setRosterFormat={setRosterFormat}
					rosterScoring={rosterScoring}
					setRosterScoring={setRosterScoring}
					rosterNotes={rosterNotes}
					setRosterNotes={setRosterNotes}
					additionalEmails={additionalEmails}
					handleEmailChange={handleEmailChange}
					handleAddEmail={handleAddEmail}
					handleRemoveEmail={handleRemoveEmail}
					isDraftModeEnabled={isDraftModeEnabled}
				/>
			)}

			{/* Roster Builder Confirmation Modal */}
			{isUseRosterBuilderConfirmationModalOpen && (
				<UseRosterBuilderConfirmation
					isOpen={isUseRosterBuilderConfirmationModalOpen}
					closeModal={() => setIsUseRosterBuilderConfirmationModalOpen(false)}
					handleUseRosterBuilder={handleUseRosterBuilder}
				/>
			)}

			{/* Pricing Table */}
			{isPricingTableModalOpen && (
				<div
					className="fixed inset-0 flex justify-center items-center"
					style={{
						zIndex: 1000,
						backgroundColor: 'rgba(0, 0, 0, 0.5)'
					}}
				>
					<div
						ref={modalRef}
						className="relative bg-white rounded-lg p-6 shadow-lg"
						style={{ width: 'auto', maxHeight: 'auto', overflowY: 'auto' }}
					>
						<button
							className="absolute top-0 right-0 p-4"
							onClick={closePricingTableModal}
						>
							<svg
								className="h-6 w-6 text-gray-500"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
						<h2 className="text-2xl font-bold mb-4">Our Pricing</h2>
						<div className="overflow-x-auto">
							<div className="flex flex-col">
								<PricingTable
									create_checkout_session_endpoint={
										create_checkout_session_endpoint
									}
									stripe_public_key={stripe_public_key}
									stripe_product_essential_id={stripe_product_essential_id}
									stripe_product_advanced_id={stripe_product_advanced_id}
									stripe_product_ultimate_id={stripe_product_ultimate_id}
								/>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* User Profile Success Modal */}
			{isUserProfileSuccessModalOpen && (
				<div
					className="fixed inset-0 flex justify-center items-center"
					style={{
						zIndex: 1000,
						backgroundColor: 'rgba(0, 0, 0, 0.5)'
					}}
				>
					<div
						ref={modalRef}
						className="relative bg-white rounded-lg p-6 shadow-2xl mx-4 md:mx-auto"
						style={{
							width: 'auto',
							maxHeight: '95%',
							overflowY: 'auto',
							maxWidth: '1200px'
						}}
					>
						<button
							className="absolute top-4 right-4 p-2 text-gray-600 hover:text-gray-900 transition-colors"
							onClick={closeUserProfileSuccessModal}
						>
							<svg
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
						<h2 className="text-xl font-extrabold mb-5 text-gray-800">
							Welcome to Fantasy Fitness!
						</h2>
						<div className="space-y-4 mb-4" style={{ maxWidth: '975px' }}>
							<p className="text-md text-gray-600">
								Enjoy our free features and when you’re ready to take your
								fantasy fitness to the next level, select a plan that's right
								for you.
							</p>
						</div>
						<div>
							<PricingTable
								create_checkout_session_endpoint={
									create_checkout_session_endpoint
								}
								stripe_public_key={stripe_public_key}
								stripe_product_essential_id={stripe_product_essential_id}
								stripe_product_advanced_id={stripe_product_advanced_id}
								stripe_product_ultimate_id={stripe_product_ultimate_id}
							/>
						</div>
						<div className="mt-8 space-y-6">
							<p className="text-gray-700">
								If you have any questions or need assistance, don’t hesitate to
								reach out to Matt at{' '}
								<a
									href="mailto:matt@fantasyfitness.io"
									className="text-blue-600 underline hover:text-blue-800"
								>
									matt@fantasyfitness.io
								</a>.
							</p>
							<p className="font-semibold text-gray-800">
								We hope you enjoy your fantasy fitness journey!
							</p>
						</div>
					</div>
				</div>
			)}

			{/* Subscription Success Modal */}
			{isSubscriptionSuccessModalOpen && (
				<div
					className="fixed inset-0 flex justify-center items-center"
					style={{
						zIndex: 1000,
						backgroundColor: 'rgba(0, 0, 0, 0.6)'
					}}
				>
					<div
						ref={modalRef}
						className="relative bg-white rounded-lg p-8 shadow-2xl max-w-lg w-full mx-4 md:mx-auto"
						style={{ maxHeight: '80%', overflowY: 'auto' }}
					>
						<button
							className="absolute top-4 right-4 p-2 text-gray-600 hover:text-gray-900 transition-colors"
							onClick={closeSubscriptionSuccessModal}
						>
							<svg
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
						<h2 className="text-xl font-extrabold mb-6 text-gray-800">
							Success!
						</h2>
						<div className="space-y-6">
							<p className="text-gray-800">
								Your subscription is now active! Here are the details:
							</p>
							<div className="text-gray-700">{renderPlanFeatures()}</div>
							<p className="text-gray-700">
								If you have any questions or need assistance, don’t hesitate to
								reach out to Matt at{' '}
								<a
									href="mailto:matt@fantasyfitness.io"
									className="text-blue-600 underline hover:text-blue-800"
								>
									matt@fantasyfitness.io
								</a>.
							</p>
							<p className="font-semibold text-gray-800">
								We hope you enjoy your fantasy fitness journey!
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
