import React from 'react';
import { Link } from 'react-router-dom';
import MobileDataTableCard from '../../DataTables/MobileDataTableCard';
import AdvancedDataTable from '../../DataTables/AdvancedDataTable';
import convertInchesToFeetAndInches from '../../../utils/convertInchesToFeetAndInches';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';

function PlayersCard({ players, sortOrder, sortBy, handleSortOrderChange }) {
	return (
		<div className="p-8 border border-gray-300 rounded-xl shadow-lg bg-white">
			<div>
				<div className="contents lg:hidden">
					<MobileDataTableCard
						data={players}
						style="text-gray-800 border-b-2 border-gray-300 font-semibold"
						rowRenderer={(row) => {
							const labels = [
								{
									displayName: 'Player',
									apiName: 'player',
									alignment: 'left'
								},
								{
									displayName: 'Position',
									apiName: 'position',
									alignment: 'left'
								},
								{
									displayName: 'Age (years)',
									apiName: 'age',
									alignment: 'left'
								},
								{
									displayName: 'Height (in.)',
									apiName: 'height',
									alignment: 'left'
								},
								{
									displayName: 'Weight (lbs.)',
									apiName: 'weight',
									alignment: 'left'
								},
								{
									displayName: 'Forty Time (sec)',
									apiName: 'forty_time',
									alignment: 'left'
								},
								{
									displayName: 'Fantasy Fitness Team Score™',
									apiName: 'team_grade',
									alignment: 'left'
								},
								{
									displayName: 'Fantasy Fitness Talent Score™',
									apiName: 'talent_grade',
									alignment: 'left'
								},
								{
									displayName: 'Fantasy Fitness Score™',
									apiName: 'fantasy_fitness_grade',
									alignment: 'left'
								}
							];
							return (
								<div
									key={`${row.first_name} ${row.last_name} - PlayersProfileStatsTable - MobileDataTableCard`}
									className="p-5 bg-white border-t text-gray-800"
								>
									<div>
										<Link
											to={`/players/${row.id}?position=${row.position}`}
											className="text-blue-500 hover:underline"
										>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<div
													style={{
														width: '50px',
														height: '50px',
														overflow: 'hidden',
														marginRight: '8px'
													}}
												>
													<img
														src={row.image_url + '?w=50&h=50'}
														alt={`${row.first_name} ${row.last_name}`}
														style={{
															width: '100%',
															height: '100%',
															objectFit: 'cover',
															border: '1px solid #ddd',
															borderRadius: '50%'
														}}
													/>
												</div>
												{`${row.first_name} ${row.last_name}`}
											</div>
										</Link>
									</div>
									<div className="mt-4 font-semibold">
										{labels[1].displayName}
									</div>
									<div>{getPositionAbbreviation(row.position)}</div>
									<div className="mt-4 font-semibold">
										{labels[2].displayName}
									</div>
									<div>{row.age}</div>
									<div className="mt-4 font-semibold">
										{labels[3].displayName}
									</div>
									<div>{convertInchesToFeetAndInches(row.height)}</div>
									<div className="mt-4 font-semibold">
										{labels[4].displayName}
									</div>
									<div>{row.weight}</div>
									<div className="mt-4 font-semibold">
										{labels[5].displayName}
									</div>
									<div>{row.forty_time}</div>
									<div className="mt-4 font-semibold">
										{labels[6].displayName}
									</div>
									<div>{row.team_grade}</div>
									<div className="mt-4 font-semibold">
										{labels[7].displayName}
									</div>
									<div>{row.talent_grade}</div>
									<div className="mt-4 font-semibold">
										{labels[8].displayName}
									</div>
									<div>{row.fantasy_fitness_grade}</div>
								</div>
							);
						}}
					/>
				</div>
				<div className="hidden lg:contents">
					<AdvancedDataTable
						data={players}
						style="text-gray-800 border-b-2 border-gray-300 font-semibold"
						sortingEnabled={true}
						sortOrder={sortOrder}
						sortColumn={sortBy ? sortBy : ''}
						onChangeSortOrder={handleSortOrderChange}
						columns={[
							{
								displayName: 'Player',
								apiName: 'player',
								alignment: 'left'
							},
							{
								displayName: 'Position',
								apiName: 'position',
								alignment: 'left'
							},
							{
								displayName: 'Age (years)',
								apiName: 'age',
								alignment: 'left'
							},
							{
								displayName: 'Height (in.)',
								apiName: 'height',
								alignment: 'left'
							},
							{
								displayName: 'Weight (lbs.)',
								apiName: 'weight',
								alignment: 'left'
							},
							{
								displayName: 'Forty Time (sec)',
								apiName: 'forty_time',
								alignment: 'left'
							},
							{
								displayName: 'Fantasy Fitness Team Score™',
								apiName: 'team_grade',
								alignment: 'left'
							},
							{
								displayName: 'Fantasy Fitness Talent Score™',
								apiName: 'talent_grade',
								alignment: 'left'
							},
							{
								displayName: 'Fantasy Fitness Score™',
								apiName: 'fantasy_fitness_grade',
								alignment: 'left'
							}
						]}
						rowRenderer={(row) => {
							return (
								<tr
									key={`${row.first_name} ${row.last_name} - PlayersProfileStatsTable - AdvancedDataTable`}
									className="bg-white text-gray-800"
								>
									<td className="pl-8 py-3 text-left">
										<Link
											to={`/players/${row.id}?position=${row.position}`}
											className="text-blue-500 hover:underline"
										>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<div
													style={{
														width: '50px',
														height: '50px',
														overflow: 'hidden',
														marginRight: '8px'
													}}
												>
													<img
														src={row.image_url + '?w=50&h=50'}
														alt={`${row.first_name} ${row.last_name}`}
														style={{
															width: '100%',
															height: '100%',
															objectFit: 'cover',
															border: '1px solid #ddd',
															borderRadius: '50%'
														}}
													/>
												</div>
												{`${row.first_name} ${row.last_name}`}
											</div>
										</Link>
									</td>
									<td className="pl-8 py-3 text-left">
										{getPositionAbbreviation(row.position)}
									</td>
									<td className="pl-8 py-3 text-left">{row.age}</td>
									<td className="pl-8 py-3 text-left">
										{convertInchesToFeetAndInches(row.height)}
									</td>
									<td className="pl-8 py-3 text-left">{row.weight}</td>
									<td className="pl-8 py-3 text-left">{row.forty_time}</td>
									<td className="pl-8 py-3 text-left">{row.team_grade}</td>
									<td className="pl-8 py-3 text-left">{row.talent_grade}</td>
									<td className="pl-8 py-3 text-left">
										{row.fantasy_fitness_grade}
									</td>
								</tr>
							);
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default PlayersCard;
