import React from 'react';

const SmallSchool = () => {
	return (
		<span
			title="Attended a Small School"
			className="ml-2 bg-orange-700 text-white text-xs font-semibold px-2 py-1 rounded"
		>
			SS
		</span>
	);
};

export default SmallSchool;
