import React, { useRef } from 'react';

const UseRosterBuilderConfirmation = ({
	isOpen,
	closeModal,
	handleUseRosterBuilder
}) => {
	const modalRef = useRef(null);

	if (!isOpen) return null;

	return (
		<div
			className="fixed inset-0 z-50 overflow-auto flex w-full"
			style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
		>
			<div
				className="relative p-8 pt-4 bg-white m-auto flex-col flex rounded-lg"
				style={{ width: '40%' }}
				ref={modalRef}
			>
				<button className="absolute top-0 right-0 p-4" onClick={closeModal}>
					<svg
						className="h-6 w-6 text-gray-500"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>
				<h2 className="text-xl font-bold mb-10">
					Build a roster while you draft?
				</h2>
				<p className="text-gray-700 mb-4">
					The Fantasy Fitness Roster Builder™ is an essential tool for drafting
					your winning team.
				</p>
				<ul className="list-disc list-inside text-gray-700 mb-20 ml-4 space-y-2">
					<li>
						Adds players to your roster while you draft so you don't have to
						manually add players later
					</li>
					<li>
						View your roster in real-time to make critical adjustments as you
						draft
					</li>
					<li>
						Track your player exposure across all your rosters to maintain a
						balanced player portfolio
					</li>
					<li>
						Add player notes on a per-roster basis, which is essential for winning
						your dynasty leagues
					</li>
					<li>
						Effortlessly manage and adjust your roster throughout the season
					</li>
				</ul>

				<div className="flex justify-end space-x-4">
					<button
						className="w-16 bg-gray-300 text-gray-800 p-2 rounded-lg hover:bg-gray-400"
						onClick={closeModal}
					>
						No
					</button>
					<button
						className="w-16 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600"
						onClick={handleUseRosterBuilder}
					>
						Yes
					</button>
				</div>
			</div>
		</div>
	);
};

export default UseRosterBuilderConfirmation;
