import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import TeamPortraitCard from '../TeamPortraitCard/TeamPortraitCard';
import FixedTeamBanner from '../FixedTeamBanner/FixedTeamBanner';
import CoachesCard from '../CoachesCard/CoachesCard';
import PlayersCard from '../PlayersCard/PlayersCard';

export default function TeamProfileCard(props) {
	const { id: paramId } = useParams();
	const id = (props.team && props.team.id) || paramId;

	const [
		team,
		setTeam
	] = useState([]);
	const [
		players,
		setPlayers
	] = useState([]);
	const [
		temperature,
		setTemperature
	] = useState(null);

	// Filter and sort state variables
	const [
		sortBy,
		setSortBy
	] = useState('fantasy_fitness_grade');
	const [
		sortOrder,
		setSortOrder
	] = useState('desc');

	const handleSortChange = (event) => {
		const { value } = event.target;
		setSortBy(value);
		// If sorting on a different column, reset default sort order to 'asc'
		if (value !== sortBy) {
			setSortOrder('asc');
		}
	};

	const handleSortOrderChange = (sortColumn, sortOrder) => {
		if (sortOrder === '' || sortOrder === 'desc') {
			setSortBy(sortColumn);
			setSortOrder('asc');
		} else if (sortOrder === 'asc') {
			setSortBy(sortColumn);
			setSortOrder('desc');
		}
	};

	const handleToggleSortOrder = () => {
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
	};

	const handleClearFilters = () => {
		setSortBy('fantasy_fitness_grade');
		setSortOrder('desc');
	};

	useEffect(
		() => {
			window.scrollTo(0, 0); // Scroll to the top of the window when the component mounts

			fetchTeamData();
		},
		[
			id,
			sortBy,
			sortOrder
		]
	);

	useEffect(
		() => {
			if (team.location) {
				fetchTemperature(team.location);
			}
		},
		[
			team.location
		]
	);

	const fetchTeamData = () => {
		fetch(props.get_team_endpoint.replace('id', id))
			.then((response) => response.json())
			.then((result) => {
				setTeam(result.data.team || {});
				setPlayers(result.data.players || []);
			});
	};

	const fetchTemperature = (location) => {
		let city = location.split(',')[0].trim();
		if (city === 'Tampa Bay') {
			city = 'Tampa';
		}
		fetch(
			`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${'336d6d9ed7689fcfacbc7a9cd10f4250'}`
		)
			.then((response) => response.json())
			.then((data) => {
				if (
					data.main &&
					data.main.temp &&
					data.weather &&
					data.weather.length > 0
				) {
					// Convert temperature to Fahrenheit
					const tempInFahrenheit = Math.round(
						(data.main.temp - 273.15) * 9 / 5 + 32
					);
					// Get weather description
					const weatherDescription = data.weather[0].main;
					setTemperature({
						temp: tempInFahrenheit,
						weather: weatherDescription
					});
				}
			})
			.catch((error) => {
				console.error('Error fetching temperature:', error);
			});
	};

	const [
		scrolledPastBanner,
		setScrolledPastBanner
	] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const bannerHeight = document.querySelector('.banner-section')
				.offsetHeight;
			const scrolled = window.scrollY > bannerHeight;
			setScrolledPastBanner(scrolled);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	if (!team) {
		return (
			<div>
				<LoadingSpinner />
			</div>
		);
	}

	return (
		<div>
			{/* Team Portrait Card */}
			<div className="banner-section">
				<TeamPortraitCard team={team} temperature={temperature} />
			</div>

			{/* Fixed Team Banner  - Displays after scrolling past the Team Portrait Card Section */}
			{scrolledPastBanner && (
				<FixedTeamBanner
					scrolledPastBanner={scrolledPastBanner}
					team={team}
					temperature={temperature}
				/>
			)}

			{/* Players Section */}
			<div className="text-3xl font-bold mb-3 mt-14">
				<h4>Players</h4>
			</div>
			<PlayersCard players={players} />

			{/* Coaches Card */}
			<div className="text-3xl font-bold mb-3 mt-14">
				<h4>Coaches</h4>
			</div>
			<CoachesCard team={team} />
		</div>
	);
}
