import React from 'react';

export default class TranslationDateType extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div>
				<span>{I18n.t(this.props.value)}</span>
			</div>
		);
	}
}
