import React from 'react';
import { Fragment } from 'react';

const ComparePlayerRushingStats = ({ selectedPlayers }) => {
  return (
    <Fragment>
      {/* Attempts Per Game */}
      <tr>
        <td className="px-6 whitespace-nowrap font-medium">Rush Attempts Per Game</td>
        {selectedPlayers.map((player, index) => (
          <td key={`attempts-${index}`} className="px-6 py-4">
            {player.pro_rush_attempts_per_game || '-'}
          </td>
        ))}
      </tr>
      {/* Yards Per Game */}
      <tr>
        <td className="px-6 whitespace-nowrap font-medium">Rushing Yards Per Game</td>
        {selectedPlayers.map((player, index) => (
          <td key={`yards-${index}`} className="px-6 py-4">
            {player.pro_rushing_yards_per_game || '-'}
          </td>
        ))}
      </tr>
      {/* Rushing TDs Per Game */}
      <tr>
        <td className="px-6 whitespace-nowrap font-medium">Rushing TDs Per Game</td>
        {selectedPlayers.map((player, index) => (
          <td key={`tds-${index}`} className="px-6 py-4">
            {player.pro_rushing_touchdowns_per_game || '-'}
          </td>
        ))}
      </tr>
    </Fragment>
  );
};

export default ComparePlayerRushingStats;
