import React from 'react';

const FilterByScoringType = ({ filter, onChange }) => {
	const scoringTypes = [
    'Half PPR',
    'Half PPR - Superflex',
    'PPR',
    'PPR - TE Premium',
    'PPR - Superflex',
    'PPR - Superflex - TE Premium',
    'Standard',
    'Standard - Superflex'
	];

	const handleScoringTypeFilterChange = (event) => {
		const { value } = event.target;
		onChange('scoringType', value);
	};

	return (
		<div className="flex items-center my-2 lg:my-0 mr-2 md:mr-4">
			<label className="text-sm sm:text-base mr-2">Filter By Scoring:</label>
			<select
				name="scoringType"
				value={filter.scoringType}
				onChange={handleScoringTypeFilterChange}
				className="p-1.5 md:p-2 border border-gray-300 rounded-md text-xs sm:text-base"
			>
				<option value="">All</option>
				{scoringTypes.map((scoringType) => (
					<option key={scoringType} value={scoringType}>
						{scoringType}
					</option>
				))}
			</select>
		</div>
	);
};

export default FilterByScoringType;