import React from 'react';

export default class HelperText extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="helper-text text-gray-400 italic">
				{this.props.children}
			</div>
		);
	}
}
