const cityAbbreviations = {
	Atlanta: 'ATL',
	Baltimore: 'BAL',
	Buffalo: 'BUF',
	Carolina: 'CAR',
	Chicago: 'CHI',
	Cincinnati: 'CIN',
	Cleveland: 'CLE',
	Charlotte: 'CAR',
	Dallas: 'DAL',
	Denver: 'DEN',
	Detroit: 'DET',
	Foxborough: 'NE',
	Glendale: 'ARI',
	'Green Bay': 'GB',
	Houston: 'HOU',
	Indianapolis: 'IND',
	Jacksonville: 'JAX',
	'Kansas City': 'KC',
	Landover: 'WAS',
	'Las Vegas': 'LV',
	'Los Angeles Chargers': 'LAC',
	'Los Angeles Rams': 'LAR',
	Miami: 'MIA',
	Minneapolis: 'MIN',
	Nashville: 'TEN',
	'New England': 'NE',
	'New Orleans': 'NO',
	'New York Giants': 'NYG',
	'New York Jets': 'NYJ',
	Philadelphia: 'PHI',
	Pittsburgh: 'PIT',
	'San Francisco': 'SF',
	Seattle: 'SEA',
	'Tampa Bay': 'TB',
	Tennessee: 'TEN',
	Washington: 'WAS'
};

const getPlayerTeamCityAbbreviation = (location, team) => {
	if (!location) {
		console.log('Location is undefined or null');
		return null;
	}
	let city = location.split(',')[0].trim();

	if (team === 'Los Angeles Rams') {
		city = 'Los Angeles Rams';
	}
	if (team === 'Los Angeles Chargers') {
		city = 'Los Angeles Chargers';
	}
	if (team === 'New York Giants') {
		city = 'New York Giants';
	}
	if (team === 'New York Jets') {
		city = 'New York Jets';
	}

	return cityAbbreviations[city] || city;
};

export default getPlayerTeamCityAbbreviation;
