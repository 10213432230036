import React from 'react';
import { Link } from 'react-router-dom';

function TeamPortraitCard({ team, temperature }) {
	return (
		<div className="md:col-span-6">
			<div className="grid grid-cols-1 border border-gray-300 rounded-xl shadow-lg pt-2 md:pt-14 lg:pt-2 bg-white">
				<div className="px-4 sm:px-6 lg:px-8 flex items-center justify-between mx-4">
					{/* Player details */}
					<div className="flex items-center flex-col lg:flex-row">
						{/* Player image */}
						<img
							className="h-48 lg:h-56 xl:h-80 rounded mb-4 sm:mb-0 lg:mr-8"
							src={team.image_url}
							alt="Team Logo"
						/>
						<div className="text-center sm:text-left lg:pt-14">
							{/* Player name */}
							<h1 className="text-3xl lg:text-4xl font-bold mb-0 mb-0 mt-4 lg:mt-0">
								{team.name}
							</h1>
							{/* Player team, jersey number, and position */}
							<div className="flex flex-col sm:flex-row items-center sm:items-start">
								<div className="text-center sm:text-left">
									<p className="text-lg font text-gray-500">
										{team.location}
										{temperature !== null &&
											` • ${temperature.temp}\u00B0, ${temperature.weather}`}
									</p>
								</div>
							</div>

							{/* Additional attributes */}
							<div className="flex flex-wrap items-start text-base text-gray-500 mt-3 pb-8">
								{team.conference && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">CONFERENCE</span>
										<span className="ml-2">{team.conference}</span>
									</div>
								)}
								{team.division && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">DIVISION</span>
										<span className="ml-2">{team.division}</span>
									</div>
								)}
								{team.year_established && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">ESTABLISHED</span>
										<span className="ml-2">{team.year_established}</span>
										{/* Change "1970" to the actual established year */}
									</div>
								)}
								{team.championships !== null &&
								team.championships >= 0 && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">CHAMPIONSHIPS</span>
										<span className="ml-2">
											{team.championships === 0 ? 0 : team.championships}
										</span>
									</div>
								)}
								{team.website && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">WEBSITE</span>
										<Link
											to={`https://${team.website}`}
											className="text-blue-500 ml-2 cursor-pointer hover:underline"
											target="_blank"
											rel="noopener noreferrer"
										>
											{team.website}
										</Link>
									</div>
								)}
								{team.twitter || team.instagram || team.facebook ? (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">SOCIAL</span>
										{team.twitter && (
											<Link
												to={team.twitter}
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="fab fa-twitter ml-2 mr-1.5" />
											</Link>
										)}
										{team.instagram && (
											<Link
												to={team.instagram}
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="fab fa-instagram mr-1.5" />
											</Link>
										)}
										{team.facebook && (
											<Link
												to={team.facebook}
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="fab fa-facebook" />
											</Link>
										)}
									</div>
								) : (
									''
								)}
								{team.w_l_odds && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">W/L ODDS</span>
										<Link
											to={
												team.w_l_odds_affiliate_website_url ? (
													team.w_l_odds_affiliate_website_url
												) : (
													''
												)
											}
											className="flex items-center"
											target="_blank"
											rel="noopener noreferrer"
										>
											<span className="ml-2 hover:underline">
												O/U {team.w_l_odds}
											</span>
											{team.w_l_odds_affiliate_image_url && (
												<img
													src={team.w_l_odds_affiliate_image_url}
													alt="Affiliate Logo"
													className="ml-2"
													style={{ height: 25 }}
												/>
											)}
										</Link>
									</div>
								)}
								{team.next_game && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">NEXT GAME</span>
										<span className="ml-2">
											<Link to={`/teams/${team.next_game_id}`}>
												{team.next_game}
											</Link>
										</span>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TeamPortraitCard;
