import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router

export default function FieldErrors(props) {
	const { fieldName = '', fieldErrors = {} } = props;

	if (fieldErrors && fieldErrors.hasOwnProperty(fieldName)) {
		const errors = fieldErrors[fieldName];

		// Check if the array includes the specific string
		const isEmailTaken = errors.includes('has already been taken');

		return (
			<section className="FieldErrors">
				<div className="px-2" style={{ color: '#ff3d60' }}>
					{errors.join(' & ')}
				</div>
				{isEmailTaken && (
					<div className="px-2">
						If this is your account, please{' '}
						<Link
							to="/signin"
							className="signin-link"
							style={{
								color: '#4682b4'
							}}
						>
							sign in
						</Link>
						.
					</div>
				)}
			</section>
		);
	}

	return null;
}
