import React from 'react';

const FilterByAdjGamesPlayed = ({ filter, onChange }) => {
	const handleCheckboxChange = (event) => {
		onChange('isAdjGamesPlayed', event.target.checked);
	};

	const [
		isHovered,
		setIsHovered
	] = React.useState(false);

	return (
		<div className="flex items-center mr-4 my-4">
			<input
				type="checkbox"
				checked={true || false} //replace true with filter.isAdjGamesPlayed
				onChange={handleCheckboxChange}
				className="hidden"
				id="isAdjGamesPlayedCheckbox"
			/>
			<label
				htmlFor="isAdjGamesPlayedCheckbox"
				className="cursor-pointer select-none relative"
			>
				<div
					className={`w-12 h-6 rounded-full shadow-inner ${true //replace true with filter.isAdjGamesPlayed
						? 'bg-gray-800'
						: 'bg-gray-300'}`}
				/>
				<div
					className={`dot absolute top-0 left-0 w-6 h-6 bg-white rounded-full shadow transition transform ${true //replace true with filter.isAdjGamesPlayed
						? 'translate-x-full'
						: ''}`}
				/>
			</label>
			<span className="ml-2">Adjusted College Games Played</span>
			{true && ( //replace true with this.props.infoIconText
				<div className="relative">
					<i
						className={`fas fa-circle-info ml-2 ${isHovered
							? 'text-gray-700'
							: ''}`}
						onMouseEnter={() => this.setState({ isHovered: true })}
						onMouseLeave={() => this.setState({ isHovered: false })}
					/>
					{isHovered && (
						<div
							className="absolute p-2 bg-white border border-gray-300 shadow-lg rounded-lg text-sm w-60 z-10"
							style={{
								top: '100%',
								left: '100%',
								transform: 'translateY(6px) translateX(14px)'
							}}
						>
							{true || 'Information'}{' '}
							{/*replace true with this.props.infoIconText*/}
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default FilterByAdjGamesPlayed;
