import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { Fragment } from 'react/cjs/react.production.min';
import PlayerSearchBar from '../../SearchBars/PlayerSearchBar/PlayerSearchBar';
import PlayerProfileCard from '../../Player/PlayerProfileCard/PlayerProfileCard';
import TeamProfileCard from '../../Team/TeamProfileCard/TeamProfileCard';
import StatusWidget from '../../StatusWidget/StatusWidget';
import CircularBarChart from '../../Charts/CircularBarChart';
import RookieTag from '../../Tags/RookieTag';
import HofTag from '../../Tags/HofTag';
import ContractYearTag from '../../Tags/ContractYearTag';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';
import RosterPlayerNoteModal from '../RosterPlayerNoteModal/RosterPlayerNoteModal';
import RosterPortraitCard from '../RosterPortraitCard/RosterPortraitCard';
import FixedRosterBanner from '../FixedRosterBanner/FixedRosterBanner';
import RosterMetricsCard from '../RosterMetricsCard/RosterMetricsCard';
import Week17OpponentsModal from '../../Player/Week17OpponentsModal/Week17OpponentsModal';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

const RosterProfileCard = ({
	get_roster_by_user_endpoint,
	get_player_endpoint,
	get_players_endpoint,
	get_team_endpoint,
	create_roster_player_by_roster_endpoint,
	delete_roster_player_by_roster_endpoint,
	get_roster_player_note_endpoint,
	create_roster_player_note_endpoint,
	update_roster_player_note_endpoint
}) => {
	const { user } = useAuth();
	const { id } = useParams();
	const modalRef = useRef();
	const [
		roster,
		setRoster
	] = useState(null);
	const [
		selectedPlayer,
		setSelectedPlayer
	] = useState([]);
	const [
		isPlayerModalOpen,
		setIsPlayerModalOpen
	] = useState(false);
	const [
		selectedTeam,
		setSelectedTeam
	] = useState([]);
	const [
		isTeamModalOpen,
		setIsTeamModalOpen
	] = useState(false);
	const [
		isRosterPlayerNoteModalOpen,
		setIsRosterPlayerNoteModalOpen
	] = useState(false);
	const [
		scrolledPastBanner,
		setScrolledPastBanner
	] = useState(false);
	const [
		isPlayerWeek17OpponentsModalOpen,
		setIsPlayerWeek17OpponentsModalOpen
	] = useState(false);

	useEffect(
		() => {
			if (get_roster_by_user_endpoint) {
				const endpoint = get_roster_by_user_endpoint
					.replace('user_id', user.id)
					.replace('roster_id', id);
				fetch(endpoint).then((response) => response.json()).then((data) => {
					setRoster(data.data);
				});
			}
		},
		[
			get_roster_by_user_endpoint,
			user.id,
			id
		]
	);

	const handleRemovePlayer = (position, playerId) => {
		if (delete_roster_player_by_roster_endpoint) {
			const endpoint =
				delete_roster_player_by_roster_endpoint
					.replace('user_id', user.id)
					.replace('roster_id', id)
					.replace('player_id', playerId) +
				'?position=' +
				position;

			fetch(endpoint, {
				method: 'DELETE'
			})
				.then((response) => response.json())
				.then((data) => {
					setRoster(data.data);
				});
		}
	};

	const handleAddPlayer = (position, playerId) => {
		if (create_roster_player_by_roster_endpoint) {
			const endpoint =
				create_roster_player_by_roster_endpoint
					.replace('user_id', user.id)
					.replace('roster_id', id)
					.replace('player_id', playerId) +
				'?position=' +
				position;

			fetch(endpoint, {
				method: 'POST'
			})
				.then((response) => response.json())
				.then((data) => {
					// console.log('handleAddPlayer data: ', data);
					setRoster(data.data);
				});
		}
	};

	const handleOpenRosterPlayerNote = (player) => {
		setSelectedPlayer(player);
		setIsRosterPlayerNoteModalOpen(true);
	};

	const handleRosterPlayerClick = (player) => {
		setSelectedPlayer(player);
		setIsPlayerModalOpen(true);
	};

	const handleTeamClick = (team) => {
		setSelectedTeam(team);
		setIsTeamModalOpen(true);
	};

	const handleOpenPlayerWeek17OpponentsModal = (player) => {
		setSelectedPlayer(player);
		setIsPlayerWeek17OpponentsModalOpen(true);
	};

	const closePlayerModal = () => {
		setIsPlayerModalOpen(false);
		setSelectedPlayer(null);
	};

	const closeTeamModal = () => {
		setIsTeamModalOpen(false);
		setSelectedTeam(null);
	};

	const closeWeek17OpponentsModal = () => {
		setIsPlayerWeek17OpponentsModalOpen(false);
	};

	const closePlayerModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPlayerModalOpen(false);
			setSelectedPlayer(null);
		}
	};

	const closeTeamModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsTeamModalOpen(false);
			setSelectedTeam(null);
		}
	};

	const closeRosterPlayerNoteModalOnClickOutside = (event) => {
		if (!modalRef.current.contains(event.target)) {
			setIsRosterPlayerNoteModalOpen(false);
		}
	};

	const closeWeek17OpponentsModalOnClickOutside = (event) => {
		if (!modalRef.current.contains(event.target)) {
			setIsPlayerWeek17OpponentsModalOpen(false);
		}
	};

	useEffect(
		() => {
			if (isPlayerModalOpen) {
				document.addEventListener('mousedown', closePlayerModalOnClickOutside);
			} else {
				document.removeEventListener(
					'mousedown',
					closePlayerModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closePlayerModalOnClickOutside
				);
			};
		},
		[
			isPlayerModalOpen
		]
	);

	useEffect(
		() => {
			if (isTeamModalOpen) {
				document.addEventListener('mousedown', closeTeamModalOnClickOutside);
			} else {
				document.removeEventListener('mousedown', closeTeamModalOnClickOutside);
			}
			return () => {
				document.removeEventListener('mousedown', closeTeamModalOnClickOutside);
			};
		},
		[
			isTeamModalOpen
		]
	);

	useEffect(
		() => {
			if (isRosterPlayerNoteModalOpen) {
				document.addEventListener(
					'mousedown',
					closeRosterPlayerNoteModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeRosterPlayerNoteModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeRosterPlayerNoteModalOnClickOutside
				);
			};
		},
		[
			isRosterPlayerNoteModalOpen
		]
	);

	useEffect(
		() => {
			if (isPlayerWeek17OpponentsModalOpen) {
				document.addEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			};
		},
		[
			isPlayerWeek17OpponentsModalOpen
		]
	);

	useEffect(() => {
		const handleScroll = () => {
			const bannerHeight = document.querySelector('.banner-section')
				.offsetHeight;
			const scrolled = window.scrollY > bannerHeight;
			setScrolledPastBanner(scrolled);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const rankModalRef = useRef(null);
	const currentPlayerRef = useRef(null);
	const [
		gradeRanks,
		setGradeRanks
	] = useState([]);
	const [
		visibleRankModal,
		setVisibleRankModal
	] = useState(null);
	const [
		loading,
		setLoading
	] = useState(false);

	// Handle rank modal click
	const handleRankClick = async (gradeType, position) => {
		if (visibleRankModal === gradeType) {
			setVisibleRankModal(null);
		} else {
			setLoading(true);
			setVisibleRankModal(gradeType);

			try {
				const response = await fetch(
					`/api/v1/players/position-ranks/${encodeURIComponent(position)}`
				);
				if (!response.ok) throw new Error('Network response was not ok');
				const data = await response.json();
				setLoading(false);
				setGradeRanks(data);
			} catch (error) {
				console.error('Failed to fetch grade ranks:', error);
			}
		}
	};
	// Handle player click
	const handlePlayerClick = (playerId, playerPosition) => {
		setVisibleRankModal(null);
		window.location.href = `/players/${playerId}?position=${playerPosition}`;
	};

	// Scroll to the selected player in the modal
	useEffect(
		() => {
			setTimeout(() => {
				if (visibleRankModal && currentPlayerRef.current) {
					currentPlayerRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center'
					});
				}
			}, 500);
		},
		[
			visibleRankModal,
			currentPlayerRef
		]
	);

	// Handle clicks outside the rank modal to close it
	const handleClickOutside = (event) => {
		if (rankModalRef.current && !rankModalRef.current.contains(event.target)) {
			setVisibleRankModal(null);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	if (!roster) {
		return (
			<div>
				<LoadingSpinner />
			</div>
		);
	}

	const renderPlayers = (position, players) => (
		<div className="mt-10">
			<h3
				className="text-xl text-gray-800 font-bold"
				style={{ marginBottom: '-10px' }}
			>
				{position.toUpperCase()}
			</h3>
			<div>
				{players.map((player) => (
					<div
						key={player.id}
						className="flex flex-col lg:flex-row justify-between items-center mb-2 border-gray-200 border pt-6 rounded-xl my-6 shadow-sm bg-white"
					>
						<div className="flex items-center justify-center lg:w-96 lg:ml-10">
							<div className="flex items-center">
								<div className="relative mr-4">
									<img
										src={`${player.image_url}?w=75&h=75`}
										alt={`${player.first_name} ${player.last_name}`}
										style={{
											width: '75px',
											height: '75px',
											objectFit: 'cover',
											border: '1px solid #ddd',
											borderRadius: '50%',
											backgroundColor: 'white'
										}}
									/>
									<div className="absolute top-2 left-12 transform translate-x-1/2 -translate-y-1/2">
										<StatusWidget
											status={player.status}
											description={player.injury_description}
											hideLabel={true}
											is75pxThumbnail={true}
										/>
									</div>
								</div>
								<div>
									<div
										className="text-2xl font-bold hover:underline cursor-pointer"
										onClick={() => handleRosterPlayerClick(player)}
									>
										{player.first_name} {player.last_name}
									</div>
									<div>
										<div
											className="text-lg text-blue-500 hover:underline cursor-pointer"
											onClick={() => handleTeamClick(player.team)}
										>
											{player.team.name}
										</div>
									</div>
									{player.draft_year && (
										<div className="flex items-center mr-6 mb-2 text-gray-700">
											<span className="font-bold uppercase">EXP</span>
											<span className="ml-2 flex items-center">
												{`${new Date().getFullYear() - player.draft_year} yrs`}
												{(new Date().getFullYear() - player.draft_year === 0 ||
													player.is_rookie) && <RookieTag />}
												{player.is_hof && <HofTag />}
												{player.is_contract_year && <ContractYearTag />}
											</span>
										</div>
									)}
									<i
										key={player.id + player.position}
										className="fa-regular fa-note-sticky fa-lg text-blue-500 rounded mr-3 cursor-pointer"
										alt="Manager Player Notes"
										onClick={() => handleOpenRosterPlayerNote(player)}
									/>
									<i
										key={player.id + player.position}
										className="fa-solid fa-yin-yang fa-lg text-blue-500 rounded mr-3 cursor-pointer"
										alt="Week 17 Opponents"
										onClick={() => handleOpenPlayerWeek17OpponentsModal(player)}
									/>
								</div>
							</div>
						</div>
						<div className="flex flex-wrap justify-center items-center w-full md:flex-row space-y-6 lg:space-y-0 md:space-x-4 2xl:space-x-20 mt-4 lg:mt-0">
							<CircularBarChart
								loading={loading}
								title="Overall Score™"
								chartSize="sm"
								value={
									player && player.fantasy_fitness_grade ? (
										player.fantasy_fitness_grade
									) : (
										0
									)
								}
								maxPossible={10}
								infoIconText="This is the player's Fantasy Fitness Score™. It combines the player's Fantasy Fitness Talent Score™ and Fantasy Fitness Team Score™ to estimate their overall value."
								rank={
									player && player.fantasy_fitness_grade_position_rank ? (
										player.fantasy_fitness_grade_position_rank
									) : (
										999
									)
								}
								abbr_position={getPositionAbbreviation(
									player && player.position ? player.position : ''
								)}
								gradeRanks={
									gradeRanks && gradeRanks.fantasy_fitness_grades ? (
										gradeRanks.fantasy_fitness_grades
									) : (
										[]
									)
								}
								handleRankClick={() =>
									handleRankClick(
										`fantasy_fitness_score_${player.id}_${player.last_name}`,
										player.position
									)}
								isRankVisible={
									visibleRankModal ===
									`fantasy_fitness_score_${player.id}_${player.last_name}`
								}
								rankModalRef={rankModalRef}
								currentPlayerRef={currentPlayerRef}
								handlePlayerClick={handlePlayerClick}
								currentPlayerId={player && player.id ? player.id : 0}
								hasLabel={true}
								hasRankModalEnabled={true}
							/>
							<CircularBarChart
								loading={loading}
								title="Talent Score™"
								chartSize="xs"
								value={player && player.talent_grade ? player.talent_grade : 0}
								maxPossible={10}
								infoIconText="This is the player's Fantasy Fitness Talent Score™. It is calculated based on a combination of the player's production and athleticism."
								rank={
									player && player.talent_grade_position_rank ? (
										player.talent_grade_position_rank
									) : (
										999
									)
								}
								abbr_position={getPositionAbbreviation(
									player && player.position ? player.position : ''
								)}
								gradeRanks={
									gradeRanks && gradeRanks.talent_grades ? (
										gradeRanks.talent_grades
									) : (
										[]
									)
								}
								handleRankClick={() =>
									handleRankClick(
										`fantasy_fitness_talent_score_${player.id}_${player.last_name}`,
										player.position
									)}
								isRankVisible={
									visibleRankModal ===
									`fantasy_fitness_talent_score_${player.id}_${player.last_name}`
								}
								rankModalRef={rankModalRef}
								currentPlayerRef={currentPlayerRef}
								handlePlayerClick={handlePlayerClick}
								currentPlayerId={player && player.id ? player.id : 0}
								hasLabel={true}
								hasRankModalEnabled={true}
							/>
							<CircularBarChart
								loading={loading}
								title="Team Score™"
								chartSize="xs"
								value={player && player.team_grade ? player.team_grade : 0}
								maxPossible={10}
								infoIconText="This is the player's Fantasy Fitness Team Score™. It is calculated based on a combination of the player's team environment factors."
								rank={
									player && player.team_grade_position_rank ? (
										player.team_grade_position_rank
									) : (
										999
									)
								}
								abbr_position={getPositionAbbreviation(
									player && player.position ? player.position : ''
								)}
								gradeRanks={
									gradeRanks && gradeRanks.team_grades ? (
										gradeRanks.team_grades
									) : (
										[]
									)
								}
								handleRankClick={() =>
									handleRankClick(
										`fantasy_fitness_team_score_${player.id}_${player.last_name}`,
										player.position
									)}
								isRankVisible={
									visibleRankModal ===
									`fantasy_fitness_team_score_${player.id}_${player.last_name}`
								}
								rankModalRef={rankModalRef}
								currentPlayerRef={currentPlayerRef}
								handlePlayerClick={handlePlayerClick}
								currentPlayerId={player && player.id ? player.id : 0}
								hasLabel={true}
								hasRankModalEnabled={true}
							/>
						</div>
						<button
							className="text-sm text-gray-500 hover:text-gray-800 mb-4 lg:mb-0 lg:mr-8"
							onClick={() => handleRemovePlayer(player.position, player.id)}
						>
							Remove
						</button>
					</div>
				))}
			</div>
		</div>
	);

	return (
		<div>
			<div className="banner-section">
				<RosterPortraitCard roster={roster} />
			</div>
			{/* Fixed Roster Banner  - Displays after scrolling past the Roster Portrait Card Section */}
			{scrolledPastBanner && (
				<FixedRosterBanner
					scrolledPastBanner={scrolledPastBanner}
					roster={roster}
				/>
			)}

			{/* Roster Metrics Card */}
			<div className="mt-14">
				<RosterMetricsCard roster={roster} />
			</div>

			{/* Roster Players */}
			<div className="mt-14 relative">
				<div className="absolute" style={{ top: -33, right: -15 }}>
					<PlayerSearchBar
						getPlayersEndpoint={get_players_endpoint}
						handleAddPlayer={handleAddPlayer}
						isRosterBuilder={true}
						isVisibleByDefault={true}
						isCompareModal={false}
						isDarkMode={true}
					/>
				</div>
				{roster.players && (
					<Fragment>
						{renderPlayers('Quarterbacks', roster.players.quarterbacks)}
						{renderPlayers('Running Backs', roster.players.running_backs)}
						{renderPlayers('Wide Receivers', roster.players.wide_receivers)}
						{renderPlayers('Tight Ends', roster.players.tight_ends)}
					</Fragment>
				)}
			</div>
			{/* Player Modal */}
			{isPlayerModalOpen &&
			selectedPlayer && (
				<div
					className="fixed inset-0 z-50 overflow-auto flex w-full"
					style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
				>
					<div
						className="relative p-8 bg-white m-auto flex-col flex rounded-lg"
						style={{ width: '93%' }}
						ref={modalRef}
					>
						<button
							className="absolute top-0 right-0 p-2 text-gray-700 hover:text-gray-900"
							onClick={closePlayerModal}
						>
							<svg
								className="h-6 w-6 fill-current"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M6.225 4.811l.084.073L12 10.587l5.691-5.691a1 1 0 011.497 1.32l-.083.094L13.413 12l5.692 5.692a1 1 0 01-1.32 1.497l-.094-.083L12 13.413l-5.691 5.691a1 1 0 01-1.497-1.32l.083-.094L10.587 12 4.811 6.225a1 1 0 011.32-1.497z" />
							</svg>
						</button>
						<PlayerProfileCard
							player={selectedPlayer}
							get_player_endpoint={get_player_endpoint}
							get_players_endpoint={get_players_endpoint}
						/>
					</div>
				</div>
			)}
			{/* Team Modal */}
			{isTeamModalOpen &&
			selectedTeam && (
				<div
					className="fixed inset-0 z-50 overflow-auto flex w-full"
					style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
				>
					<div
						className="relative p-8 bg-white m-auto flex-col flex rounded-lg"
						style={{ width: '93%' }}
						ref={modalRef}
					>
						<button
							className="absolute top-0 right-0 p-2 text-gray-700 hover:text-gray-900"
							onClick={closeTeamModal}
						>
							<svg
								className="h-6 w-6 fill-current"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M6.225 4.811l.084.073L12 10.587l5.691-5.691a1 1 0 011.497 1.32l-.083.094L13.413 12l5.692 5.692a1 1 0 01-1.32 1.497l-.094-.083L12 13.413l-5.691 5.691a1 1 0 01-1.497-1.32l.083-.094L10.587 12 4.811 6.225a1 1 0 011.32-1.497z" />
							</svg>
						</button>
						<TeamProfileCard
							team={selectedTeam}
							get_team_endpoint={get_team_endpoint}
						/>
					</div>
				</div>
			)}

			{/* Roster Player Note Modal */}
			{isRosterPlayerNoteModalOpen && (
				<RosterPlayerNoteModal
					userId={user.id}
					rosterId={id}
					player={selectedPlayer}
					closeRosterPlayerNoteModal={() =>
						setIsRosterPlayerNoteModalOpen(false)}
					get_roster_player_note_endpoint={get_roster_player_note_endpoint}
					create_roster_player_note_endpoint={
						create_roster_player_note_endpoint
					}
					update_roster_player_note_endpoint={
						update_roster_player_note_endpoint
					}
					modalRef={modalRef}
				/>
			)}

			{/* Week 17 Opponents Modal */}
			{isPlayerWeek17OpponentsModalOpen && (
				<Week17OpponentsModal
					player={selectedPlayer}
					closeWeek17OpponentsModal={closeWeek17OpponentsModal}
					modalRef={modalRef}
				/>
			)}
		</div>
	);
};

export default RosterProfileCard;
