import React, { Fragment } from 'react';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';
import getPlayerTeamCityAbbreviation from '../../../utils/getPlayerTeamCityAbbreviation';

const PlayersRankTableModal = ({
	isVisible,
	rankModalRef,
	handlePlayerClick,
	gradeRanks,
	currentPlayerId,
	currentPlayerRef,
	displayPosition,
	loading,
	renderOnComparePlayerModal
}) => {
	if (!isVisible) return null;

	return (
		<div
			className="absolute bg-white border border-gray-300 shadow-lg rounded-lg text-sm w-80 z-10 overflow-y-auto"
			style={{
				top: renderOnComparePlayerModal ? 'calc(100% + 15px)' : 'calc(100% + 10px)',
				left: renderOnComparePlayerModal ? '120px' : '50%',
				transform: 'translateX(-50%)',
				maxHeight: '350px'
			}}
			ref={rankModalRef}
		>
			<div className="max-h-screen">
				{loading ? (
					<div className="p-4 text-center">
						<LoadingSpinner />
					</div>
				) : gradeRanks && gradeRanks.length > 0 ? (
					<table className="min-w-full relative">
						<thead className="sticky top-0 bg-gray-100">
							<tr>
								<th className="pl-4 pr-3 py-4 text-left text-xs text-gray-800 font-bold tracking-wider">
									Rank
								</th>
								<th className="px-3 py-4 text-left text-xs text-gray-800 font-bold tracking-wider">
									Name
								</th>
								<th className="pl-3 pr-4 py-4 text-left text-xs text-gray-800 font-bold tracking-wider">
									Score
								</th>
							</tr>
						</thead>
						<tbody>
							{gradeRanks.map((player) => (
								<tr
									key={player.id}
									className={`hover:bg-gray-100 ${player.id === currentPlayerId
										? 'font-bold'
										: ''}`}
									onClick={() => handlePlayerClick(player.id, player.position)}
								>
									<td className="pl-4 pr-3 py-2 whitespace-nowrap">
										{displayPosition ? (
											<Fragment>
												<span className="mr-1">
													{getPositionAbbreviation(player.position)}
												</span>
												{player.rank}
											</Fragment>
										) : (
											player.rank
										)}
									</td>
									<td
										className={`text-blue-500 px-3 py-2 whitespace-nowrap hover:underline cursor-pointer ${player.id ===
										currentPlayerId
											? 'font-bold hover:no-underline hover:cursor-none'
											: ''}`}
										style={
											player.id === currentPlayerId ? { color: '#373D3F' } : {}
										}
										ref={
											player.id === currentPlayerId ? currentPlayerRef : null
										}
									>
										{player.first_name} {player.last_name}{' '}
										{player.team ? (
											`(${getPlayerTeamCityAbbreviation(
												player.team.location,
												player.team.name
											)})`
										) : (
											''
										)}
									</td>
									<td
										className={`pl-3 pr-4 py-2 whitespace-nowrap ${player.id ===
										currentPlayerId
											? 'font-bold'
											: ''}`}
									>
										{player.score}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					<div className="p-4 text-center">No results found.</div>
				)}
			</div>
		</div>
	);
};

export default PlayersRankTableModal;
