import React from 'react';

export default class Header extends React.Component {
	constructor(props) {
		super(props);

		this.handleUpdateSortOrder = this.handleUpdateSortOrder.bind(this);
	}

	render() {
		return (
			<div
				className="Header"
				onClick={this.props.column.apiName ? this.handleUpdateSortOrder : null}
			>
				<span className="Header-value">
					{this.props.column.displayName}
					{this.props.sortingEnabled ? (
						<span className="Header-sortIcon m-2">
							{this.renderSortOrderIcon()}
						</span>
					) : (
						''
					)}
				</span>
			</div>
		);
	}

	renderSortOrderIcon() {
		if (!this.props.column.apiName) {
			return '';
		} else if (this.props.column.apiName) {
			let { sortColumn, sortOrder } = this.props;

			if (sortColumn !== this.props.column.apiName) {
				return null;
			} else {
				if (sortOrder === 'asc') {
					return <i className="fa-solid fa-sort-up" />;
				} else if (sortOrder === 'desc') {
					return <i className="fa-solid fa-sort-down" />;
				} else if (sortOrder !== 'asc' || sortOrder !== 'desc') {
					return null;
				}
			}
		}
	}

	handleUpdateSortOrder(e) {
		this.props.onChangeSortOrder(
			this.props.column.apiName,
			this.props.sortOrder
		);
	}
}
