import React from 'react';

const PasswordResetHelpModal = ({ isOpen, onClose, modalRef }) => {
	if (!isOpen) return null;

	return (
		<div
			className="fixed inset-0 z-50 overflow-auto flex w-full"
			style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
		>
			<div
				className="relative py-4 px-8 bg-white m-auto flex-col flex rounded-lg"
				ref={modalRef}
			>
				<button
					className="absolute top-0 right-0 p-2 text-gray-700 hover:text-gray-900"
					onClick={onClose}
				>
					<svg
						className="h-6 w-6 fill-current"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M6.225 4.811l.084.073L12 10.587l5.691-5.691a1 1 0 011.497 1.32l-.083.094L13.413 12l5.692 5.692a1 1 0 01-1.32 1.497l-.094-.083L12 13.413l-5.691 5.691a1 1 0 01-1.497-1.32l.083-.094L10.587 12 4.811 6.225a1 1 0 011.32-1.497z" />
					</svg>
				</button>
				<h2 className="text-xl font-bold mb-4">Password Reset Help</h2>
				<p>
					Email{' '}
					<a
						href="mailto:matt@fantasyfitness.io?subject=Password Reset Request"
						className="text-blue-500 hover:underline"
					>
						matt@fantasyfitness.io
					</a>{' '}
					to get help resetting your password.
				</p>
			</div>
		</div>
	);
};

export default PasswordResetHelpModal;
