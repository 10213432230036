export default function TeFortyTimeValueToColorHelper(value) {
	if (value <= 4.5) {
		return '#1ABE10';
	} else if (value <= 4.575) {
		return '#4BC818';
	} else if (value <= 4.65) {
		return '#7DD220';
	} else if (value <= 4.725) {
		return '#AFDC28';
	} else if (value <= 4.8) {
		return '#E1E630';
	} else if (value <= 4.875) {
		return '#EDCC2E';
	} else if (value <= 4.95) {
		return '#E9A227';
	} else if (value <= 5.025) {
		return '#E57820';
	} else if (value <= 5.1) {
		return '#E04E1A';
	} else if (value > 5.1) {
		return '#DC2413';
	}
}
