import React from 'react';
import { Fragment } from 'react';
import StatusWidget from '../../../StatusWidget/StatusWidget';
import RookieTag from '../../../Tags/RookieTag';
import HofTag from '../../../Tags/HofTag';
import ContractYearTag from '../../../Tags/ContractYearTag';
import convertInchesToFeetAndInches from '../../../../utils/convertInchesToFeetAndInches';

const ComparePlayerGeneralInfo = ({ selectedPlayers }) => {
	return (
		<Fragment>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">Name</td>
				{selectedPlayers.map((player, index) => (
					<td key={`name-${index}`} className="px-6 py-4">
						<div className="flex items-center">
							<div className="relative mr-4">
								<img
									src={`${player.image_url}?w=75&h=75`}
									alt={`${player.first_name} ${player.last_name}`}
									style={{
										width: '75px',
										height: '75px',
										objectFit: 'cover',
										border: '1px solid #ddd',
										borderRadius: '50%',
										backgroundColor: 'white'
									}}
								/>
								<div className="absolute top-2 left-12 transform translate-x-1/2 -translate-y-1/2">
									<StatusWidget
										status={player.status}
										description={player.injury_description}
										hideLabel={true}
										is75pxThumbnail={true}
									/>
								</div>
							</div>
							<div>{`${player.first_name} ${player.last_name}`}</div>
						</div>
					</td>
				))}
			</tr>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">Team</td>
				{selectedPlayers.map((player, index) => (
					<td key={`team-${index}`} className="px-6 py-4">
						{player && player.team && player.team.image_url && (
							<img
								src={`${player.team.image_url}?w=30&h=30`}
								alt={player.team.name}
								className="mr-2 inline"
							/>
						)}
						{player && player.team && player.team.name}
					</td>
				))}
			</tr>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">HT/WT</td>
				{selectedPlayers.map((player, index) => (
					<td key={`htwt-${index}`} className="px-6 py-4">
						{convertInchesToFeetAndInches(player.height)}, {player.weight} lbs.
					</td>
				))}
			</tr>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">Age</td>
				{selectedPlayers.map((player, index) => (
					<td key={`age-${index}`} className="px-6 py-4">
						{player.age}
					</td>
				))}
			</tr>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">Draft Pick (Year)</td>
				{selectedPlayers.map((player, index) => (
					<td key={`draft-${index}`} className="px-6 py-4">
						{player.draft_pick} ({player.draft_year})
					</td>
				))}
			</tr>
			<tr>
				<td className="px-6 py-4 whitespace-nowrap font-medium">Experience</td>
				{selectedPlayers.map((player, index) => (
					<td key={`experience-${index}`} className="px-6 py-4">
						{`${new Date().getFullYear() - player.draft_year} yrs`}
						{(new Date().getFullYear() - player.draft_year === 0 ||
							player.is_rookie) && <RookieTag />}
						{player.is_hof && <HofTag />}
						{player.is_contract_year && <ContractYearTag />}
					</td>
				))}
			</tr>
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">40-Time (sec.)</td>
				{selectedPlayers.map((player, index) => (
					<td key={`attempts-${index}`} className="px-6 py-4">
						{player.forty_time || '-'}
					</td>
				))}
			</tr>
		</Fragment>
	);
};

export default ComparePlayerGeneralInfo;
