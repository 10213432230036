import React from 'react';

export default class DateDataType extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let date = this.props.value;

		if (date === null) {
			return '-';
		} 

		let formattedDate = new Date(date).toLocaleDateString();

		return (
			<div>
				<span>{formattedDate}</span>
			</div>
		);
	}
}
