import React from 'react';
import { getRosterPlatformUrl } from '../../../utils/getRosterPlatformUrl';

function RosterPortraitCard({
	roster,
	isDraftModeEnabled,
	handleOpenRosterPlayersSection
}) {
	const platformUrl = getRosterPlatformUrl(roster.platform);

	return (
		<div className="md:col-span-6">
			<div
				className={`grid grid-cols-1 border border-gray-300 rounded-xl shadow-lg pt-2 ${isDraftModeEnabled
					? 'md:pt-10'
					: 'lg:pt-0'} bg-white`}
			>
				<div className="px-4 sm:px-6 lg:px-8 flex items-center justify-between mx-4">
					{/* Roster details */}
					<div className="flex items-center flex-col sm:flex-row">
						{/* Roster image */}
						{roster.image_url && (
							<img
								className="h-48 md:60 lg:h-80 rounded mb-4 sm:mb-0 sm:mr-8"
								src={roster.image_url}
								alt="Roster Logo"
							/>
						)}
						<div
							className={`text-center sm:text-left ${isDraftModeEnabled
								? 'lg:pt-2.5'
								: 'lg:pt-6'}`}
						>
							{/* Roster/Team name */}
							<h1 className="text-3xl lg:text-4xl font-bold mt-4 mb-2">
								{roster.team_name}
							</h1>
							{/* Roster league name */}
							<div className="flex flex-col sm:flex-row items-center sm:items-start">
								<div className="text-center sm:text-left">
									<p className="text-lg font text-gray-500">
										{roster.league_name}
									</p>
								</div>
							</div>

							{/* Additional attributes */}
							<div
								className={`flex flex-wrap items-start text-base text-gray-500 mt-3 ${isDraftModeEnabled
									? 'pb-2.5'
									: 'pb-5 lg:pb-8'}`}
							>
								{roster.platform && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">PLATFORM</span>
										<span className="ml-2">
											{platformUrl ? (
												<a
													href={platformUrl}
													target="_blank"
													rel="noopener noreferrer"
													className="text-blue-500 hover:underline"
												>
													{roster.platform}
												</a>
											) : (
												<span>{roster.platform}</span>
											)}
										</span>
									</div>
								)}
								{roster.format && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">FORMAT</span>
										<span className="ml-2">{roster.format}</span>
									</div>
								)}
								{roster.scoring && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">SCORING</span>
										<span className="ml-2">{roster.scoring}</span>
									</div>
								)}
								{roster.users && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">MANAGERS</span>
										<span className="ml-2">
											{roster.users.map((user) => user.email).join(', ')}
										</span>
									</div>
								)}
								{roster.notes && (
									<div className="flex items-center mr-6 mb-2">
										<span className="font-bold uppercase">NOTES</span>
										<span className="ml-2">{roster.notes}</span>
									</div>
								)}
							</div>
						</div>
					</div>
					{isDraftModeEnabled && (
						<div
							onClick={() => handleOpenRosterPlayersSection()}
							className="ml-auto cursor-pointer"
						>
							Hide/Show Players
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default RosterPortraitCard;
