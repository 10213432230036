const getWeek17Opponents = () => {
	return {
		'Kansas City Chiefs': 'Pittsburgh Steelers',
		'Pittsburgh Steelers': 'Kansas City Chiefs',
		'Baltimore Ravens': 'Houston Texans',
		'Houston Texans': 'Baltimore Ravens',
		'Seattle Seahawks': 'Chicago Bears',
		'Chicago Bears': 'Seattle Seahawks',
		'Denver Broncos': 'Cincinnati Bengals',
		'Cincinnati Bengals': 'Denver Broncos',
		'Arizona Cardinals': 'Los Angeles Rams',
		'Los Angeles Rams': 'Arizona Cardinals',
		'Los Angeles Chargers': 'New England Patriots',
		'New England Patriots': 'Los Angeles Chargers',
		'Indianapolis Colts': 'New York Giants',
		'New York Giants': 'Indianapolis Colts',
		'Atlanta Falcons': 'Washington Commanders',
		'Washington Commanders': 'Atlanta Falcons',
		'New York Jets': 'Buffalo Bills',
		'Buffalo Bills': 'New York Jets',
		'Green Bay Packers': 'Minnesota Vikings',
		'Minnesota Vikings': 'Green Bay Packers',
		'Las Vegas Raiders': 'New Orleans Saints',
		'New Orleans Saints': 'Las Vegas Raiders',
		'Carolina Panthers': 'Tampa Bay Buccaneers',
		'Tampa Bay Buccaneers': 'Carolina Panthers',
		'Tennessee Titans': 'Jacksonville Jaguars',
		'Jacksonville Jaguars': 'Tennessee Titans',
		'Dallas Cowboys': 'Philadelphia Eagles',
		'Philadelphia Eagles': 'Dallas Cowboys',
		'Miami Dolphins': 'Cleveland Browns',
		'Cleveland Browns': 'Miami Dolphins',
		'Detroit Lions': 'San Francisco 49ers',
		'San Francisco 49ers': 'Detroit Lions'
	};
};
export default getWeek17Opponents;
