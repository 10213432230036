import React, { useState, useEffect } from 'react';

function Carousel(props) {
	const images = props.images;
	const mobileImages = props.mobileImages;

	const [
		activeIndex,
		setActiveIndex
	] = useState(0);

	const handlePrevClick = () => {
		setActiveIndex(activeIndex === 0 ? images.length - 1 : activeIndex - 1);
	};

	const handleNextClick = () => {
		setActiveIndex(activeIndex === images.length - 1 ? 0 : activeIndex + 1);
	};

	useEffect(
		() => {
			const intervalId = setInterval(() => {
				setActiveIndex(activeIndex === images.length - 1 ? 0 : activeIndex + 1);
			}, props.slideSpeed ? props.slideSpeed : 10000);

			return () => clearInterval(intervalId);
		},
		[
			activeIndex
		]
	);

	return (
		<div>
			<div className="hidden md:contents">
				{images.map((image, index) => (
					<div className="text-center" key={index}>
						{index === activeIndex ? (
							<img
								className="object-scale-down h-550 block rounded-lg mx-auto"
								src={image}
							/>
						) : (
							''
						)}
					</div>
				))}
			</div>
			<div className="contents md:hidden">
				{mobileImages.map((image, index) => (
					<div className="text-center" key={index}>
						{index === activeIndex ? (
							<img
								className="object-scale-down h-550 block rounded-lg mx-auto"
								src={image}
							/>
						) : (
							''
						)}
					</div>
				))}
			</div>
			<button className="block" onClick={handlePrevClick}>
				{''}
			</button>
			<button className="block" onClick={handleNextClick}>
				{''}
			</button>
		</div>
	);
}

export default Carousel;
