import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import GradesValueToColorHelper from '../Helpers/GradesValueToColorHelper';
import PlayersRankTableModal from '../Players/PlayersRankTableModal/PlayersRankTableModal';

const CircularBarChart = (props) => {
	const [
		isInfoIconHovered,
		setIsInfoIconHovered
	] = useState(false);

	const value = `${props.value}`;
	const rank = props.rank;
	const abbr_position = props.abbr_position;

	// Define offset values based on hasLabel prop
	let labelOffsetY;
	let valueOffsetY;
	let titleOffsetY;

	if (props.hasLabel) {
		if (props.chartSize === 'xl') {
			labelOffsetY = 20;
			valueOffsetY = -22;
			titleOffsetY = -12;
		} else if (props.chartSize === 'lg') {
			labelOffsetY = 18;
			valueOffsetY = -20;
			titleOffsetY = -10;
		} else if (props.chartSize === 'md') {
			labelOffsetY = 16;
			valueOffsetY = -19;
			titleOffsetY = -8;
		} else if (props.chartSize === 'sm') {
			labelOffsetY = 14;
			valueOffsetY = -18;
			titleOffsetY = -6;
		} else {
			labelOffsetY = 12;
			valueOffsetY = -17;
			titleOffsetY = -4;
		}
	} else {
		// Note: labelOffsetY must be 0 when hasLabel is false to prevent the label from cutting off part of the chart
		if (props.chartSize === 'xl') {
			labelOffsetY = 0;
			valueOffsetY = -8;
			titleOffsetY = -12;
		} else if (props.chartSize === 'lg') {
			labelOffsetY = 0;
			valueOffsetY = -7;
			titleOffsetY = -10;
		} else if (props.chartSize === 'md') {
			labelOffsetY = 0;
			valueOffsetY = -6;
			titleOffsetY = -8;
		} else if (props.chartSize === 'sm') {
			labelOffsetY = 0;
			valueOffsetY = -5;
			titleOffsetY = -6;
		} else {
			labelOffsetY = 0;
			valueOffsetY = -4;
			titleOffsetY = -3;
		}
	}

	const chartOptions = {
		chart: {
			type: 'radialBar'
		},
		plotOptions: {
			radialBar: {
				hollow: {
					size: '69%'
				},
				dataLabels: {
					name: {
						show: true,
						fontSize:
							props.chartSize === 'xl'
								? '20px'
								: props.chartSize === 'lg'
									? '16px'
									: props.chartSize === 'md'
										? '14px'
										: props.chartSize === 'sm' ? '12px' : '10px',
						fontWeight: 'italic',
						offsetY: labelOffsetY,
						formatter: () => {
							return rank && abbr_position ? `#${rank} ${abbr_position}` : '';
						},
						style: {
							colors: [
								'#373D3F'
							]
						}
					},
					value: {
						show: true,
						fontSize:
							props.chartSize === 'xl'
								? '40px'
								: props.chartSize === 'lg'
									? '32px'
									: props.chartSize === 'md'
										? '28px'
										: props.chartSize === 'sm' ? '24px' : '20px',
						fontWeight: 'bold',
						offsetY: valueOffsetY,
						formatter: () => {
							return value;
						}
					}
				},
				track: {
					background: '#eeeeee'
				}
			}
		},
		colors: [
			'#000'
		],
		fill: {
			type: 'solid',
			colors: [
				GradesValueToColorHelper(props.value)
			]
		},
		stroke: {
			lineCap: 'round'
		},
		legend: {
			show: false
		},
		tooltip: {
			enabled: true,
			theme: 'dark',
			y: {
				title: {
					formatter: () => ''
				},
				formatter: (value) => {
					const originalValue = (value * props.maxPossible / 100).toFixed(2);
					return `${originalValue} out of ${props.maxPossible}`;
				}
			},
			x: {
				show: false
			},
			style: {
				fontSize: '12px',
				colors: [
					'#fff'
				]
			}
		}
	};

	const chartSeries = [
		(props.value / props.maxPossible * 100).toFixed(2)
	];

	const titleFontSize =
		props.chartSize === 'xl'
			? '24px'
			: props.chartSize === 'lg'
				? '20px'
				: props.chartSize === 'md'
					? '18px'
					: props.chartSize === 'sm' ? '14px' : '12px';

	const sizeStylesForPlayerRankModalHotspot = {
		xl: {
			top: 'calc(100% + 80px)',
			right: props.renderOnComparePlayerModal ? 'calc(100% - 210px)' : '',
			left: props.renderOnComparePlayerModal ? '' : '50%',
			width: '85px',
			height: '90px',
			zIndex: 5
		},
		lg: {
			top: 'calc(100% + 70px)',
			right: props.renderOnComparePlayerModal ? 'calc(100% - 195px)' : '',
			left: props.renderOnComparePlayerModal ? '' : '50%',
			width: '75px',
			height: '80px',
			zIndex: 5
		},
		md: {
			top: 'calc(100% + 60px)',
			right: props.renderOnComparePlayerModal ? 'calc(100% - 180px)' : '',
			left: props.renderOnComparePlayerModal ? '' : '50%',
			width: '65px',
			height: '70px',
			zIndex: 5
		},
		sm: {
			top: 'calc(100% + 50px)',
			right: props.renderOnComparePlayerModal ? 'calc(100% - 165px)' : '',
			left: props.renderOnComparePlayerModal ? '' : '50%',
			width: '55px',
			height: '60px',
			zIndex: 5
		},
		xs: {
			top: 'calc(100% + 40px)',
			right: props.renderOnComparePlayerModal ? 'calc(100% - 150px)' : '',
			left: props.renderOnComparePlayerModal ? '' : '50%',
			width: '45px',
			height: '50px',
			zIndex: 5
		}
	}[props.chartSize || 'sm'];

	const stylesForViewListText = {
		xl: {
			top: `calc(100% + ${props.hasLabel ? 151 : 141}px)`,
			right: props.renderOnComparePlayerModal ? 'calc(100% - 167px)' : '',
			fontSize: '16px',
			zIndex: 6
		},
		lg: {
			top: `calc(100% + ${props.hasLabel ? 131 : 122}px)`,
			right: props.renderOnComparePlayerModal ? 'calc(100% - 152px)' : '',
			fontSize: '14px',
			zIndex: 6
		},
		md: {
			top: `calc(100% + ${props.hasLabel ? 113 : 107}px)`,
			right: props.renderOnComparePlayerModal ? 'calc(100% - 137px)' : '',
			fontSize: '12px',
			zIndex: 6
		},
		sm: {
			top: `calc(100% + ${props.hasLabel ? 98 : 94}px)`,
			right: props.renderOnComparePlayerModal ? 'calc(100% - 122px)' : '',
			fontSize: '11px',
			zIndex: 6
		},
		xs: {
			top: `calc(100% + ${props.hasLabel ? 85 : 82}px)`,
			right: props.renderOnComparePlayerModal ? 'calc(100% - 107px)' : '',
			fontSize: '9px',
			zIndex: 6
		}
	}[props.chartSize || 'sm'];

	const viewListText = (
		<div className="flex justify-center">
			<span
				className="text-blue-500 hover:underline cursor-pointer"
				onClick={props.handleRankClick}
			>
				View
			</span>
		</div>
	);

	return (
		<div className="CircularBarChart relative">
			<div className="flex items-center justify-center relative">
				<div
					className="text-center text-gray-800 flex items-center justify-center"
					style={{ fontSize: titleFontSize, marginBottom: titleOffsetY || 0 }}
				>
					{props.hasTitle === false ? (
						''
					) : props.hasTitle || props.title ? (
						props.title
					) : (
						''
					)}
					{props.hasTitle === false ? (
						''
					) : (
						(props.hasTitle || props.title) &&
						props.infoIconText && (
							<div className="relative flex items-center">
								<i
									className={`fas fa-circle-info ml-2 text-gray-600 ${isInfoIconHovered
										? 'text-gray-700'
										: ''}`}
									onMouseEnter={() => setIsInfoIconHovered(true)}
									onMouseLeave={() => setIsInfoIconHovered(false)}
								/>
								{isInfoIconHovered && (
									<div
										className="p-2 bg-white border border-gray-300 shadow-lg rounded-lg text-sm w-36 z-10"
										style={{
											position: 'absolute',
											top: '15px',
											left: '-8px',
											transform: 'translate(-50%, 10px)',
											whiteSpace: 'wrap'
										}}
									>
										{props.infoIconText || 'Information'}
									</div>
								)}
							</div>
						)
					)}
					{props.hasRankModalEnabled && (
						<div className="absolute" style={stylesForViewListText}>
							{viewListText}
						</div>
					)}
					{props.hasRankModalEnabled && (
						<div
							className="absolute"
							style={{
								...sizeStylesForPlayerRankModalHotspot,
								transform: 'translateX(-50%)',
								zIndex: 5,
								cursor: 'pointer'
							}}
							onClick={props.handleRankClick}
						/>
					)}
				</div>
				{/* PlayersRankTableModal */}
				<PlayersRankTableModal
					className="z-101"
					loading={props.loading}
					isVisible={props.isRankVisible}
					rankModalRef={props.rankModalRef}
					gradeRanks={props.gradeRanks}
					handlePlayerClick={props.handlePlayerClick}
					currentPlayerId={props.currentPlayerId}
					currentPlayerRef={props.currentPlayerRef}
					value={props.value}
					displayPosition={props.displayPosition}
					renderOnComparePlayerModal={props.renderOnComparePlayerModal}
				/>
			</div>
			<Chart
				options={chartOptions}
				series={chartSeries}
				type="radialBar"
				width={
					props.chartSize === 'xl' ? (
						325
					) : props.chartSize === 'lg' ? (
						285
					) : props.chartSize === 'md' ? (
						250
					) : props.chartSize === 'sm' ? (
						220
					) : (
						195
					)
				}
			/>
		</div>
	);
};

export default CircularBarChart;
