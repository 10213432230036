import React, { useState, useContext } from 'react';
import PageTitle from '../FormElements/PageTitle';
import GlobalErrorsBox from '../ErrorComponents/GlobalErrorBox/GlobalErrorsBox';
import FormSection from '../FormElements/FormSection';
import FormSubmit from '../FormElements/FormSubmit';
import Label from '../FormElements/Label';
import TextInput from '../FormElements/TextInput';
import PhoneNumberInput from '../FormElements/PhoneNumberInput';
import EmailInput from '../FormElements/EmailInput';

const UserEditForm = ({ user, update_user_endpoint, closeModal }) => {
	const [
		formData,
		setFormData
	] = useState({
		first_name: user.first_name,
		last_name: user.last_name,
		phone_number: user.phone_number,
		email: user.email
	});
	const [
		errorsList,
		setErrorsList
	] = useState([]);
	const [
		fieldErrors,
		setFieldErrors
	] = useState({});
	const [
		isSubmitted,
		setIsSubmitted
	] = useState(false); // New state for form submission status

	const onChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		try {
			const endpoint = update_user_endpoint.replace('id', user.id);
			const response = await fetch(endpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					user: formData
				})
			});

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(JSON.stringify(errorData));
			}

			const data = await response.json();
			if (data.success) {
				// Update the user context
				setFormData({
					first_name: data.data.first_name,
					last_name: data.data.last_name,
					phone_number: data.data.phone_number
				});
				setErrorsList([]);
				setFieldErrors({});
				setIsSubmitted(true); // Set the form as submitted
			} else {
				setErrorsList(data.message);
			}
		} catch (error) {
			const errorData = JSON.parse(error.message);
			setFieldErrors(errorData.field_errors);
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	};

	return (
		<div
			className="fixed inset-0 z-50 overflow-auto flex w-full"
			style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
		>
			<div
				className="relative p-8 bg-white m-auto flex-col flex rounded-lg"
				style={{ width: '40%' }}
			>
				<button className="absolute top-0 right-0 p-4" onClick={closeModal}>
					<svg
						className="h-6 w-6 text-gray-500"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>
				<div className="py-4">
					<div className="justify-self-center w-full">
						<PageTitle title={'Edit your user profile'} />
						<GlobalErrorsBox globalErrors={errorsList} />
						{isSubmitted ? (
							<div className="text-start text-green-700">
								<i className="fa-solid fa-circle-check mr-2" />
								<span>Your profile has been successfully updated!</span>
							</div>
						) : (
							<FormSection>
								<Label htmlFor="first_name">First Name *</Label>
								<TextInput
									type="text"
									id="first_name"
									name="first_name"
									value={formData.first_name}
									onChange={onChange}
									fieldErrors={fieldErrors}
								/>
								<Label htmlFor="last_name">Last Name *</Label>
								<TextInput
									type="text"
									id="last_name"
									name="last_name"
									value={formData.last_name}
									onChange={onChange}
									fieldErrors={fieldErrors}
								/>
								<Label htmlFor="phone_number">Phone *</Label>
								<PhoneNumberInput
									id="phone_number"
									name="phone_number"
									value={formData.phone_number}
									onChange={onChange}
									fieldErrors={fieldErrors}
								/>
								{!isSubmitted && (
									<div className="grid">
										<div className="mt-4">
											<FormSubmit
												onSubmit={onSubmit}
												label={'Update profile'}
											/>
										</div>
									</div>
								)}
							</FormSection>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserEditForm;
