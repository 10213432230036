import React from 'react';
import CircularBarChart from '../../Charts/CircularBarChart';
import getPositionAbbreviation from '../../../utils/getPositionAbbreviation';

const FantasyFitnessScoreCard = ({
	player,
	gradeRanks,
	handleRankClick,
	visibleRankModal,
	handlePlayerClick,
	rankModalRef,
	currentPlayerRef,
	loading
}) => {
	return (
		<div className="flex flex-wrap border border-gray-300 rounded-xl shadow-lg bg-white">
			<div className="pt-6 px-5 md:px-20 flex flex-wrap items-center justify-center lg:justify-between w-full md:flex-row space-y-6 lg:space-y-0">
				<div>
					<CircularBarChart
						loading={loading}
						title="Overall Score™"
						chartSize="xl"
						value={
							player[0] && player[0].fantasy_fitness_grade ? (
								player[0].fantasy_fitness_grade
							) : (
								0
							)
						}
						maxPossible={10}
						infoIconText="This is the player's Fantasy Fitness Score™. It combines the player's Fantasy Fitness	Talent Score™ and Fantasy Fitness Team Score™ to estimate their	overall value."
						rank={
							player[0] && player[0].fantasy_fitness_grade_position_rank ? (
								player[0].fantasy_fitness_grade_position_rank
							) : (
								999
							)
						}
						abbr_position={getPositionAbbreviation(
							player[0] && player[0].position ? player[0].position : ''
						)}
						gradeRanks={
							gradeRanks && gradeRanks.fantasy_fitness_grades ? (
								gradeRanks.fantasy_fitness_grades
							) : (
								[]
							)
						}
						handleRankClick={() =>
							handleRankClick(`fantasy_fitness_score_${player[0].id}`)}
						isRankVisible={
							visibleRankModal === `fantasy_fitness_score_${player[0].id}`
						}
						rankModalRef={rankModalRef}
						currentPlayerRef={currentPlayerRef}
						handlePlayerClick={handlePlayerClick}
						currentPlayerId={player[0] && player[0].id ? player[0].id : 0}
						hasLabel={true}
						hasRankModalEnabled={true}
					/>
					{/*	<p
						className="w-80 px-5 xl:px-0 pb-6 flex flex-wrap justify-center"
						style={{ marginTop: '-10px', 
						fontSize: '10px',
						 }}
					>
						The Fantasy Fitness Score™ combines a player's Fantasy Fitness
						Talent Score™ and Fantasy Fitness Team Score™ to estimate their
						overall value.
					</p>
				*/}
				</div>

				<div
					className="hidden xl:inline"
					style={{
						borderLeft: '1.5px solid #D1D5DB',
						marginTop: '-1.75rem',
						height: '90%'
					}}
				/>
				<div
					className="lg:hidden inline"
					style={{
						borderBottom: '1.5px solid #D1D5DB',
						width: '100%',
						marginTop: '-1.5rem',
						padding: '20px 0 0 0'
					}}
				/>
				<CircularBarChart
					loading={loading}
					title="Talent Score™"
					chartSize="lg"
					value={
						player[0] && player[0].talent_grade ? player[0].talent_grade : 0
					}
					maxPossible={10}
					infoIconText="This is the player's Fantasy Fitness Talent Score™. It is calculated based on a combination of the player's production and athleticism."
					rank={
						player[0] && player[0].talent_grade_position_rank ? (
							player[0].talent_grade_position_rank
						) : (
							999
						)
					}
					abbr_position={getPositionAbbreviation(
						player[0] && player[0].position ? player[0].position : ''
					)}
					gradeRanks={
						gradeRanks && gradeRanks.talent_grades ? (
							gradeRanks.talent_grades
						) : (
							[]
						)
					}
					handleRankClick={() =>
						handleRankClick(`fantasy_fitness_talent_score_${player[0].id}`)}
					isRankVisible={
						visibleRankModal === `fantasy_fitness_talent_score_${player[0].id}`
					}
					rankModalRef={rankModalRef}
					currentPlayerRef={currentPlayerRef}
					handlePlayerClick={handlePlayerClick}
					currentPlayerId={player[0] && player[0].id ? player[0].id : 0}
					hasLabel={true}
					hasRankModalEnabled={true}
				/>
				<CircularBarChart
					loading={loading}
					title="Team Score™"
					chartSize="lg"
					value={player[0] && player[0].team_grade ? player[0].team_grade : 0}
					maxPossible={10}
					infoIconText="This is the player's Fantasy Fitness Team Score™. It is calculated based on a combination of the player's team environment factors."
					rank={
						player[0] && player[0].team_grade_position_rank ? (
							player[0].team_grade_position_rank
						) : (
							999
						)
					}
					abbr_position={getPositionAbbreviation(
						player[0] && player[0].position ? player[0].position : ''
					)}
					gradeRanks={
						gradeRanks && gradeRanks.team_grades ? gradeRanks.team_grades : []
					}
					handleRankClick={() =>
						handleRankClick(`fantasy_fitness_team_score_${player[0].id}`)}
					isRankVisible={
						visibleRankModal === `fantasy_fitness_team_score_${player[0].id}`
					}
					rankModalRef={rankModalRef}
					currentPlayerRef={currentPlayerRef}
					handlePlayerClick={handlePlayerClick}
					currentPlayerId={player[0] && player[0].id ? player[0].id : 0}
					hasLabel={true}
					hasRankModalEnabled={true}
				/>
			</div>
		</div>
	);
};

export default FantasyFitnessScoreCard;
