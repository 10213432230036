import React from 'react';

import MobileDataTableCard from '../../../../../DataTables/MobileDataTableCard';
import AdvancedDataTable from '../../../../../DataTables/AdvancedDataTable';
import getFormattedNumberWithComma from '../../../../../../utils/getFormattedNumberWithComma';

export default function TightEndCollegeBasicReceivingStatsTable(props) {
	return (
		<div>
			<div className="text-xl py-2">
				<h4>{props.title}</h4>
			</div>
			<div className="contents lg:hidden">
				<MobileDataTableCard
					data={props.data}
					style="text-gray-800 border-b-2 border-gray-300 font-semibold"
					rowRenderer={(row) => {
						const labels = [
							{
								displayName: 'Category',
								apiName: 'category',
								alignment: 'left'
							},
							{
								displayName: 'Games Played',
								apiName: 'college_games_played',
								alignment: 'left'
							},
							{
								displayName: 'Receptions',
								apiName: 'college_receptions',
								alignment: 'left'
							},
							{
								displayName: 'Receiving Yards',
								apiName: 'college_receiving_yards',
								alignment: 'left'
							},
							{
								displayName: 'Yards Per Reception',
								apiName: 'college_receiving_yards_per_reception',
								alignment: 'left'
							},
							{
								displayName: 'Receiving TDs',
								apiName: 'college_receiving_touchdowns',
								alignment: 'left'
							}
						];
						return (
							<div
								key={`${row.first_name} ${row.last_name} - PlayersProfileStatsTable - MobileDataTableCard`}
								className="p-5 bg-white border-t text-gray-800"
							>
								<div className="flex flex-wrap">
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[1].displayName}
									</div>
									<div className="w-1/2 mb-2">{row.college_games_played}</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[2].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{getFormattedNumberWithComma(row.college_receptions)}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[3].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{getFormattedNumberWithComma(row.college_receiving_yards)}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[4].displayName}
									</div>
									<div className="w-1/2 mb-2">
										{row.college_receiving_yards_per_reception}
									</div>
									<div
										className="w-1/2 font-semibold"
										style={{ minWidth: '150px' }}
									>
										{labels[5].displayName}
									</div>
									<div className="w-1/2">
										{row.college_receiving_touchdowns}
									</div>
								</div>
							</div>
						);
					}}
				/>
			</div>
			<div className="hidden lg:contents">
				<AdvancedDataTable
					data={props.data}
					style="text-gray-800 border-b-2 border-gray-300 font-semibold"
					columns={[
						{
							displayName: 'Category',
							apiName: 'category',
							alignment: 'left'
						},
						{
							displayName: 'Games Played',
							apiName: 'college_games_played',
							alignment: 'left'
						},
						{
							displayName: 'Receptions',
							apiName: 'college_receptions',
							alignment: 'left'
						},
						{
							displayName: 'Receiving Yards',
							apiName: 'college_receiving_yards',
							alignment: 'left'
						},
						{
							displayName: 'Yards Per Reception',
							apiName: 'college_yards_per_reception',
							alignment: 'left'
						},
						{
							displayName: 'Receiving TDs',
							apiName: 'college_receiving_touchdowns',
							alignment: 'left'
						}
					]}
					rowRenderer={(row) => {
						return (
							<tr key={row.id} className="bg-white border-t text-gray-800">
								<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10">
									Career
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_games_played}
								</td>
								<td className="pl-8 py-3 text-left">
									{getFormattedNumberWithComma(row.college_receptions)}
								</td>
								<td className="pl-8 py-3 text-left">
									{getFormattedNumberWithComma(row.college_receiving_yards)}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_receiving_yards_per_reception}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_receiving_touchdowns}
								</td>
							</tr>
						);
					}}
				/>
			</div>
		</div>
	);
}
