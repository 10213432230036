import React from 'react';
import getLaymanDraftPosition from '../../../utils/getLaymanDraftPosition';

const PlayerAdpBreakdownModal = ({
	player,
	closePlayerAdpBreakdownModal,
	modalRef
}) => {
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);

	const formattedDate = yesterday.toLocaleDateString('en-US', {
		month: 'numeric',
		day: 'numeric',
		year: 'numeric'
	});

	return (
		<div
			className="fixed inset-0 flex justify-center items-center"
			style={{
				zIndex: 1000,
				backgroundColor: 'rgba(0, 0, 0, 0.5)'
			}}
		>
			<div
				className="relative bg-white rounded-lg p-8 shadow-2xl max-w-lg w-full"
				style={{ maxHeight: '80vh', overflowY: 'auto' }}
				ref={modalRef}
			>
				<button
					className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-200 transition duration-300"
					onClick={closePlayerAdpBreakdownModal}
				>
					<svg
						className="h-6 w-6 text-gray-600"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>
				<h2 className="text-2xl font-semibold mb-6">
					{player && player.first_name ? (
						`${player.first_name}'s ADP Breakdown`
					) : (
						'ADP Breakdown'
					)}
				</h2>
				<div className="overflow-hidden rounded-lg border border-gray-200">
					<table className="min-w-full bg-white">
						<thead className="bg-gray-100">
							<tr>
								<th className="py-2 px-4 text-left text-gray-600 font-medium">
									Underdog
								</th>
								<th className="py-2 px-4 text-left text-gray-600 font-medium">
									FFPC
								</th>
								<th className="py-2 px-4 text-left text-gray-600 font-medium">
									Yahoo!
								</th>
								<th className="py-2 px-4 text-left text-gray-600 font-medium">
									ESPN
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className="border-b border-gray-200">
								<td className="py-2 px-4">{player.underdog_adp || '-'}</td>
								<td className="py-2 px-4">{player.ffpc_adp || '-'}</td>
								<td className="py-2 px-4">{player.yahoo_adp || '-'}</td>
								<td className="py-2 px-4">{player.espn_adp || '-'}</td>
							</tr>
							<tr>
								<td className="py-2 px-4">
									{getLaymanDraftPosition(player.underdog_adp)}
								</td>
								<td className="py-2 px-4">
									{getLaymanDraftPosition(player.ffpc_adp)}
								</td>
								<td className="py-2 px-4">
									{getLaymanDraftPosition(player.yahoo_adp)}
								</td>
								<td className="py-2 px-4">
									{getLaymanDraftPosition(player.espn_adp)}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p className="text-xs text-gray-500 text-right pt-2 pr-2">
					Last updated: {formattedDate}
				</p>
			</div>
		</div>
	);
};

export default PlayerAdpBreakdownModal;
