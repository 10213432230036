import React from 'react';

export default function PageContent(props) {
	return (
		<React.Fragment>
			<div className="mx-auto">
				{props.children}
			</div>
		</React.Fragment>
	);
}
