// LoadingSpinner.js
import React from 'react';
import spinner from '../../../assets/images/spinner.gif';

const LoadingSpinner = () => {
	return (
		<div className="flex justify-center items-center h-full">
			<img src={spinner} alt="Loading..." />
		</div>
	);
};

export default LoadingSpinner;
