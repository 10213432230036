const getLaymanDraftPosition = (adp) => {
	if (!adp || adp === null) return '-';

	if (adp >= 1 && adp <= 4.4) return 'Early 1st';
	if (adp >= 4.5 && adp <= 8.4) return 'Mid 1st';
	if (adp >= 8.5 && adp <= 12.4) return 'Late 1st';

	if (adp >= 12.5 && adp <= 16.4) return 'Early 2nd';
	if (adp >= 16.5 && adp <= 20.4) return 'Mid 2nd';
	if (adp >= 20.5 && adp <= 24.4) return 'Late 2nd';

	if (adp >= 24.5 && adp <= 28.4) return 'Early 3rd';
	if (adp >= 28.5 && adp <= 32.4) return 'Mid 3rd';
	if (adp >= 32.5 && adp <= 36.4) return 'Late 3rd';

	if (adp >= 36.5 && adp <= 40.4) return 'Early 4th';
	if (adp >= 40.5 && adp <= 44.4) return 'Mid 4th';
	if (adp >= 44.5 && adp <= 48.4) return 'Late 4th';

	if (adp >= 48.5 && adp <= 52.4) return 'Early 5th';
	if (adp >= 52.5 && adp <= 56.4) return 'Mid 5th';
	if (adp >= 56.5 && adp <= 60.4) return 'Late 5th';

	if (adp >= 60.5 && adp <= 64.4) return 'Early 6th';
	if (adp >= 64.5 && adp <= 68.4) return 'Mid 6th';
	if (adp >= 68.5 && adp <= 72.4) return 'Late 6th';

	if (adp >= 72.5 && adp <= 76.4) return 'Early 7th';
	if (adp >= 76.5 && adp <= 80.4) return 'Mid 7th';
	if (adp >= 80.5 && adp <= 84.4) return 'Late 7th';

	if (adp >= 84.5 && adp <= 88.4) return 'Early 8th';
	if (adp >= 88.5 && adp <= 92.4) return 'Mid 8th';
	if (adp >= 92.5 && adp <= 96.4) return 'Late 8th';

	if (adp >= 96.5 && adp <= 100.4) return 'Early 9th';
	if (adp >= 100.5 && adp <= 104.4) return 'Mid 9th';
	if (adp >= 104.5 && adp <= 108.4) return 'Late 9th';

	if (adp >= 108.5 && adp <= 112.4) return 'Early 10th';
	if (adp >= 112.5 && adp <= 116.4) return 'Mid 10th';
	if (adp >= 116.5 && adp <= 120.4) return 'Late 10th';

	if (adp >= 120.5 && adp <= 124.4) return 'Early 11th';
	if (adp >= 124.5 && adp <= 128.4) return 'Mid 11th';
	if (adp >= 128.5 && adp <= 132.4) return 'Late 11th';

	if (adp >= 132.5 && adp <= 136.4) return 'Early 12th';
	if (adp >= 136.5 && adp <= 140.4) return 'Mid 12th';
	if (adp >= 140.5 && adp <= 144.4) return 'Late 12th';

	if (adp >= 144.5 && adp <= 148.4) return 'Early 13th';
	if (adp >= 148.5 && adp <= 152.4) return 'Mid 13th';
	if (adp >= 152.5 && adp <= 156.4) return 'Late 13th';

	if (adp >= 156.5 && adp <= 160.4) return 'Early 14th';
	if (adp >= 160.5 && adp <= 164.4) return 'Mid 14th';
	if (adp >= 164.5 && adp <= 168.4) return 'Late 14th';

	if (adp >= 168.5 && adp <= 172.4) return 'Early 15th';
	if (adp >= 172.5 && adp <= 176.4) return 'Mid 15th';
	if (adp >= 176.5 && adp <= 180.4) return 'Late 15th';

	if (adp >= 180.5 && adp <= 184.4) return 'Early 16th';
	if (adp >= 184.5 && adp <= 188.4) return 'Mid 16th';
	if (adp >= 188.5 && adp <= 192.4) return 'Late 16th';

	if (adp >= 192.5 && adp <= 196.4) return 'Early 17th';
	if (adp >= 196.5 && adp <= 200.4) return 'Mid 17th';
	if (adp >= 200.5 && adp <= 204.4) return 'Late 17th';

	if (adp >= 204.5 && adp <= 208.4) return 'Early 18th';
	if (adp >= 208.5 && adp <= 212.4) return 'Mid 18th';
	if (adp >= 212.5 && adp <= 216.4) return 'Late 18th';

	if (adp >= 216.5 && adp <= 220.4) return 'Early 19th';
	if (adp >= 220.5 && adp <= 224.4) return 'Mid 19th';
	if (adp >= 224.5 && adp <= 228.4) return 'Late 19th';

	if (adp >= 228.5 && adp <= 232.4) return 'Early 20th';
	if (adp >= 232.5 && adp <= 236.4) return 'Mid 20th';
	if (adp >= 236.5 && adp <= 240.4) return 'Late 20th';

	return '-';
}

export default getLaymanDraftPosition;