import React from 'react';

const CollegeStatsButtons = ({
	selectedCollegeStatsType,
	handleCollegeBasicStatsClick,
	handleCollegeAdvancedStatsClick
}) => {
	return (
		<div className="mb-4 flex flex-row">
			{/* College Basic Stats Button*/}
			<div className="items-center mr-4" onClick={handleCollegeBasicStatsClick}>
				<button
					className={`m-2 p-2 border border-gray-300 rounded-md mr-2 ${selectedCollegeStatsType ===
					'basic'
						? 'bg-gray-800 text-white'
						: 'bg-white text-black'}`}
				>
					College Basic Stats
				</button>
			</div>
			{/* College Advanced Stats Button */}
			<div className="tems-center mr-4">
				<button
					className={`m-2 p-2 border border-gray-300 rounded-md mr-2 ${selectedCollegeStatsType ===
					'advanced'
						? 'bg-gray-800 text-white'
						: 'bg-white text-black'}`}
					onClick={handleCollegeAdvancedStatsClick}
				>
					College Advanced Stats
				</button>
			</div>
			<span className="m-2 text-sm font-extralight">
				* Note: Stats buttons appear only when filtering by a single position
			</span>
		</div>
	);
};

export default CollegeStatsButtons;
