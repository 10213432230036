import React from 'react';
import { Link } from 'react-router-dom';
import AdvancedDataTable from '../../../../DataTables/AdvancedDataTable';
import MobileDataTableCard from '../../../../DataTables/MobileDataTableCard';

const WideReceiversCollegeAdvancedStatsTable = ({
	players,
	sortBy,
	sortOrder,
	handleSortOrderChange
}) => {
	return (
		<div>
			<div className="contents lg:hidden">
				<MobileDataTableCard
					data={players}
					style="text-gray-800 border-b-2 border-gray-300 font-semibold"
					rowRenderer={(row) => {
						const labels = [
							{
								displayName: 'Player',
								apiName: 'player',
								alignment: 'left'
							},
							{
								displayName: 'Receptions Per Game',
								apiName: 'college_receptions_per_game',
								alignment: 'left',
								helper_text: 'The average receptions per game.'
							},
							{
								displayName: 'Receiving Yards Per Game',
								apiName: 'college_receiving_yards_per_game',
								alignment: 'left'
							},
							{
								displayName: 'Receiving TDs Per Game',
								apiName: 'college_receiving_touchdowns_per_game',
								alignment: 'left',
								helper_text: 'The average receiving touchdowns per game.'
							},
							{
								displayName: 'Receptions Per TD',
								apiName: 'college_receptions_per_touchdown',
								alignment: 'left',
								helper_text: 'The average receptions per touchdown.'
							},
							{
								displayName: 'Receiving Yards Per TD',
								apiName: 'college_receiving_yards_per_touchdown',
								alignment: 'left',
								helper_text: 'The average receiving yards per touchdown.'
							}
						];
						return (
							<div
								key={`${row.first_name} ${row.last_name} - QuarterbacksCollegeAdvancedStatsTable - MobileDataTableCard`}
								className="p-5 bg-white border-t text-gray-800"
							>
								<div>
									<Link
										to={`/players/${row.id}?position=${row.position}`}
										className="text-blue-500 hover:text-blue-700"
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: '50px',
													height: '50px',
													overflow: 'hidden',
													marginRight: '8px'
												}}
											>
												<img
													src={row.image_url + '?w=50&h=50'}
													alt={`${row.first_name} ${row.last_name}`}
													style={{
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														border: '1px solid #ddd',
														borderRadius: '50%'
													}}
												/>
											</div>
											{`${row.first_name} ${row.last_name}`}
										</div>
									</Link>
								</div>
								<div className="mt-4 font-semibold">
									{labels[1].displayName}
								</div>
								<div>{row.college_receptions_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[2].displayName}
								</div>
								<div>{row.college_receiving_yards_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[3].displayName}
								</div>
								<div>{row.college_receiving_touchdowns_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[4].displayName}
								</div>
								<div>{row.college_receptions_per_touchdown}</div>
								<div className="mt-4 font-semibold">
									{labels[5].displayName}
								</div>
								<div>{row.college_receiving_yards_per_touchdown}</div>
							</div>
						);
					}}
				/>
			</div>
			<div className="hidden lg:contents">
				<AdvancedDataTable
					data={players}
					style="text-gray-800 border-b-2 border-gray-300 font-semibold"
					sortOrder={sortOrder}
					sortColumn={sortBy ? sortBy : ''}
					onChangeSortOrder={handleSortOrderChange}
					sortingEnabled={true}
					columns={[
						{
							displayName: 'Player',
							apiName: 'player',
							alignment: 'left'
						},
						{
							displayName: 'Receptions Per Game',
							apiName: 'college_receptions_per_game',
							alignment: 'left',
							helper_text: 'The average receptions per game.'
						},
						{
							displayName: 'Receiving Yards Per Game',
							apiName: 'college_receiving_yards_per_game',
							alignment: 'left'
						},
						{
							displayName: 'Receiving TDs Per Game',
							apiName: 'college_receiving_touchdowns_per_game',
							alignment: 'left',
							helper_text: 'The average receiving touchdowns per game.'
						},
						{
							displayName: 'Receptions Per TD',
							apiName: 'college_receptions_per_touchdown',
							alignment: 'left',
							helper_text: 'The average receptions per touchdown.'
						},
						{
							displayName: 'Receiving Yards Per TD',
							apiName: 'college_receiving_yards_per_touchdown',
							alignment: 'left',
							helper_text: 'The average receiving yards per touchdown.'
						}
					]}
					rowRenderer={(row) => {
						return (
							<tr
								className="bg-white"
								key={`${row.first_name} ${row.last_name} - QuarterbacksCollege - AdvancedStatsTable`}
							>
								<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10 w-60">
									<Link
										to={`/players/${row.id}?position=${row.position}`}
										className="text-blue-500 hover:text-blue-700"
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: '50px',
													height: '50px',
													overflow: 'hidden',
													marginRight: '8px'
												}}
											>
												<img
													src={row.image_url + '?w=50&h=50'}
													alt={`${row.first_name} ${row.last_name}`}
													style={{
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														border: '1px solid #ddd',
														borderRadius: '50%'
													}}
												/>
											</div>
											{`${row.first_name} ${row.last_name}`}
										</div>
									</Link>
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_receptions_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_receiving_yards_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_receiving_touchdowns_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_receptions_per_touchdown}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_receiving_yards_per_touchdown}
								</td>
							</tr>
						);
					}}
				/>
			</div>
		</div>
	);
};

export default WideReceiversCollegeAdvancedStatsTable;
