import React from 'react';
import FieldErrors from '../ErrorComponents/FieldErrors/FieldErrors';
import HelperText from './HelperText';

export default class PhoneNumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.formatPhoneNumberForDisplay(this.props.value || '')
    };

    this.onChange = this.onChange.bind(this);
    this.formatPhoneNumberForDisplay = this.formatPhoneNumberForDisplay.bind(this);
    this.removeDashes = this.removeDashes.bind(this);
  }

  formatPhoneNumberForDisplay(value) {
    // Format the phone number with dashes for display
    const cleaned = value.replace(/\D/g, '');
    const match = cleaned.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (match) {
      return [match[1], match[2], match[3]].filter(Boolean).join('-');
    }
    return value;
  }

  removeDashes(value) {
    // Remove dashes for storage
    return value.replace(/-/g, '');
  }

  onChange(event) {
    const rawValue = event.target.value;
    const formattedValue = this.formatPhoneNumberForDisplay(rawValue);
    
    this.setState({ value: formattedValue });
    this.props.onChange({
      target: {
        value: this.removeDashes(formattedValue),
        name: this.props.name
      }
    });
  }

  render() {
    const {
      id,
      name,
      style,
      placeholder,
      value,
      readOnly,
      helperText,
      fieldErrors
    } = this.props;

    return (
      <div className="TextInput mt-1 mb-4">
        <input
          type="tel"
          autoCapitalize="off"
          autoComplete="off"
          className={`${this.props.tailwindStyles} block border border-gray-300 w-full px-4 h-10 rounded-md`}
          id={id}
          name={name}
          style={style}
          placeholder={placeholder}
          value={this.state.value}
          onChange={this.onChange}
          readOnly={readOnly ? true : ''}
        />
        <div className="p-1">
          {fieldErrors && fieldErrors.hasOwnProperty(name) ? (
            <FieldErrors fieldName={name} fieldErrors={fieldErrors} />
          ) : (
            <HelperText>{helperText}</HelperText>
          )}
        </div>
      </div>
    );
  }
}
