import React from 'react';
import PricingTable from '../../PricingTable/PricingTable';

const PricingPage = ({
	create_checkout_session_endpoint,
	stripe_public_key,
	stripe_product_essential_id,
	stripe_product_advanced_id,
	stripe_product_ultimate_id
}) => (
	<div>
		<div className="mt-4 lg:mt-14">
			<PricingTable
				create_checkout_session_endpoint={create_checkout_session_endpoint}
				stripe_public_key={stripe_public_key}
				stripe_product_essential_id={stripe_product_essential_id}
				stripe_product_advanced_id={stripe_product_advanced_id}
				stripe_product_ultimate_id={stripe_product_ultimate_id}
			/>
		</div>
	</div>
);

export default PricingPage;
