// Import and start Rails UJS
// console.log('Before starting @rails/ujs');
import Rails from '@rails/ujs';
Rails.start();


// Import and start Turbolinks
// console.log('Before starting turbolinks');
const turbolinks = require('turbolinks');
turbolinks.start();

// Import and start Active Storage
// console.log('Before starting @rails/activestorage');
const activeStorage = require('@rails/activestorage');
activeStorage.start();

// Import stylesheets
import '../stylesheets/application';

// Import and start Bootstrap (make sure it comes after jQuery and Popper.js)
import 'jquery';
import 'popper.js';
import 'bootstrap';

// Import I18n
import I18n from 'i18n-js';
window.I18n = I18n;

// Support component names relative to this directory
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
