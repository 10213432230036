export default function GradesValueToColorHelper(value) {
	if (value >= 10) {
		return '#1ABE10';
	} else if (value >= 9.5) {
		return '#4BC818';
	} else if (value >= 9) {
		return '#7DD220';
	} else if (value >= 8.5) {
		return '#AFDC28';
	} else if (value >= 8) {
		return '#E1E630';
	} else if (value >= 7.5) {
		return '#EDCC2E';
	} else if (value >= 7) {
		return '#E9A227';
	} else if (value >= 6.5) {
		return '#E57820';
	} else if (value >= 6) {
		return '#E04E1A';
	} else if (value < 6) {
		return '#DC2413';
	}
}
