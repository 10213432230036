import React from 'react';

const FixedRosterBanner = ({ scrolledPastBanner, roster }) => {
	if (!scrolledPastBanner || roster.length === 0) return null;

	const { image_url, team_name, league_name, platform, scoring } = roster;

	return (
		<div className="fixed top-0 left-0 right-0 h-28 bg-white text-black flex items-center justify-center border border-gray-300 shadow-lg z-50">
			<div className="flex items-center">
				<div
					onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
					style={{ cursor: 'pointer' }}
					className="flex items-center"
				>
					{image_url && (
						<div className="relative mr-4">
							<img
								src={`${image_url}?w=75&h=75`}
								alt={`${team_name} logo`}
								style={{
									width: '75px',
									height: '75px',
									objectFit: 'cover',
									border: '1px solid #ddd',
									borderRadius: '50%',
									backgroundColor: 'white'
								}}
							/>
						</div>
					)}
					<div>
						<div className="text-2xl font-bold">{team_name}</div>
						<div>
							<div className="text-lg text-gray-500">
								{league_name} • {platform} • {scoring}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FixedRosterBanner;
