import React from 'react';
import PropTypes from 'prop-types';
import SessionsSignInForm from '../../Sessions/SessionsSignInForm';

export default function SignInPage({ sign_in_endpoint }) {
	if (!sign_in_endpoint) {
		return <div>Error: Missing sign-in endpoint</div>;
	}

	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<SessionsSignInForm endpoint={sign_in_endpoint} />
		</div>
	);
}

SignInPage.propTypes = {
	sign_in_endpoint: PropTypes.string.isRequired
};
