import React from 'react';

const StatsTypeButtons = ({
	statsType1,
	statsType2,
	selectedStatType,
	handleStatsType1ButtonClick,
	handleStatsType2ButtonClick
}) => {
	return (
		<div className="mb-4 flex flex-row">
			<div
				className="items-center mr-4"
				onClick={handleStatsType1ButtonClick}
			>
				<button
					className={`p-2 border border-gray-300 rounded-md mr-2 ${selectedStatType ===
					statsType1.toLowerCase()
						? 'bg-gray-800 text-white'
						: 'bg-white text-black'}`}
				>
					{statsType1}
				</button>
			</div>
			<div className="items-center mr-4">
				<button
					className={`p-2 border border-gray-300 rounded-md mr-2 ${selectedStatType ===
					statsType2.toLowerCase()
						? 'bg-gray-800 text-white'
						: 'bg-white text-black'}`}
					onClick={handleStatsType2ButtonClick}
				>
					{statsType2}
				</button>
			</div>
		</div>
	);
};

export default StatsTypeButtons;
