import React from 'react';

export default class StatsRow extends React.Component {
	constructor(props) {
		super(props);

		const data = this.props.data || [];

		this.state = {
			data: data,
			loading: data && data.length ? false : true
		};

		this.loadData = this.loadData.bind(this);
	}

	componentDidMount() {
		if (!this.state.data || this.state.data.length === 0) {
			this.loadData();
		}
	}

	render() {
		let data = null;

		if (this.state.data && this.state.data.length) {
			data = this.state.data.map((d) => (
				<div className="stat-section" key={d.name}>
					<span className="stat-name">{d.name}</span>
					<span className="stat-value">{d.value}</span>
				</div>
			));
		}

		return (
			<div className="StatsRow">
				{data}
				{this.state.loading ? (
					<div className="loadingOverlay">
						<div className="spinner" />
					</div>
				) : null}
			</div>
		);
	}

	loadData() {
		this.setState({ loading: true });
		$.ajax({
			type: 'POST',
			url: this.props.endpoint
		}).done((data) => {
			// console.log('StatsRow loadData done', data);
			this.setState({ data: data, loading: false });
		});
	}
}
