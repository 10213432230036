// Mapping function to convert team name to abbreviation
const getPositionAbbreviation = (position) => {
	switch (position.toLowerCase()) {
		case 'quarterback':
			return 'QB';
		case 'running back':
			return 'RB';
		case 'wide receiver':
			return 'WR';
		case 'tight end':
			return 'TE';
		default:
			return position; // Return original position if not matched
	}
};

export default getPositionAbbreviation;
