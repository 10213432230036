import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import UserEditForm from '../User/UserEditForm';

export default function UserDetailsCard({
	userId,
	get_user_endpoint,
	update_user_endpoint,
	get_subscriptions_by_user_endpoint,
	create_checkout_session_endpoint,
	stripe_public_key
}) {
	const [
		user,
		setUser
	] = useState({});
	const [
		subscriptions,
		setSubscriptions
	] = useState([]);
	const [
		isEditing,
		setIsEditing
	] = useState(false);

	useEffect(
		() => {
			if (get_user_endpoint && userId) {
				const endpoint = get_user_endpoint.replace('id', userId);
				fetch(endpoint).then((response) => response.json()).then((data) => {
					setUser(data.data || {});
				});
			}
		},
		[
			userId,
			get_user_endpoint
		]
	);

	useEffect(
		() => {
			if (get_subscriptions_by_user_endpoint && userId) {
				const endpoint = get_subscriptions_by_user_endpoint.replace(
					'user_id',
					userId
				);
				fetch(endpoint).then((response) => response.json()).then((data) => {
					setSubscriptions(data.data || []);
				});
			}
		},
		[
			userId,
			get_subscriptions_by_user_endpoint
		]
	);

	const handleManageSubscription = () => {
		// Redirect to Stripe billing portal
		window.location.href =
			'https://billing.stripe.com/p/login/cN2295e9ha1edyM7ss'; // LIVE URL - Replace with your Stripe billing portal URL env variable so it's dynamic
	};

	const formatPhoneNumber = (phoneNumber) => {
		const cleaned = ('' + phoneNumber).replace(/\D/g, '');
		const match = cleaned.match(/(\d{1,3})(\d{1,3})(\d{1,4})/);
		if (match) {
			return [
				match[1],
				match[2],
				match[3]
			]
				.filter(Boolean)
				.join('-');
		}
		return phoneNumber;
	};

	const capitalizeFirstLetter = (string) => {
		if (!string) return '';
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const getOrdinalSuffix = (day) => {
		if (day > 3 && day < 21) return 'th'; // Handles 11th, 12th, 13th, etc.
		switch (day % 10) {
			case 1:
				return 'st';
			case 2:
				return 'nd';
			case 3:
				return 'rd';
			default:
				return 'th';
		}
	};

	const formatDateWithMonthName = (dateString) => {
		const date = new Date(dateString);
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		];
		const month = monthNames[date.getMonth()];
		const day = date.getDate();
		const year = date.getFullYear();
		const ordinalSuffix = getOrdinalSuffix(day);
		return `${month} ${day}${ordinalSuffix}, ${year}`;
	};

	const mostRecentSubscription = subscriptions.reduce(
		(latest, subscription) => {
			return new Date(subscription.created_at) > new Date(latest.created_at)
				? subscription
				: latest;
		},
		subscriptions[0] || {}
	);

	const hasSubscriptionDetails =
		mostRecentSubscription && mostRecentSubscription.price;

	console.log('mostRecentSubscription', mostRecentSubscription);

	return (
		<div className="UserDetailsCard bg-white w-full min-w-96 p-5 rounded-md shadow border text-gray-800">
			<div className="grid lg:grid-cols-12 lg:gap-5">
				<div className="col-span-12 lg:col-span-2">
					<h2 className="text-lg lg:text-xl font-semibold mb-2">My Profile</h2>
				</div>
				<div className="col-span-12 lg:col-span-10">
					<Fragment>
						<div className="border p-4 bg-gray-50 shadow-sm rounded-md relative">
							<span
								className="absolute top-4 right-4 hover:underline cursor-pointer"
								onClick={() => setIsEditing(true)}
							>
								Edit
							</span>
							<div className="grid md:grid-cols-12">
								<div className="md:col-span-6">
									<div className="font-semibold">First Name</div>
									<span className="my-1 mr-3">{user.first_name}</span>
									<div className="font-semibold mt-4">Last Name</div>
									<span className="mr-2 block">{user.last_name}</span>
									<div className="font-semibold mt-4">Phone Number</div>
									<span className="mr-2 block">
										{formatPhoneNumber(user.phone_number)}
									</span>
								</div>
								<div className="md:col-span-6 mt-4 md:mt-0">
									<div className="font-semibold">Email (Username)</div>
									<span className="mr-2 block">{user.email}</span>
									<div className="font-semibold mt-4">Password</div>
									<span className="mr-2 block">******</span>
								</div>
							</div>
						</div>
						{/* Subscription Details Section */}
						<div className="border p-4 bg-gray-50 shadow-sm rounded-md mt-4 relative">
							<div className="absolute top-4 right-4">
								{hasSubscriptionDetails ? (
									<button
										onClick={handleManageSubscription}
										className="px-4 py-2 bg-blue-500 text-white rounded"
									>
										Manage subscription
									</button>
								) : (
									<a
										href="/pricing"
										className="px-4 py-2 bg-blue-500 text-white rounded"
									>
										Add subscription
									</a>
								)}
							</div>
							<div className="grid md:grid-cols-2">
								<div className="md:col-span-1">
									<div className="font-semibold">Subscription Plan</div>
									<span className="block my-1">
										{hasSubscriptionDetails &&
										mostRecentSubscription.product ? (
											mostRecentSubscription.product.name
										) : (
											'None'
										)}
									</span>
									<div className="font-semibold mt-4">Status</div>
									<span className="block my-1">
										{hasSubscriptionDetails && mostRecentSubscription.status ? (
											<Fragment>
												{capitalizeFirstLetter(mostRecentSubscription.status)}
												{mostRecentSubscription.status !== 'canceled' &&
												mostRecentSubscription.scheduled_cancellation_date && (
													<Fragment
													>{`, but cancels on ${formatDateWithMonthName(
														mostRecentSubscription.scheduled_cancellation_date
													)}`}</Fragment>
												)}
											</Fragment>
										) : (
											'N/A'
										)}
									</span>
								</div>
								<div className="md:col-span-1">
									{hasSubscriptionDetails && (
										<div>
											<div className="font-semibold">Amount</div>
											<div className="flex items-center">
												<span className="block my-1">
													{mostRecentSubscription.final_price_cents === 0 ? (
														'$0'
													) : mostRecentSubscription.final_price_cents ? (
														`$${mostRecentSubscription.final_price_cents / 100}`
													) : (
														'Contact Support'
													)}/
													{mostRecentSubscription.billing_frequency}
													{mostRecentSubscription.discount_name ? (
														<span className="ml-2">
															({mostRecentSubscription.discount_name})
														</span>
													) : (
														''
													)}
												</span>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</Fragment>
				</div>
			</div>
			{isEditing && (
				<UserEditForm
					user={user}
					update_user_endpoint={update_user_endpoint}
					onCancel={() => setIsEditing(false)}
					closeModal={() => setIsEditing(false)}
				/>
			)}
		</div>
	);
}
