import React from 'react';
import FieldErrors from '../ErrorComponents/FieldErrors/FieldErrors';
import HelperText from './HelperText';

export default class TextInput extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			icon,
			id,
			name,
			style,
			placeholder,
			value,
			readOnly,
			onChange,
			helperText,
			fieldErrors
		} = this.props;

		return (
			<div className="TextInput mt-1 mb-4">
				<span className={icon} style={{ fontSize: 16, top: 10, left: 13 }} />
				<input
					type="text"
					autoCapitalize="off"
					autoComplete="off"
					className={`${this.props
						.tailwindStyles} block border border-gray-300 w-full px-4 h-10 rounded-md ${icon
						? 'has-icon'
						: ''}`}
					id={id}
					name={name}
					style={style}
					placeholder={placeholder}
					value={value || ''}
					onChange={onChange}
					readOnly={readOnly ? true : ''}
				/>
				<div className="p-1">
					{fieldErrors && fieldErrors.hasOwnProperty(name) ? (
						<FieldErrors fieldName={name} fieldErrors={fieldErrors} />
					) : (
						<HelperText>{helperText}</HelperText>
					)}
				</div>
			</div>
		);
	}
}
