import React from 'react';
import UserDetailsCard from '../../User/UserDetailsCard';
import { useAuth } from '../../../contexts/AuthContext';

function UserProfilePage({
	get_user_endpoint,
	update_user_endpoint,
	get_subscriptions_by_user_endpoint,
	create_checkout_session_endpoint,
	stripe_public_key
}) {
	const { user } = useAuth();

	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<UserDetailsCard
				userId={user.id}
				get_user_endpoint={get_user_endpoint}
				update_user_endpoint={update_user_endpoint}
				get_subscriptions_by_user_endpoint={get_subscriptions_by_user_endpoint}
				create_checkout_session_endpoint={create_checkout_session_endpoint}
				stripe_public_key={stripe_public_key}
			/>
		</div>
	);
}

export default UserProfilePage;
