import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const DynamicFFLogoRoute = ({ children }) => {
	const { authenticated, loading } = useAuth();

	if (loading) {
		return (
			<div>
				<LoadingSpinner />
			</div>
		);
	}

	if (authenticated) {
		return <Navigate to="/players" />;
	}

	if (!authenticated) {
		return children;
	}
};

export default DynamicFFLogoRoute;
