import React from 'react';

export default function SponsoredItemsCard(props) {
	return (
		<div>
			<div className="card-body">
				<div className="flex flex-wrap">
					{props.items.map((item, index) => (
						<div className="w-1/4 p-4" key={index}>
							<div className="card bg-gray-100 p-10 rounded">
								<img
									src={item.imgSrc}
									alt={item.title}
									className="card-img-top mx-auto"
								/>
								<div className="card-body">
									<h5 className="card-title text-lg font-semibold">
										{item.title}
									</h5>
									<p className="card-text">{item.description}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
