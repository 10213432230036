import React from 'react';

const FixedTeamBanner = ({ scrolledPastBanner, team, temperature }) => {
	if (!scrolledPastBanner || team.length === 0) return null;

	const { image_url, name, location } = team;

	return (
		<div className="fixed top-0 left-0 right-0 h-28 bg-white text-black flex items-center justify-center border border-gray-300 shadow-lg z-50">
			<div className="flex items-center">
				<div
					onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
					style={{ cursor: 'pointer' }}
					className="flex items-center"
				>
					<div className="relative mr-4">
						<img
							src={`${image_url}?w=75&h=75`}
							alt={`${name}`}
							style={{
								width: '75px',
								height: '75px',
								objectFit: 'cover',
								border: '1px solid #ddd',
								borderRadius: '50%',
								backgroundColor: 'white'
							}}
						/>
					</div>
					<div>
						<div className="text-2xl font-bold">{name}</div>
						<div>
							<div className="text-lg text-gray-500">
								{`${location}`}
								{temperature !== null &&
									` • ${temperature.temp}\u00B0, ${temperature.weather}`}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FixedTeamBanner;
