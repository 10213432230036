import React, { useState, useEffect, useRef, Fragment } from 'react';
import CircularBarChart from '../../../Charts/CircularBarChart';
import getPositionAbbreviation from '../../../../utils/getPositionAbbreviation';

const ComparePlayerFantasyFitnessScores = ({ selectedPlayers }) => {
	const [
		isFFSHovered,
		setIsFFSHovered
	] = useState(false);
	const [
		isFFTTHovered,
		setIsFFTTHovered
	] = useState(false);
	const [
		isFFTSHovered,
		setIsFFTSHovered
	] = useState(false);

	const rankModalRef = useRef(null);
	const currentPlayerRef = useRef(null);
	const [
		gradeRanks,
		setGradeRanks
	] = useState([]);
	const [
		visibleRankModal,
		setVisibleRankModal
	] = useState(null);
	const [
		loading,
		setLoading
	] = useState(false);

	// Handle rank modal click
	const handleRankClick = async (gradeType, position) => {
		if (visibleRankModal === gradeType) {
			setVisibleRankModal(null);
		} else {
			setLoading(true);
			setVisibleRankModal(gradeType);

			try {
				const response = await fetch(
					`/api/v1/players/position-ranks/${encodeURIComponent(position)}`
				);
				if (!response.ok) throw new Error('Network response was not ok');
				const data = await response.json();
				setLoading(false);
				setGradeRanks(data);
			} catch (error) {
				console.error('Failed to fetch grade ranks:', error);
			}
		}
	};

	// Handle player click
	const handlePlayerClick = (playerId, playerPosition) => {
		setVisibleRankModal(null);
		const url = `/players/${playerId}?position=${playerPosition}`;
		window.open(url, '_blank');
	};

	// Scroll to the selected player in the modal
	useEffect(
		() => {
			setTimeout(() => {
				if (visibleRankModal && currentPlayerRef.current) {
					currentPlayerRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center'
					});
				}
			}, 500);
		},
		[
			visibleRankModal,
			currentPlayerRef
		]
	);

	// Handle clicks outside the rank modal to close it
	const handleClickOutside = (event) => {
		if (rankModalRef.current && !rankModalRef.current.contains(event.target)) {
			setVisibleRankModal(null);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const tooltipStyles = {
		position: 'absolute',
		top: '100%',
		left: '125px',
		transform: 'translateX(-50%)',
		whiteSpace: 'wrap',
		width: '250px'
	};

	const tooltipContent = {
		ffs:
			"This is the player's Fantasy Fitness Score™. It combines the player's Fantasy Fitness Talent Score™ and Fantasy Fitness Team Score™ to estimate their overall value.",
		ffts:
			"This is the player's Fantasy Fitness Talent Score™. It is calculated based on a combination of the player's production and athleticism.",
		fftt:
			"This is the player's Fantasy Fitness Team Score™. It is calculated based on a combination of the player's team environment factors."
	};

	const renderTooltip = (isHovered, content) =>
		isHovered && (
			<div
				className="p-2 bg-white border border-gray-300 shadow-lg rounded-lg text-sm z-10"
				style={tooltipStyles}
			>
				{content}
			</div>
		);

	return (
		<Fragment>
			{/* Fantasy Fitness Score */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Overall Score™
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isFFSHovered
								? 'text-gray-700'
								: ''}`}
							onMouseEnter={() => setIsFFSHovered(true)}
							onMouseLeave={() => setIsFFSHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isFFSHovered, tooltipContent.ffs)}
					</div>
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`fitness-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-55px' }}>
							<CircularBarChart
								loading={loading}
								chartSize="md"
								value={
									player && player.fantasy_fitness_grade ? (
										player.fantasy_fitness_grade
									) : (
										0
									)
								}
								maxPossible={10}
								rank={
									player && player.fantasy_fitness_grade_position_rank ? (
										player.fantasy_fitness_grade_position_rank
									) : (
										999
									)
								}
								abbr_position={getPositionAbbreviation(
									player && player.position ? player.position : ''
								)}
								gradeRanks={
									gradeRanks && gradeRanks.fantasy_fitness_grades ? (
										gradeRanks.fantasy_fitness_grades
									) : (
										[]
									)
								}
								handleRankClick={() =>
									handleRankClick(
										`fantasy_fitness_score_${player.id}_${player.last_name}`,
										player.position
									)}
								isRankVisible={
									visibleRankModal ===
									`fantasy_fitness_score_${player.id}_${player.last_name}`
								}
								rankModalRef={rankModalRef}
								currentPlayerRef={currentPlayerRef}
								handlePlayerClick={handlePlayerClick}
								currentPlayerId={player && player.id ? player.id : 0}
								hasTitle={false}
								hasLabel={true}
								hasRankModalEnabled={true}
								renderOnComparePlayerModal={true}
							/>
						</div>
					</td>
				))}
			</tr>
			{/* Fantasy Fitness Talent Score */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Talent Score™
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isFFTTHovered
								? 'text-gray-700'
								: ''}`}
							onMouseEnter={() => setIsFFTTHovered(true)}
							onMouseLeave={() => setIsFFTTHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isFFTTHovered, tooltipContent.ffts)}
					</div>
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`fitness-talent-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							<CircularBarChart
								loading={loading}
								chartSize="sm"
								value={player && player.talent_grade ? player.talent_grade : 0}
								maxPossible={10}
								rank={
									player && player.talent_grade_position_rank ? (
										player.talent_grade_position_rank
									) : (
										999
									)
								}
								abbr_position={getPositionAbbreviation(
									player && player.position ? player.position : ''
								)}
								gradeRanks={
									gradeRanks && gradeRanks.talent_grades ? (
										gradeRanks.talent_grades
									) : (
										[]
									)
								}
								handleRankClick={() =>
									handleRankClick(
										`fantasy_fitness_talent_score_${player.id}_${player.last_name}`,
										player.position
									)}
								isRankVisible={
									visibleRankModal ===
									`fantasy_fitness_talent_score_${player.id}_${player.last_name}`
								}
								rankModalRef={rankModalRef}
								currentPlayerRef={currentPlayerRef}
								handlePlayerClick={handlePlayerClick}
								currentPlayerId={player && player.id ? player.id : 0}
								hasTitle={false}
								hasLabel={true}
								hasRankModalEnabled={true}
								renderOnComparePlayerModal={true}
							/>
						</div>
					</td>
				))}
			</tr>
			{/* Fantasy Fitness Team Score */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Team Score™
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isFFTSHovered
								? 'text-gray-700'
								: ''}`}
							onMouseEnter={() => setIsFFTSHovered(true)}
							onMouseLeave={() => setIsFFTSHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isFFTSHovered, tooltipContent.fftt)}
					</div>
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`fitness-team-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							<CircularBarChart
								loading={loading}
								chartSize="sm"
								value={player && player.team_grade ? player.team_grade : 0}
								maxPossible={10}
								rank={
									player && player.team_grade_position_rank ? (
										player.team_grade_position_rank
									) : (
										999
									)
								}
								abbr_position={getPositionAbbreviation(
									player && player.position ? player.position : ''
								)}
								gradeRanks={
									gradeRanks && gradeRanks.team_grades ? (
										gradeRanks.team_grades
									) : (
										[]
									)
								}
								handleRankClick={() =>
									handleRankClick(
										`fantasy_fitness_team_score_${player.id}_${player.last_name}`,
										player.position
									)}
								isRankVisible={
									visibleRankModal ===
									`fantasy_fitness_team_score_${player.id}_${player.last_name}`
								}
								rankModalRef={rankModalRef}
								currentPlayerRef={currentPlayerRef}
								handlePlayerClick={handlePlayerClick}
								currentPlayerId={player && player.id ? player.id : 0}
								hasTitle={false}
								hasLabel={true}
								hasRankModalEnabled={true}
								renderOnComparePlayerModal={true}
							/>
						</div>
					</td>
				))}
			</tr>
		</Fragment>
	);
};

export default ComparePlayerFantasyFitnessScores;
