import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children, endpoints }) => {
	const navigate = useNavigate();
	const [
		authenticated,
		setAuthenticated
	] = useState(false);
	const [
		loading,
		setLoading
	] = useState(true);
	const [
		csrfToken,
		setCsrfToken
	] = useState('');
	const [
		user,
		setUser
	] = useState(null);
	const [
		subscription,
		setSubscription
	] = useState({});
	const [
		rosterCount,
		setRosterCount
	] = useState(0);

	useEffect(
		() => {
			const initializeAuth = async () => {
				try {
					const csrfResponse = await fetch('/csrf-token');
					const csrfData = await csrfResponse.json();
					setCsrfToken(csrfData.csrfToken);

					const authResponse = await fetch(
						endpoints.check_authentication_endpoint
					);
					if (!authResponse.ok) {
						throw new Error(`HTTP error! Status: ${authResponse.status}`);
					}
					const authData = await authResponse.json();
					setAuthenticated(authData.authenticated);
					setUser(authData.user || null); // Set user object if available
					setSubscription(authData.subscription || null); // Set subscription object if available
				} catch (error) {
					console.error('Error initializing authentication:', error);
				} finally {
					setLoading(false);
				}
			};

			initializeAuth();
		},
		[
			endpoints
		]
	);

	const signIn = async (credentials) => {
		try {
			const response = await fetch(endpoints.sign_in_endpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRF-Token': csrfToken
				},
				body: JSON.stringify(credentials)
			});

			if (response.ok) {
				const data = await response.json();
				setAuthenticated(true);
				setUser(data.user);
				setSubscription(data.subscription);
				setRosterCount(data.roster_count);
				// console.log('Sign in successful:', data);
				if (data.redirect) {
					navigate(data.redirect);
				} else {
					window.location.href = '/players';
				}
			} else {
				const errorData = await response.json();
				console.error('Error signing in:', errorData);
				return errorData;
			}
		} catch (error) {
			console.error('Error signing in:', error);
			throw error;
		}
	};

	const signOut = async () => {
		try {
			const response = await fetch(endpoints.sign_out_endpoint, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					'X-CSRF-Token': csrfToken
				}
			});

			if (response.ok) {
				setAuthenticated(false);
				setUser(null);
				setCsrfToken('');
				window.location.href = '/signin';
			} else {
				const errorData = await response.json();
				console.error('Error signing out:', errorData);
			}
		} catch (error) {
			console.error('Error signing out:', error);
		}
	};

	if (loading) {
		return (
			<div>
				<LoadingSpinner />
			</div>
		);
	}

	const authContextValue = {
		authenticated,
		signIn,
		signOut,
		csrfToken,
		user,
		subscription,
		rosterCount,
		endpoints
	};

	return (
		<AuthContext.Provider value={authContextValue}>
			{children}
		</AuthContext.Provider>
	);
};
