import React from 'react';

export default class FormSection extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="FormSection" style={this.props.style}>
				<form>{this.props.children}</form>
			</div>
		);
	}
}
