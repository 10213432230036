export const getCompetition = (player) => {
	if (
		!player ||
		!player[0] ||
		!player[0].position ||
		!player[0].player_same_team_grade_rank_data ||
		!player[0].player_same_team_grade_rank_data.talent_grades
	) {
		return [];
	}

	const position = player[0].position;
	const talentGrades = player[0].player_same_team_grade_rank_data.talent_grades;

	// Define position order based on the current player's position
	const positionOrderMap = {
		'running back': [
			position,
			'wide receiver',
			'tight end'
		],
		'wide receiver': [
			position,
			'tight end',
			'running back'
		],
		'tight end': [
			position,
			'wide receiver',
			'running back'
		],
		quarterback: [
			position
		]
	};

	const positionOrder = positionOrderMap[position] || [];

	return talentGrades
		.filter((p) => positionOrder.includes(p.position))
		.sort(
			(a, b) =>
				positionOrder.indexOf(a.position) - positionOrder.indexOf(b.position)
		);
};

export const getQuarterbacks = (player) => {
	if (
		!player ||
		!player[0] ||
		!player[0].player_same_team_grade_rank_data ||
		!player[0].player_same_team_grade_rank_data.talent_grades
	) {
		return [];
	}

	return player[0].player_same_team_grade_rank_data.talent_grades.filter(
		(p) => p.position === 'quarterback'
	);
};

export const getNonQuarterbacks = (player) => {
	if (
		!player ||
		!player[0] ||
		!player[0].player_same_team_grade_rank_data ||
		!player[0].player_same_team_grade_rank_data.talent_grades
	) {
		return [];
	}

	const talentGrades = player[0].player_same_team_grade_rank_data.talent_grades;

	// Define the desired order of positions
	const positionOrder = [
		'wide receiver',
		'tight end',
		'running back'
	];

	return talentGrades
		.filter((p) => p.position !== 'quarterback')
		.sort(
			(a, b) =>
				positionOrder.indexOf(a.position) - positionOrder.indexOf(b.position)
		);
};
