// Mapping function to convert position name to capital spelling
const getPositionCapitalization = (position) => {
	switch (position.toLowerCase()) {
		case 'quarterback':
			return 'Quarterback';
		case 'running back':
			return 'Running Back';
		case 'wide receiver':
			return 'Wide Receiver';
		case 'tight end':
			return 'Tight End';
		default:
			return position; // Return original position if not matched
	}
};

export default getPositionCapitalization;
