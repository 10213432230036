import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { Fragment } from 'react/cjs/react.production.min';
import PlayerProfileCard from '../../Player/PlayerProfileCard/PlayerProfileCard';
import TeamProfileCard from '../../Team/TeamProfileCard/TeamProfileCard';
import StatusWidget from '../../StatusWidget/StatusWidget';
import RosterPlayerNoteModal from '../RosterPlayerNoteModal/RosterPlayerNoteModal';
import RosterPortraitCard from '../RosterPortraitCard/RosterPortraitCard';
import FixedRosterBanner from '../FixedRosterBanner/FixedRosterBanner';
import Week17OpponentsModal from '../../Player/Week17OpponentsModal/Week17OpponentsModal';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

const RosterDraftCard = ({
	get_roster_by_user_endpoint,
	get_player_endpoint,
	get_players_endpoint,
	get_team_endpoint,
	create_roster_player_by_roster_endpoint,
	delete_roster_player_by_roster_endpoint,
	get_roster_player_note_endpoint,
	create_roster_player_note_endpoint,
	update_roster_player_note_endpoint,
	selectedRoster,
	isDraftModeEnabled,
	selectedPlayers
}) => {
	const { user } = useAuth();
	const { id } = useParams();
	const modalRef = useRef();
	const [
		roster,
		setRoster
	] = useState(null);
	const [
		selectedPlayer,
		setSelectedPlayer
	] = useState([]);
	const [
		isPlayerModalOpen,
		setIsPlayerModalOpen
	] = useState(false);
	const [
		selectedTeam,
		setSelectedTeam
	] = useState([]);
	const [
		isTeamModalOpen,
		setIsTeamModalOpen
	] = useState(false);
	const [
		isRosterPlayerNoteModalOpen,
		setIsRosterPlayerNoteModalOpen
	] = useState(false);
	const [
		scrolledPastBanner,
		setScrolledPastBanner
	] = useState(false);
	const [
		isRosterPlayersSectionOpen,
		setIsRosterPlayerSectionOpen
	] = useState(true);
	const [
		isPlayerWeek17OpponentsModalOpen,
		setIsPlayerWeek17OpponentsModalOpen
	] = useState(false);

	useEffect(
		() => {
			if (get_roster_by_user_endpoint) {
				const endpoint = get_roster_by_user_endpoint
					.replace('user_id', user.id)
					.replace('roster_id', selectedRoster ? selectedRoster.id : id);
				fetch(endpoint).then((response) => response.json()).then((data) => {
					setRoster(data.data);
				});
			}
		},
		[
			get_roster_by_user_endpoint,
			user.id,
			selectedRoster,
			id
		]
	);

	const handleOpenRosterPlayersSection = () => {
		setIsRosterPlayerSectionOpen(!isRosterPlayersSectionOpen);
	};

	const handleOpenRosterPlayerNote = (player) => {
		setSelectedPlayer(player);
		setIsRosterPlayerNoteModalOpen(true);
	};

	const handleOpenPlayerWeek17OpponentsModal = (player) => {
		setSelectedPlayer(player);
		setIsPlayerWeek17OpponentsModalOpen(true);
	};

	const handlePlayerClick = (player) => {
		setSelectedPlayer(player);
		setIsPlayerModalOpen(true);
	};

	const handleTeamClick = (team) => {
		setSelectedTeam(team);
		setIsTeamModalOpen(true);
	};

	const closePlayerModal = () => {
		setIsPlayerModalOpen(false);
		setSelectedPlayer(null);
	};

	const closeTeamModal = () => {
		setIsTeamModalOpen(false);
		setSelectedTeam(null);
	};

	const closeWeek17OpponentsModal = () => {
		setIsPlayerWeek17OpponentsModalOpen(false);
		setSelectedPlayer(null);
	};

	const closePlayerModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsPlayerModalOpen(false);
			setSelectedPlayer(null);
		}
	};

	const closeTeamModalOnClickOutside = (event) => {
		if (modalRef.current && !modalRef.current.contains(event.target)) {
			setIsTeamModalOpen(false);
			setSelectedTeam(null);
		}
	};

	const closeRosterPlayerNoteModalOnClickOutside = (event) => {
		if (!modalRef.current.contains(event.target)) {
			setIsRosterPlayerNoteModalOpen(false);
		}
	};

	const closeWeek17OpponentsModalOnClickOutside = (event) => {
		if (!modalRef.current.contains(event.target)) {
			setIsPlayerWeek17OpponentsModalOpen(false);
		}
	};

	useEffect(
		() => {
			if (isPlayerModalOpen) {
				document.addEventListener('mousedown', closePlayerModalOnClickOutside);
			} else {
				document.removeEventListener(
					'mousedown',
					closePlayerModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closePlayerModalOnClickOutside
				);
			};
		},
		[
			isPlayerModalOpen
		]
	);

	useEffect(
		() => {
			if (isTeamModalOpen) {
				document.addEventListener('mousedown', closeTeamModalOnClickOutside);
			} else {
				document.removeEventListener('mousedown', closeTeamModalOnClickOutside);
			}
			return () => {
				document.removeEventListener('mousedown', closeTeamModalOnClickOutside);
			};
		},
		[
			isTeamModalOpen
		]
	);

	useEffect(
		() => {
			if (isRosterPlayerNoteModalOpen) {
				document.addEventListener(
					'mousedown',
					closeRosterPlayerNoteModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeRosterPlayerNoteModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeRosterPlayerNoteModalOnClickOutside
				);
			};
		},
		[
			isRosterPlayerNoteModalOpen
		]
	);

	useEffect(
		() => {
			if (isPlayerWeek17OpponentsModalOpen) {
				document.addEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			} else {
				document.removeEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			}
			return () => {
				document.removeEventListener(
					'mousedown',
					closeWeek17OpponentsModalOnClickOutside
				);
			};
		},
		[
			isPlayerWeek17OpponentsModalOpen
		]
	);

	useEffect(() => {
		const handleScroll = () => {
			const bannerHeight = document.querySelector('.banner-section')
				.offsetHeight;
			const scrolled = window.scrollY > bannerHeight;
			setScrolledPastBanner(scrolled);
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	if (!roster) {
		return (
			<div>
				<LoadingSpinner />
			</div>
		);
	}

	const renderPlayers = (position, players) => (
		<div className="mx-6 inline-block py-6 w-72">
			<h3
				className="text-xl text-gray-800 font-bold"
				style={{ marginBottom: '-10px' }}
			>
				{position.toUpperCase()}
			</h3>
			<div>
				{players.length === 0 ? (
					<p className="text-gray-500 flex justify-start pt-6">
						No {position.toLowerCase()} drafted yet.
					</p>
				) : (
					players.map((player) => (
						<div
							key={player.id}
							className={`flex flex-col lg:flex-row justify-between items-start mb-2 border-gray-200 border pt-4 rounded-xl my-6 shadow-sm bg-white w-full`}
						>
							<div className="flex items-start mx-4">
								<div className="flex items-start">
									<div className="relative mr-4">
										<img
											src={`${player.image_url}?w=50&h=50`}
											alt={`${player.first_name} ${player.last_name}`}
											style={{
												width: '50px',
												height: '50px',
												objectFit: 'cover',
												border: '1px solid #ddd',
												borderRadius: '50%',
												backgroundColor: 'white'
											}}
										/>
										<div className="absolute top-2 left-9 transform translate-x-1/2 -translate-y-1/2">
											<StatusWidget
												status={player.status}
												description={player.injury_description}
												hideLabel={true}
												is50pXThumbnail={true}
											/>
										</div>
									</div>
									<div>
										<div
											className="text-lg font-bold hover:underline cursor-pointer"
											onClick={() => handlePlayerClick(player)}
										>
											{player.first_name} {player.last_name}
										</div>
										<div>
											<div
												className="text-sm text-blue-500 hover:underline cursor-pointer"
												onClick={() => handleTeamClick(player.team)}
											>
												{player.team.name}
											</div>
										</div>
										<i
											key={player.id + player.position}
											className="fa-regular fa-note-sticky fa-lg text-blue-500 rounded mr-3 cursor-pointer"
											alt="Manager Player Notes"
											onClick={() => handleOpenRosterPlayerNote(player)}
										/>
										<i
											key={player.id + player.position}
											className="fa-solid fa-yin-yang fa-lg text-blue-500 rounded mr-3 cursor-pointer"
											alt="Week 17 Opponents"
											onClick={() =>
												handleOpenPlayerWeek17OpponentsModal(player)}
										/>
									</div>
								</div>
							</div>
						</div>
					))
				)}
			</div>
		</div>
	);

	return (
		<div>
			<div className="banner-section">
				<RosterPortraitCard
					roster={roster}
					isDraftModeEnabled={isDraftModeEnabled}
					handleOpenRosterPlayersSection={handleOpenRosterPlayersSection}
				/>
			</div>
			{/* Fixed Roster Banner  - Displays after scrolling past the Roster Portrait Card Section */}
			{scrolledPastBanner && (
				<FixedRosterBanner
					scrolledPastBanner={scrolledPastBanner}
					roster={roster}
				/>
			)}

			<div className="relative flex items-start">
				{roster.players &&
				isRosterPlayersSectionOpen && (
					<Fragment>
						{renderPlayers('Quarterbacks', roster.players.quarterbacks)}
						{renderPlayers('Running Backs', roster.players.running_backs)}
						{renderPlayers('Wide Receivers', roster.players.wide_receivers)}
						{renderPlayers('Tight Ends', roster.players.tight_ends)}
					</Fragment>
				)}
			</div>

			{/* Player Modal */}
			{isPlayerModalOpen &&
			selectedPlayer && (
				<div
					className="fixed inset-0 z-50 overflow-auto flex w-full"
					style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
				>
					<div
						className="relative p-8 bg-white m-auto flex-col flex rounded-lg"
						style={{ width: '75%' }}
						ref={modalRef}
					>
						<button
							className="absolute top-0 right-0 p-2 text-gray-700 hover:text-gray-900"
							onClick={closePlayerModal}
						>
							<svg
								className="h-6 w-6 fill-current"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M6.225 4.811l.084.073L12 10.587l5.691-5.691a1 1 0 011.497 1.32l-.083.094L13.413 12l5.692 5.692a1 1 0 01-1.32 1.497l-.094-.083L12 13.413l-5.691 5.691a1 1 0 01-1.497-1.32l.083-.094L10.587 12 4.811 6.225a1 1 0 011.32-1.497z" />
							</svg>
						</button>
						<PlayerProfileCard
							player={selectedPlayer}
							get_player_endpoint={get_player_endpoint}
							get_players_endpoint={get_players_endpoint}
						/>
					</div>
				</div>
			)}
			{/* Team Modal */}
			{isTeamModalOpen &&
			selectedTeam && (
				<div
					className="fixed inset-0 z-50 overflow-auto flex w-full"
					style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
				>
					<div
						className="relative p-8 bg-white m-auto flex-col flex rounded-lg"
						style={{ width: '75%' }}
						ref={modalRef}
					>
						<button
							className="absolute top-0 right-0 p-2 text-gray-700 hover:text-gray-900"
							onClick={closeTeamModal}
						>
							<svg
								className="h-6 w-6 fill-current"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M6.225 4.811l.084.073L12 10.587l5.691-5.691a1 1 0 011.497 1.32l-.083.094L13.413 12l5.692 5.692a1 1 0 01-1.32 1.497l-.094-.083L12 13.413l-5.691 5.691a1 1 0 01-1.497-1.32l.083-.094L10.587 12 4.811 6.225a1 1 0 011.32-1.497z" />
							</svg>
						</button>
						<TeamProfileCard
							team={selectedTeam}
							get_team_endpoint={get_team_endpoint}
						/>
					</div>
				</div>
			)}
			{/* Roster Player Note Modal */}
			{isRosterPlayerNoteModalOpen && (
				<RosterPlayerNoteModal
					userId={user.id}
					rosterId={roster.id}
					player={selectedPlayer}
					closeRosterPlayerNoteModal={() =>
						setIsRosterPlayerNoteModalOpen(false)}
					get_roster_player_note_endpoint={get_roster_player_note_endpoint}
					create_roster_player_note_endpoint={
						create_roster_player_note_endpoint
					}
					update_roster_player_note_endpoint={
						update_roster_player_note_endpoint
					}
					modalRef={modalRef}
				/>
			)}
			{/* Week 17 Opponents Modal */}
			{isPlayerWeek17OpponentsModalOpen && (
				<Week17OpponentsModal
					player={selectedPlayer}
					selectedPlayers={selectedPlayers}
					closeWeek17OpponentsModal={closeWeek17OpponentsModal}
					modalRef={modalRef}
				/>
			)}
		</div>
	);
};

export default RosterDraftCard;
