import React from 'react';

const HofTag = () => {
	return (
		<span
			title="Future Hall of Famer"
			className="ml-2 bg-yellow-700 text-white text-xs font-semibold px-2 py-1 rounded"
		>
			HOF
		</span>
	);
};

export default HofTag;
