import React from 'react';
import PropTypes from 'prop-types';
import UserSignUpForm from '../../User/UserSignupForm';

export default function SignUpPage({ create_user_endpoint }) {
	if (!true) {
		return <div>Error: Missing sign-in endpoint</div>;
	}

	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<UserSignUpForm create_user_endpoint={create_user_endpoint} />
		</div>
	);
}

SignUpPage.propTypes = {
	create_user_endpoint: PropTypes.string.isRequired
};
