import React from 'react';

const RookieTag = () => {
	return (
		<span
			title="Rookie"
			className="ml-2 bg-purple-700 text-white text-xs font-semibold px-2 py-1 rounded"
		>
			RK
		</span>
	);
};

export default RookieTag;
