import React from 'react';
import { Fragment } from 'react';

const ComparePlayerReceivingStats = ({ selectedPlayers }) => {
  return (
    <Fragment>
      {/* Receptions Per Game */}
      <tr>
        <td className="px-6 whitespace-nowrap font-medium">Receptions Per Game</td>
        {selectedPlayers.map((player, index) => (
          <td key={`attempts-${index}`} className="px-6 py-4">
            {player.pro_receptions_per_game || '-'}
          </td>
        ))}
      </tr>
      {/* Yards Per Game */}
      <tr>
        <td className="px-6 whitespace-nowrap font-medium">Receiving Yards Per Game</td>
        {selectedPlayers.map((player, index) => (
          <td key={`yards-${index}`} className="px-6 py-4">
            {player.pro_receiving_yards_per_game || '-'}
          </td>
        ))}
      </tr>
      {/* Rushing TDs Per Game */}
      <tr>
        <td className="px-6 whitespace-nowrap font-medium">Receiving TDs Per Game</td>
        {selectedPlayers.map((player, index) => (
          <td key={`tds-${index}`} className="px-6 py-4">
            {player.pro_receiving_touchdowns_per_game || '-'}
          </td>
        ))}
      </tr>
    </Fragment>
  );
};

export default ComparePlayerReceivingStats;
