import React, { useState, Fragment } from 'react';
import CircularBarChart from '../../../Charts/CircularBarChart';

const ComparePlayerTeamEnvironmentScores = ({ selectedPlayers }) => {
	const maxPossibleScore = 10;

	// States for each tooltip
	const [isOpportunityHovered, setIsOpportunityHovered] = useState(false);
	const [isCompetitionHovered, setIsCompetitionHovered] = useState(false);
	const [isOLineHovered, setIsOLineHovered] = useState(false);
	const [isQBWRTERBHovered, setIsQBWRTERBHovered] = useState(false);
	const [isCoachingHovered, setIsCoachingHovered] = useState(false);

	const tooltipStyles = {
		position: 'absolute',
		top: '100%',
		left: '125px',
		transform: 'translateX(-50%)',
		whiteSpace: 'wrap',
		width: '250px'
	};

	const tooltipContent = {
		opportunity: "This score is calculated based on the player's projected volume and role within their team's offense.",
		competition: "This score is calculated based on the player's competition for touches within their team's offense. A higher score indicates less competition for touches. A lower score indicates more competition for touches.",
		oline: "This score is calculated based on the quality of the player's offensive line, which impacts their ability to produce fantasy points.",
		qbwrterb: "This score is calculated based on the quality of the other skill position players on the team, which impacts the player's ability to produce fantasy points.",
		coaching: "This score is calculated based on the quality of the player's head coach and offensive coordinator, which impacts their ability to produce fantasy points."
	};

	const renderTooltip = (isHovered, content) => (
		isHovered && (
			<div
				className="p-2 bg-white border border-gray-300 shadow-lg rounded-lg text-sm z-10"
				style={tooltipStyles}
			>
				{content}
			</div>
		)
	);

	return (
		<Fragment>
			{/* Opportunity */}
			<tr>
				<td key={'some key'} className="px-6 whitespace-nowrap font-medium">
					Opportunity
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isOpportunityHovered ? 'text-gray-700' : ''}`}
							onMouseEnter={() => setIsOpportunityHovered(true)}
							onMouseLeave={() => setIsOpportunityHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isOpportunityHovered, tooltipContent.opportunity)}
					</div>
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`fitness-talent-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							<CircularBarChart
								title="Opportunity"
								chartSize="xs"
								value={player.opportunity_grade}
								maxPossible={maxPossibleScore}
								infoIconText={tooltipContent.opportunity}
								hasTitle={false}
								hasLabel={false}
								hasRankModalEnabled={false}
							/>
						</div>
					</td>
				))}
			</tr>
			{/* Competition */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Competition
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isCompetitionHovered ? 'text-gray-700' : ''}`}
							onMouseEnter={() => setIsCompetitionHovered(true)}
							onMouseLeave={() => setIsCompetitionHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isCompetitionHovered, tooltipContent.competition)}
					</div>
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`fitness-talent-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							<CircularBarChart
								title="Competition"
								chartSize="xs"
								value={player.competition_grade}
								maxPossible={maxPossibleScore}
								infoIconText={tooltipContent.competition}
								hasTitle={false}
								hasLabel={false}
								hasRankModalEnabled={false}
							/>
						</div>
					</td>
				))}
			</tr>
			{/* O-Line */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					O-Line
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isOLineHovered ? 'text-gray-700' : ''}`}
							onMouseEnter={() => setIsOLineHovered(true)}
							onMouseLeave={() => setIsOLineHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isOLineHovered, tooltipContent.oline)}
					</div>
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`fitness-talent-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							<CircularBarChart
								title="O-Line"
								chartSize="xs"
								value={player.team && player.team.offensive_line_grade ? player.team.offensive_line_grade : 0}
								maxPossible={maxPossibleScore}
								infoIconText={tooltipContent.oline}
								hasTitle={false}
								hasLabel={false}
								hasRankModalEnabled={false}
							/>
						</div>
					</td>
				))}
			</tr>
			{/* QB/WR/TE/RB */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					QB/WR/TE/RB
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isQBWRTERBHovered ? 'text-gray-700' : ''}`}
							onMouseEnter={() => setIsQBWRTERBHovered(true)}
							onMouseLeave={() => setIsQBWRTERBHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isQBWRTERBHovered, tooltipContent.qbwrterb)}
					</div>
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`fitness-talent-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							{player.position === 'quarterback' ? (
								<CircularBarChart
									title="WR/TE/RB"
									chartSize="xs"
									value={(((player.team && player.team.wide_receivers_grade) || 0) * 0.5 + ((player.team && player.team.tight_ends_grade) || 0) * 0.25 + ((player.team && player.team.running_backs_grade) || 0) * 0.25).toFixed(2)}
									maxPossible={maxPossibleScore}
									infoIconText={tooltipContent.qbwrterb}
									hasTitle={false}
									hasLabel={false}
									hasRankModalEnabled={false}
								/>
							) : (
								<CircularBarChart
									title="QB"
									chartSize="xs"
									value={player.team && player.team.quarterbacks_grade}
									maxPossible={maxPossibleScore}
									infoIconText={tooltipContent.qbwrterb}
									hasTitle={false}
									hasLabel={false}
									hasRankModalEnabled={false}
								/>
							)}
						</div>
					</td>
				))}
			</tr>
			{/* Coaching */}
			<tr>
				<td className="px-6 whitespace-nowrap font-medium">
					Coaching
					<div className="relative inline-block">
						<i
							className={`fas fa-circle-info ml-2 ${isCoachingHovered ? 'text-gray-700' : ''}`}
							onMouseEnter={() => setIsCoachingHovered(true)}
							onMouseLeave={() => setIsCoachingHovered(false)}
							style={{ marginLeft: '10px' }}
						/>
						{renderTooltip(isCoachingHovered, tooltipContent.coaching)}
					</div>
				</td>
				{selectedPlayers.map((player, index) => (
					<td key={`fitness-talent-score-${index}`} className="px-6 py-4">
						<div style={{ marginLeft: '-45px' }}>
							<CircularBarChart
								title="Coaching"
								chartSize="xs"
								value={player.team && player.team.head_coach_grade}
								maxPossible={maxPossibleScore}
								infoIconText={tooltipContent.coaching}
								hasTitle={false}
								hasLabel={false}
								hasRankModalEnabled={false}
							/>
						</div>
					</td>
				))}
			</tr>
		</Fragment>
	);
};

export default ComparePlayerTeamEnvironmentScores;
