import React from 'react';
import { Link } from 'react-router-dom';
import AdvancedDataTable from '../../../../DataTables/AdvancedDataTable';
import MobileDataTableCard from '../../../../DataTables/MobileDataTableCard';

const QuarterbacksCollegeAdvancedStatsTable = ({
	players,
	sortBy,
	sortOrder,
	handleSortOrderChange
}) => {
	return (
		<div>
			<div className="contents lg:hidden">
				<MobileDataTableCard
					data={players}
					style="text-gray-800 border-b-2 border-gray-300 font-semibold"
					rowRenderer={(row) => {
						const labels = [
							{
								displayName: 'Player',
								apiName: 'player',
								alignment: 'left'
							},
							{
								displayName: 'Completions Per Game',
								apiName: 'college_pass_completions_per_game',
								alignment: 'left',
								helper_text: 'The average number of pass completions per game.'
							},
							{
								displayName: 'Attempts Per Game',
								apiName: 'college_pass_attempts_per_game',
								alignment: 'left',
								helper_text: 'The average number of pass attempts per game.'
							},
							{
								displayName: 'Passing Yards Per Game',
								apiName: 'college_passing_yards_per_game',
								alignment: 'left',
								helper_text: 'The average passing yards per game.'
							},
							{
								displayName: 'Passing TDs Per Game',
								apiName: 'college_passing_touchdowns_per_game',
								alignment: 'left',
								helper_text: 'The average passing touchdowns per game.'
							},
							{
								displayName: 'INTs Per Game',
								apiName: 'college_interceptions_per_game',
								alignment: 'left',
								helper_text: 'The average interceptions per game.'
							},
							{
								displayName: 'Yards Per Completion',
								apiName: 'college_passing_yards_per_completion',
								alignment: 'left',
								helper_text: 'The average passing yards per completion.'
							},
							{
								displayName: 'Yards Per Attempt',
								apiName: 'college_passing_yards_per_attempt',
								alignment: 'left',
								helper_text: 'The average passing yards per attempt.'
							},
							{
								displayName: 'Completions Per TD',
								apiName: 'college_pass_completions_per_touchdown',
								alignment: 'left',
								helper_text: 'The average pass completions per touchdown.'
							},
							{
								displayName: 'Attempts Per TD',
								apiName: 'college_pass_attempts_per_touchdown',
								alignment: 'left',
								helper_text: 'The average pass attempts per touchdown.'
							},
							{
								displayName: 'Yards Per TD',
								apiName: 'college_passing_yards_per_touchdown',
								alignment: 'left',
								helper_text: 'The average passing yards per touchdown.'
							},
							{
								displayName: 'Completions Per INT',
								apiName: 'college_pass_completions_per_interception',
								alignment: 'left',
								helper_text: 'The average pass completions per interception.'
							},
							{
								displayName: 'Attempts Per INT',
								apiName: 'college_pass_attempts_per_interception',
								alignment: 'left',
								helper_text: 'The average pass attempts per interception.'
							},
							{
								displayName: 'Yards Per INT',
								apiName: 'college_passing_yards_per_interception',
								alignment: 'left',
								helper_text: 'The average passing yards per interception.'
							},
							{
								displayName: 'TDs Per INT',
								apiName: 'college_passing_touchdowns_per_interception',
								alignment: 'left',
								helper_text: 'The average passing touchdowns per interception.'
							},
							{
								displayName: 'Rush Attempts Per Game',
								apiName: 'college_rush_attempts_per_game',
								alignment: 'left',
								helper_text: 'The average rush attempts per game.'
							},
							{
								displayName: 'Rushing Yards Per Attempt',
								apiName: 'college_rushing_yards_per_attempt',
								alignment: 'left',
								helper_text: 'The average rushing yards per attempt.'
							},
							{
								displayName: 'Rushing Yards Per Game',
								apiName: 'college_rushing_yards_per_game',
								alignment: 'left',
								helper_text: 'The average rushing yards per game.'
							},
							{
								displayName: 'Rushing TDs Per Game',
								apiName: 'college_rushing_touchdowns_per_game',
								alignment: 'left',
								helper_text: 'The average rushing touchdowns per game.'
							},
							{
								displayName: 'Rush Attempts Per TD',
								apiName: 'college_rush_attempts_per_touchdown',
								alignment: 'left',
								helper_text: 'The average rush attempts per touchdown.'
							},
							{
								displayName: 'Rushing Yards Per TD',
								apiName: 'college_rushing_yards_per_touchdown',
								alignment: 'left',
								helper_text: 'The average rushing yards per touchdown.'
							},
							{
								displayName: 'Total Touches Per Game',
								apiName: 'college_total_touches_per_game',
								alignment: 'left',
								helper_text: 'The average total touches per game.'
							},
							{
								displayName: 'Total Yards Per Touch',
								apiName: 'college_total_yards_per_touch',
								alignment: 'left',
								helper_text: 'The average total yards per touch.'
							},
							{
								displayName: 'Total Yards Per Game',
								apiName: 'college_total_yards_per_game',
								alignment: 'left',
								helper_text: 'The average total yards per game.'
							},
							{
								displayName: 'Total TDs Per Game',
								apiName: 'college_total_touchdowns_per_game',
								alignment: 'left',
								helper_text: 'The average total touchdowns per game.'
							},
							{
								displayName: 'Total Touches Per TD',
								apiName: 'college_total_touches_per_touchdown',
								alignment: 'left',
								helper_text: 'The average total touches per touchdown.'
							},
							{
								displayName: 'Total Yards Per TD',
								apiName: 'college_total_yards_per_touchdown',
								alignment: 'left',
								helper_text: 'The average total yards per touchdown.'
							}
						];
						return (
							<div
								key={`${row.first_name} ${row.last_name} - QuarterbacksCollegeAdvancedStatsTable - MobileDataTableCard`}
								className="p-5 bg-white border-t text-gray-800"
							>
								<div>
									<Link
										to={`/players/${row.id}?position=${row.position}`}
										className="text-blue-500 hover:text-blue-700"
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: '50px',
													height: '50px',
													overflow: 'hidden',
													marginRight: '8px'
												}}
											>
												<img
													src={row.image_url + '?w=50&h=50'}
													alt={`${row.first_name} ${row.last_name}`}
													style={{
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														border: '1px solid #ddd',
														borderRadius: '50%'
													}}
												/>
											</div>
											{`${row.first_name} ${row.last_name}`}
										</div>
									</Link>
								</div>
								<div className="mt-4 font-semibold">
									{labels[1].displayName}
								</div>
								<div>{row.college_pass_completions_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[2].displayName}
								</div>
								<div>{row.college_pass_attempts_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[3].displayName}
								</div>
								<div>{row.college_passing_yards_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[4].displayName}
								</div>
								<div>{row.college_passing_touchdowns_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[5].displayName}
								</div>
								<div>{row.college_interceptions_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[6].displayName}
								</div>
								<div>{row.college_passing_yards_per_completion}</div>
								<div className="mt-4 font-semibold">
									{labels[7].displayName}
								</div>
								<div>{row.college_passing_yards_per_attempt}</div>
								<div className="mt-4 font-semibold">
									{labels[8].displayName}
								</div>
								<div>{row.college_pass_completions_per_touchdown}</div>
								<div className="mt-4 font-semibold">
									{labels[9].displayName}
								</div>
								<div>{row.college_pass_attempts_per_touchdown}</div>
								<div className="mt-4 font-semibold">
									{labels[10].displayName}
								</div>
								<div>{row.college_passing_yards_per_touchdown}</div>
								<div className="mt-4 font-semibold">
									{labels[11].displayName}
								</div>
								<div>{row.college_pass_completions_per_interception}</div>
								<div className="mt-4 font-semibold">
									{labels[12].displayName}
								</div>
								<div>{row.college_pass_attempts_per_interception}</div>
								<div className="mt-4 font-semibold">
									{labels[13].displayName}
								</div>
								<div>{row.college_passing_yards_per_interception}</div>
								<div className="mt-4 font-semibold">
									{labels[14].displayName}
								</div>
								<div>{row.college_passing_touchdowns_per_interception}</div>
								<div className="mt-4 font-semibold">
									{labels[15].displayName}
								</div>
								<div>{row.college_rush_attempts_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[16].displayName}
								</div>
								<div>{row.college_rushing_yards_per_attempt}</div>
								<div className="mt-4 font-semibold">
									{labels[17].displayName}
								</div>
								<div>{row.college_rushing_yards_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[18].displayName}
								</div>
								<div>{row.college_rushing_touchdowns_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[19].displayName}
								</div>
								<div>{row.college_rush_attempts_per_touchdown}</div>
								<div className="mt-4 font-semibold">
									{labels[20].displayName}
								</div>
								<div>{row.college_rushing_yards_per_touchdown}</div>
								<div className="mt-4 font-semibold">
									{labels[21].displayName}
								</div>
								<div>{row.college_total_touches_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[22].displayName}
								</div>
								<div>{row.college_total_yards_per_touch}</div>
								<div className="mt-4 font-semibold">
									{labels[23].displayName}
								</div>
								<div>{row.college_total_yards_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[24].displayName}
								</div>
								<div>{row.college_total_touchdowns_per_game}</div>
								<div className="mt-4 font-semibold">
									{labels[25].displayName}
								</div>
								<div>{row.college_total_touches_per_touchdown}</div>
								<div className="mt-4 font-semibold">
									{labels[26].displayName}
								</div>
								<div>{row.college_total_yards_per_touchdown}</div>
							</div>
						);
					}}
				/>
			</div>
			<div className="hidden lg:contents">
				<AdvancedDataTable
					data={players}
					style="text-gray-800 border-b-2 border-gray-300 font-semibold"
					sortOrder={sortOrder}
					sortColumn={sortBy ? sortBy : ''}
					onChangeSortOrder={handleSortOrderChange}
					sortingEnabled={true}
					columns={[
						{
							displayName: 'Player',
							apiName: 'player',
							alignment: 'left',
							helper_text: 'The name of the player.'
						},
						{
							displayName: 'Completions Per Game',
							apiName: 'college_pass_completions_per_game',
							alignment: 'left',
							helper_text: 'The average number of pass completions per game.'
						},
						{
							displayName: 'Attempts Per Game',
							apiName: 'college_pass_attempts_per_game',
							alignment: 'left',
							helper_text: 'The average number of pass attempts per game.'
						},
						{
							displayName: 'Passing Yards Per Game',
							apiName: 'college_passing_yards_per_game',
							alignment: 'left',
							helper_text: 'The average passing yards per game.'
						},
						{
							displayName: 'Passing TDs Per Game',
							apiName: 'college_passing_touchdowns_per_game',
							alignment: 'left',
							helper_text: 'The average passing touchdowns per game.'
						},
						{
							displayName: 'INTs Per Game',
							apiName: 'college_interceptions_per_game',
							alignment: 'left',
							helper_text: 'The average interceptions per game.'
						},
						{
							displayName: 'Yards Per Completion',
							apiName: 'college_passing_yards_per_completion',
							alignment: 'left',
							helper_text: 'The average passing yards per completion.'
						},
						{
							displayName: 'Yards Per Attempt',
							apiName: 'college_passing_yards_per_attempt',
							alignment: 'left',
							helper_text: 'The average passing yards per attempt.'
						},
						{
							displayName: 'Completions Per TD',
							apiName: 'college_pass_completions_per_touchdown',
							alignment: 'left',
							helper_text: 'The average pass completions per touchdown.'
						},
						{
							displayName: 'Attempts Per TD',
							apiName: 'college_pass_attempts_per_touchdown',
							alignment: 'left',
							helper_text: 'The average pass attempts per touchdown.'
						},
						{
							displayName: 'Yards Per TD',
							apiName: 'college_passing_yards_per_touchdown',
							alignment: 'left',
							helper_text: 'The average passing yards per touchdown.'
						},
						{
							displayName: 'Completions Per INT',
							apiName: 'college_pass_completions_per_interception',
							alignment: 'left',
							helper_text: 'The average pass completions per interception.'
						},
						{
							displayName: 'Attempts Per INT',
							apiName: 'college_pass_attempts_per_interception',
							alignment: 'left',
							helper_text: 'The average pass attempts per interception.'
						},
						{
							displayName: 'Yards Per INT',
							apiName: 'college_passing_yards_per_interception',
							alignment: 'left',
							helper_text: 'The average passing yards per interception.'
						},
						{
							displayName: 'TDs Per INT',
							apiName: 'college_passing_touchdowns_per_interception',
							alignment: 'left',
							helper_text: 'The average passing touchdowns per interception.'
						},
						{
							displayName: 'Rush Attempts Per Game',
							apiName: 'college_rush_attempts_per_game',
							alignment: 'left',
							helper_text: 'The average rush attempts per game.'
						},
						{
							displayName: 'Rushing Yards Per Attempt',
							apiName: 'college_rushing_yards_per_attempt',
							alignment: 'left',
							helper_text: 'The average rushing yards per attempt.'
						},
						{
							displayName: 'Rushing Yards Per Game',
							apiName: 'college_rushing_yards_per_game',
							alignment: 'left',
							helper_text: 'The average rushing yards per game.'
						},
						{
							displayName: 'Rushing TDs Per Game',
							apiName: 'college_rushing_touchdowns_per_game',
							alignment: 'left',
							helper_text: 'The average rushing touchdowns per game.'
						},
						{
							displayName: 'Rush Attempts Per TD',
							apiName: 'college_rush_attempts_per_touchdown',
							alignment: 'left',
							helper_text: 'The average rush attempts per touchdown.'
						},
						{
							displayName: 'Rushing Yards Per TD',
							apiName: 'college_rushing_yards_per_touchdown',
							alignment: 'left',
							helper_text: 'The average rushing yards per touchdown.'
						},
						{
							displayName: 'Total Touches Per Game',
							apiName: 'college_total_touches_per_game',
							alignment: 'left',
							helper_text: 'The average total touches per game.'
						},
						{
							displayName: 'Total Yards Per Touch',
							apiName: 'college_total_yards_per_touch',
							alignment: 'left',
							helper_text: 'The average total yards per touch.'
						},
						{
							displayName: 'Total Yards Per Game',
							apiName: 'college_total_yards_per_game',
							alignment: 'left',
							helper_text: 'The average total yards per game.'
						},
						{
							displayName: 'TD Per Game',
							apiName: 'college_total_touchdowns_per_game',
							alignment: 'left',
							helper_text: 'The average total touchdowns per game.'
						},
						{
							displayName: 'Total Touches Per TD',
							apiName: 'college_total_touches_per_touchdown',
							alignment: 'left',
							helper_text: 'The average total touches per touchdown.'
						},
						{
							displayName: 'Total Yards Per TD',
							apiName: 'college_total_yards_per_touchdown',
							alignment: 'left',
							helper_text: 'The average total yards per touchdown.'
						}
					]}
					rowRenderer={(row) => {
						return (
							<tr
								className="bg-white"
								key={`${row.first_name} ${row.last_name} - QuarterbacksCollege - AdvancedStatsTable`}
							>
								<td className="pl-8 py-3 text-left sticky left-0 bg-white z-10 w-60">
									<Link
										to={`/players/${row.id}?position=${row.position}`}
										className="text-blue-500 hover:text-blue-700"
									>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											<div
												style={{
													width: '50px',
													height: '50px',
													overflow: 'hidden',
													marginRight: '8px'
												}}
											>
												<img
													src={row.image_url + '?w=50&h=50'}
													alt={`${row.first_name} ${row.last_name}`}
													style={{
														width: '100%',
														height: '100%',
														objectFit: 'cover',
														border: '1px solid #ddd',
														borderRadius: '50%'
													}}
												/>
											</div>
											{`${row.first_name} ${row.last_name}`}
										</div>
									</Link>
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_pass_completions_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_pass_attempts_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_passing_yards_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_passing_touchdowns_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_interceptions_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_passing_yards_per_completion}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_passing_yards_per_attempt}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_pass_completions_per_touchdown}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_pass_attempts_per_touchdown}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_passing_yards_per_touchdown}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_pass_completions_per_interception}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_pass_attempts_per_interception}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_passing_yards_per_interception}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_passing_touchdowns_per_interception}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_rush_attempts_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_rushing_yards_per_attempt}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_rushing_yards_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_rushing_touchdowns_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_rush_attempts_per_touchdown}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_rushing_yards_per_touchdown}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_total_touches_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_total_yards_per_touch}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_total_yards_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_total_touchdowns_per_game}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_total_touches_per_touchdown}
								</td>
								<td className="pl-8 py-3 text-left">
									{row.college_total_yards_per_touchdown}
								</td>
							</tr>
						);
					}}
				/>
			</div>
		</div>
	);
};

export default QuarterbacksCollegeAdvancedStatsTable;
