import React from 'react';
import FieldErrors from '../ErrorComponents/FieldErrors/FieldErrors';
import HelperText from './HelperText';

export default class TextArea extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {
			icon,
			id,
			name,
			style,
			placeholder,
			value,
			readOnly,
			onChange,
			helperText,
			fieldErrors
		} = this.props;

		return (
			<div className="TextArea mt-1 mb-4">
				{icon && (
					<span
						className={icon}
						style={{ fontSize: 16, top: 10, left: 13, position: 'absolute' }}
					/>
				)}
				<textarea
					autoCapitalize="off"
					autoComplete="off"
					className={`${this.props
						.tailwindStyles} block border border-gray-300 w-full px-4 py-2 rounded-md ${icon
						? 'has-icon'
						: ''}`}
					id={id}
					name={name}
					style={style}
					placeholder={placeholder}
					value={value || ''}
					onChange={onChange}
					readOnly={readOnly ? true : false}
				/>
				<div className="p-1">
					{fieldErrors && fieldErrors.hasOwnProperty(name) ? (
						<FieldErrors fieldName={name} fieldErrors={fieldErrors} />
					) : (
						<HelperText>{helperText}</HelperText>
					)}
				</div>
			</div>
		);
	}
}
