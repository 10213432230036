import React from 'react';
import TeamProfileCard from '../../Team/TeamProfileCard/TeamProfileCard';

const handleTeamsClick = () => {
	window.location.href = `/teams`;
};

export default function TeamProfilePage(props) {
	// console.log('TeamProfilePage', props);
	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<div className="relative">
				{ /* <div
					className="absolute text-2xl text-gray-700"
					style={{
						top: -46,
						left: 0,
						cursor: 'pointer'
					}}
					onClick={() => handleTeamsClick()}
				>
					<span className="hover:underline">Teams</span>
					<i
						className="absolute ml-2 fa-solid fa-angle-right"
						style={{
							top: 4,
							left: 75,
							cursor: 'pointer'
						}}
					/>
				</div> */}
				<div>
					<TeamProfileCard get_team_endpoint={props.get_team_endpoint} />
				</div>
			</div>
		</div>
	);
}
