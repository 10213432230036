export default function QbFortyTimeValueToColorHelper(value) {
	if (value <= 4.5) {
		return '#1ABE10';
	} else if (value <= 4.6) {
		return '#4BC818';
	} else if (value <= 4.7) {
		return '#7DD220';
	} else if (value <= 4.8) {
		return '#AFDC28';
	} else if (value <= 4.9) {
		return '#E1E630';
	} else if (value <= 5.0) {
		return '#EDCC2E';
	} else if (value <= 5.1) {
		return '#E9A227';
	} else if (value <= 5.2) {
		return '#E57820';
	} else if (value <= 5.3) {
		return '#E04E1A';
	} else if (value > 5.3) {
		return '#DC2413';
	}
}
