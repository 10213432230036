import React from 'react';

export default class PrimaryButton extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(e) {
		e.preventDefault();
		const targetElement = document.querySelector(this.props.url);
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth' });
		}
		if (this.props.onClick) {
			this.props.onClick();
		}
	}

	render() {
		return (
			<a href={this.props.url} onClick={this.handleClick}>
				<button
					type="button"
					style={this.props.style}
					className={
						'my-3 text-white text-center rounded-md bg-blue-500 hover:bg-blue-600' +
						(this.props.onShowcase ? ' md:w-40 ' : ' w-full')
					}
				>
					<p className="text-lg py-2 px-8">{this.props.label}</p>
				</button>
			</a>
		);
	}
}
