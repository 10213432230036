import React from 'react';
import Header from './DataTableComponents/Header';

export default class AdvancedDataTable extends React.Component {
	constructor(props) {
		super(props);

		const data = this.props.data || [];

		this.state = {
			data: data,
			loading: false
		};

		this.loadData = this.loadData.bind(this);
	}

	componentDidMount() {
		if (!this.state.data || this.state.data.length === 0) {
			this.loadData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.endpoint !== this.props.endpoint) {
			this.loadData();
		}

		if (prevProps.data !== this.props.data) {
			this.setState({ data: this.props.data });
		}
	}

	render() {
		// console.log('AdvancedDataTable - Props', this.props);
		// console.log('AdvancedDataTable - State', this.state);
		const { margin = 'mt-0', shadow = '' } = this.props;

		return (
			<div
				className={`w-full min-w-96 ${margin} border border-gray-300 rounded-md ${shadow} overflow-scroll`}
			>
				<div className="mb-0.5">
					<table className="w-full">
						<thead className="bg-gray-100">
							<tr>
								{this.props.columns.map((column, index) => (
									<th
										key={column.displayName}
										className={`px-8 py-3 text-${column.alignment} ${column.apiName ===
										'draft'
											? 'bg-blue-500 text-white'
											: ''} ${this.props.style} ${index === 0
											? 'bg-gray-100'
											: ''}`}
										style={{
											position: index === 0 ? 'sticky' : 'static',
											left: index === 0 ? 0 : 'auto',
											zIndex: index === 0 ? 1 : 'auto',
											color: column.apiName === 'draft' ? 'white' : 'inherit'
										}}
									>
										<Header
											column={column}
											sortOrder={this.props.sortOrder}
											sortColumn={this.props.sortColumn}
											onChangeSortOrder={
												column.isSortable === false ? (
													''
												) : (
													this.props.onChangeSortOrder
												)
											}
											sortingEnabled={this.props.sortingEnabled}
										/>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{this.state.data.map((d, index) => {
								return this.props.rowRenderer(d, index);
							})}
						</tbody>
					</table>
				</div>
				{this.state.loading ? (
					<div className="loadingOverlay">
						<div className="spinner" />
					</div>
				) : null}
			</div>
		);
	}

	/**
	 * Change Handlers
	*/

	/**
	 * Helper Functions 
	*/

	loadData() {
		if (this.props.endpoint) {
			this.setState({ loading: true });
			$.ajax({
				type: 'GET',
				url: this.props.endpoint
			}).done((payload) => {
				const { data } = payload;
				this.setState({ data: data });
			});
		}
	}
}
