import React from 'react';

export default function GlobalErrorsBox(props) {
	// console.log('GlobalErrorsBox', props);

	const { globalErrors = [] } = props;
	const errorsList = globalErrors.map((error) => (
		<li style={{ color: '#ff3d60' }} key={error}>
			{error}
		</li>
	));

	if (globalErrors && globalErrors.length) {
		return (
			<div className="GlobalError my-3">
				<div>{errorsList}</div>
			</div>
		);
	}
	return null;
}
