import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import logo from '../../../assets/images/logo.jpeg';
import PlayerSearchBar from '../SearchBars/PlayerSearchBar/PlayerSearchBar';

const Header = (props) => {
	const { authenticated, signOut } = useAuth();

	const navigate = useNavigate();
	const location = useLocation();
	const [
		isDropdownOpen,
		setIsDropdownOpen
	] = useState(false);
	const dropdownRef = useRef(null);

	const handleSignOut = async () => {
		await signOut();
		setIsDropdownOpen(false);
	};

	const handleNavigation = (path) => {
		setIsDropdownOpen(false);
		navigate(path);
	};

	useEffect(
		() => {
			const handleClickOutside = (event) => {
				if (
					dropdownRef.current &&
					!dropdownRef.current.contains(event.target)
				) {
					setIsDropdownOpen(false);
				}
			};

			if (isDropdownOpen) {
				document.addEventListener('mousedown', handleClickOutside);
			} else {
				document.removeEventListener('mousedown', handleClickOutside);
			}

			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		},
		[
			isDropdownOpen
		]
	);

	return (
		<nav className="relative flex items-center bg-tt-purple-800">
			<div className="mx-auto">
				<Link to="/">
					<img src={logo} className="h-24" alt="Logo" />
				</Link>
			</div>
			<div className="absolute top-3 right-4">
				<PlayerSearchBar getPlayersEndpoint={props.get_players_endpoint} />
			</div>
			<div className="absolute top-8 left-7">
				<Fragment>
					<i
						className="fa-solid fa-bars text-2xl text-white cursor-pointer"
						onClick={() => setIsDropdownOpen(!isDropdownOpen)}
					/>
					{isDropdownOpen && (
						<div
							ref={dropdownRef}
							className="absolute top-10 left-0 w-48 bg-white text-black rounded-lg shadow-lg z-10"
						>
							<ul className="list-none p-2 space-y-1">
								<li>
									<button
										className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
										onClick={() => handleNavigation('/players')}
									>
										<i className="fa-solid fa-people-group text-lg mr-3" />{' '}
										Players
									</button>
								</li>
								<li>
									<button
										className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
										onClick={() => handleNavigation('/teams')}
									>
										<i className="fa-solid fa-football text-lg mr-3" /> Teams
									</button>
								</li>
								{authenticated ? (
									<Fragment>
										<li>
											<button
												className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
												onClick={() => handleNavigation('/rosters')}
											>
												<i className="fa-solid fa-clipboard-list text-lg mr-4" />
												My Rosters
											</button>
										</li>
										<li>
											<button
												className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
												onClick={() => handleNavigation('/my-profile')}
											>
												<i className="fa-solid fa-circle-user text-lg mr-3" />
												My Profile
											</button>
										</li>
										<li>
											<button
												className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
												onClick={handleSignOut}
											>
												<i className="fa-solid fa-arrow-right-from-bracket text-lg mr-3" />
												Sign Out
											</button>
										</li>
									</Fragment>
								) : (
									location.pathname !== '/signin' && (
										<li>
											<button
												className="w-full text-left p-2 hover:bg-gray-100 rounded flex items-center text-md"
												onClick={() => handleNavigation('/signin')}
											>
												<i className="fa-solid fa-arrow-right-to-bracket text-lg mr-3" />
												Sign In
											</button>
										</li>
									)
								)}
							</ul>
						</div>
					)}
				</Fragment>
			</div>
		</nav>
	);
};

export default Header;
