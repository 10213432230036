import React from 'react';

export default class BarChart extends React.Component {
	constructor(props) {
		super(props);

		const data = this.props.data || { data: [], yAxis: [] };

		this.state = {
			data: data,
			loading: data && data.length ? false : true
		};

		// this.loadData = this.loadData.bind(this);
	}

	componentDidMount() {
		if (!this.state.data || this.state.data.data.length === 0) {
			this.loadData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.endpoint !== this.props.endpoint) {
			this.loadData();
		}
	}

	render() {
		// console.log(this.props)
		// console.log(this.state);
		let bars = null;
		let labels = null;
		let val = 0;
		let highestValue = this.state.data.yAxis[4];
		let sum = 0;

		if (
			this.state.data &&
			this.state.data.data &&
			this.state.data.data.length
		) {
			val = 100 / this.state.data.data.length;
			sum = this.state.data.data.reduce(
				(accumulator, item) => accumulator + item.value,
				0
			);
			bars = this.state.data.data.map((b, idx) => (
				<Tooltip text={`${b.name}: ${b.value}`} key={b.name}>
					<div
						key={b.name}
						className="bar w-28 absolute bottom-0 rounded-md"
						style={{
							backgroundColor: b.color,
							height: `${b.value / highestValue * 100}%`,
							width: `${val - 4}%`,
							left: `${idx * val}%`
						}}
					/>
				</Tooltip>
			));
			labels = this.state.data.data.map((b, idx) => (
				<div
					key={b.name}
					className="bar-name min-w-24 absolute -bottom-11 md:-bottom-7 text-center text-gray-800"
					style={{
						width: `${val - 4}%`,
						left: `${idx * val}%`
					}}
				>
					{b.name}
				</div>
			));
		}

		return (
			<div className="relative">
				{this.state.loading ? (
					<div className="loadingOverlay">
						<div className="spinner" />
					</div>
				) : null}
				{sum > 0 ? (
					<div className="BarChart-container mt-5 h-80 relative pb-10">
						<div className="grid-container">
							<div className="y-axis-container absolute bottom-0 -mb-2.5 z-5">
								<div className="percent-label percent-label-100 h-20">
									<span>$</span>
									{this.state.data.yAxis[4]}
								</div>
								<div className="percent-label percent-label-75 h-20">
									<span>$</span>
									{this.state.data.yAxis[3]}
								</div>
								<div className="percent-label percent-label-50 h-20">
									<span>$</span>
									{this.state.data.yAxis[2]}
								</div>
								<div className="percent-label percent-label-25 h-20">
									<span>$</span>
									{this.state.data.yAxis[1]}
								</div>
								<div className="percent-label percent-label-0 ">
									<span>$</span>
									{this.state.data.yAxis[0]}
								</div>
							</div>
							<div className="body-container absolute bottom-0 left-16 w-9/12 z-5">
								<div className="percent-section block h-20 -mx-2 border border-t-1 border-r-0 border-l-0 border-b-0" />
								<div className="percent-section block h-20 border border-t-1 border-r-0 border-l-0 border-b-0" />
								<div className="percent-section block h-20 border border-t-1 border-r-0 border-l-0 border-b-0" />
								<div className="percent-section percent-section-bottom h-20 border border-t-1 border-r-0 border-l-0 border-b-1" />
							</div>
						</div>
						<div className="bars-container h-full w-9/12 absolute bottom-0 left-20 z-10">
							{bars}
							<div className="bar-label-container">{labels}</div>
						</div>
					</div>
				) : (
					<div className="no-data">
						<div className="text">{I18n.t('admin.no_data_available')}</div>
					</div>
				)}
			</div>
		);
	}

	// loadData() {
	// 	this.setState({ loading: true });
	// 	$.ajax({
	// 		type: 'POST',
	// 		url: this.props.endpoint
	// 	}).done((data) => {
	// 		//console.log('BarChart loadData done', data);
	// 		this.setState({ data: data, loading: false });
	// 	});
	// }
}
