import React from 'react';

const ProCollegeButtons = ({
	selectedPlayingLevelType,
	handleProStatsButtonClick,
	handleCollegeStatsButtonClick
}) => {
	return (
		<div className="mb-4 flex flex-row">
			{/* NFL Button*/}
			<div className="items-center mr-4" onClick={handleProStatsButtonClick}>
				<button
					className={`p-2 border border-gray-300 rounded-md mr-2 ${selectedPlayingLevelType ===
					'pro'
						? 'bg-gray-800 text-white'
						: 'bg-white text-black'}`}
				>
					NFL Stats
				</button>
			</div>
			{/* College Button */}
			<div className="items-center mr-4">
				<button
					className={`p-2 border border-gray-300 rounded-md mr-2 ${selectedPlayingLevelType ===
					'college'
						? 'bg-gray-800 text-white'
						: 'bg-white text-black'}`}
					onClick={handleCollegeStatsButtonClick}
				>
					College Stats
				</button>
			</div>
		</div>
	);
};

export default ProCollegeButtons;
