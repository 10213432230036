import React from 'react';

const ClearFiltersButton = ({ onClick }) => {
  return (
    <div className="flex items-center">
      <button
        onClick={onClick}
        className="underline text-gray-800"
      >
        Clear All
      </button>
    </div>
  );
};

export default ClearFiltersButton;
