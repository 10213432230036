import React from 'react';

const FilterByPosition = ({ filter, onChange }) => {
	const positionButtons = [
		{ label: 'All', value: 'all' },
		{ label: 'QB', value: 'quarterback' },
		{ label: 'RB', value: 'running back' },
		{ label: 'WR', value: 'wide receiver' },
		{ label: 'TE', value: 'tight end' }
	];

	const handlePositionFilterChange = (position) => {
		if (position === 'all') {
			if (filter.position.length === positionButtons.length - 1) {
				onChange('position', []);
			} else {
				onChange('position', positionButtons.slice(1).map((button) => button.value));
			}
		} else {
			const updatedPositions = filter.position.includes(position)
				? filter.position.filter((p) => p !== position)
				: [...filter.position, position];
			onChange('position', updatedPositions);
		}
	};

	const isAllSelected = filter.position.length === positionButtons.length - 1;

	return (
		<div className="flex items-center my-2 lg:my-0 mr-2 md:mr-4">
			<label className="text-sm sm:text-base mr-2">Filter By Position:</label>
			<div className="flex flex-wrap justify-start space-x-1 lg:space-x-0 xl:space-x-2">
				{positionButtons.map((button) => (
					<button
						key={button.value}
						className={`w-10 h-10 flex items-center justify-center border border-gray-300 rounded-full text-xs sm:text-base hover:bg-gray-800 hover:text-white ${filter.position.includes(button.value) || (button.value === 'all' && isAllSelected)
							? 'bg-gray-800 text-white'
							: 'bg-white text-gray-800'}`}
						onClick={() => handlePositionFilterChange(button.value)}
					>
						<span className="text-xs">{button.label}</span>
					</button>
				))}
			</div>
		</div>
	);
};

export default FilterByPosition;
