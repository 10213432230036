import React from 'react';

export default class MobileDataTableCard extends React.Component {
	constructor(props) {
		super(props);

		const data = this.props.data || [];

		this.state = {
			data: data,
			loading: false
		};

		this.loadData = this.loadData.bind(this);
	}

	componentDidMount() {
		if (!this.state.data || this.state.data.length === 0) {
			this.loadData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.endpoint !== this.props.endpoint) {
			this.loadData();
		}

		if (prevProps.data !== this.props.data) {
			this.setState({ data: this.props.data });
		}
	}

	render() {
		// console.log('MobileDataTableCard - Props', this.props);
		// console.log('MobileDataTableCard - State', this.state);
		const { margin = 'my-0', shadow = '' } = this.props;

		return (
			<div
				className={`w-full text ${margin} border border-gray-300 rounded-md ${shadow} overflow-auto`}
			>
				<div className="mb-0.5">
					<div className="w-full">
						<div>
							{this.state.data.map((d) => {
								return this.props.rowRenderer(d);
							})}
						</div>
					</div>
				</div>
				{this.state.loading ? (
					<div className="loadingOverlay">
						<div className="spinner" />
					</div>
				) : null}
			</div>
		);
	}

	/**
	 * Change Handlers
	*/

	/**
	 * Helper Functions 
	*/

	loadData() {
		if (this.props.endpoint) {
			this.setState({ loading: true });
			$.ajax({
				type: 'GET',
				url: this.props.endpoint
			}).done((payload) => {
				const { data } = payload;
				// console.log('loadData done', payload, data);
				this.setState({ data: data });
			});
		}
	}
}
