import React, { useState } from 'react';

const RosterDraftNotesCard = ({
	user,
	roster,
	update_draft_notes_by_roster_endpoint
}) => {
	console.log(
		'RosterDraftNotesCard - update_draft_notes_by_roster_endpoints:',
		{ update_draft_notes_by_roster_endpoint }
	);

	const [
		draftNotes,
		setDraftNotes
	] = useState('');

	const handleNotesChange = async (e) => {
		const newDraftNotes = e.target.value;
		setDraftNotes(newDraftNotes);

		const endpoint = update_draft_notes_by_roster_endpoint
			.replace('user_id', user.id)
			.replace('roster_id', roster.id);

		try {
			const response = await fetch(endpoint, {
				method: 'PUT', // or 'POST' or 'PATCH', depending on your API
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ draft_notes: newDraftNotes })
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			// Optionally handle response
			const data = await response.json();
			console.log('Draft notes updated successfully:', data);
		} catch (error) {
			console.error('Failed to update draft notes:', error);
		}
	};

	return (
		<div>
			<div className="flex flex-col items-center">
				<div className="w-full">
					<div className="flex justify-between items-center">
						<h2 className="text-2xl font-bold text-gray-800">Draft Notes</h2>
					</div>
					<div className="mt-4">
						<textarea
							value={draftNotes}
							onChange={handleNotesChange}
							className="p-2 border border-gray-300 rounded-xl shadow-lg w-44 xl:w-56 h-96"
							// style={{ width: 230, height: 550 }}
							placeholder="Enter your draft notes here..."
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RosterDraftNotesCard;
